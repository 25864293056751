<script>
    import { onMount } from "svelte";

    export let label;
    export let value;
    export let isDisabled = false;
    export let cols = 40;
    export let rows = 3;
    let fill = false;
    let focus = false;

    onMount(() => {
        setStatus();
    });

    function setStatus() {
        if (value !== '' && value !== undefined) {
            fill = true;
        } else {
            fill = false;
        }
        focus = false;
    }
</script>
<div class="naui-field" class:active={fill}>
    <div class="naui-field-back" class:fill={fill}>
        <label class:focus={focus}>{label}</label>
        <textarea class="naui-input w-200"
                  readonly={isDisabled}
                  cols={cols}
                  rows={rows}
                  bind:value={value}
                  on:input
                  on:focus={()=> fill=true}
                  on:focusin={()=>focus=false}></textarea>
    </div>
</div>
<style>
    .naui-field-back.fill{
        background: white;
    }
</style>
