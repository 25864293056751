<script>
    import { createEventDispatcher } from "svelte";
    import { estadosAuditar } from "../constants";
    import AuditoriaItemAuditarVer from "./AuditoriaItemAuditarVer.svelte";
    import NauiTag from "../../lib/naui/atoms/NauiTag.svelte";

    export let auditoriaItems;
    export let totalPages = 1;
    let archivo = "";
    export let itemsPerPage = 20;
    export let buscando = false;

    const dispatch = createEventDispatcher();

    function buscar() {
        dispatch("buscar");
    }

    $: desactivarAvance = (currentPage+1) == totalPages
    $: desactivarRegreso = currentPage == 0

    $: totalItems = auditoriaItems.length 
    $: currentPage = 0;
    $: start = currentPage * itemsPerPage;
    $: end =
        currentPage === totalPages - 1
            ? totalItems - 1
            : start + itemsPerPage - 1;
    $: itemsToRender = auditoriaItems.slice(start, end + 1);
    $: currentPage
    $: totalItems, currentPage;
    $: auditoriaItems, currentPage = 0
</script>

<div>
    <table class="naui-table w-100">
        <thead>
            <tr>
                <th> Vendor </th>
                <th> Referencia </th>
                <th> Tipo Auditoría </th>
                <th> Liq. Ref. </th>
                <th> Tamaño Muestra </th>
                <th width="1%"> Tipo de Empaque </th>
                <!-- <th width="1%">
                    No. de Entrega
                </th> -->
                <th width="100px"> Q </th>
                <th width="100px"> Orden de Corte </th>
                <th width="1%" colspan="2"> Crítica Auditada </th>
                <th width="1%"> Cant. </th>
                <th width="1%"> Cant. Aud. </th>
                <th width="1%"> Cant. Seg. </th>
                <th width="150px"> Estado </th>
                <th width="155px"> Fecha </th>
                <th> Hora </th>
                <th width="110px"> Nombre Auditor </th>
                <th width="100px"> Observación </th>
                <th width="1%" colspan="3"> Medida Empaque Confección </th>
                <th width="1%"> Auditar </th>
            </tr>
        </thead>
        <tbody>
            {#if !buscando && auditoriaItems.length === 0}
                <tr>
                    <td colspan="20">
                        <div><p>No se encontraron registros</p></div>
                    </td>
                </tr>
            {:else if auditoriaItems.length > 0}
                {#each itemsToRender as item (item.id)}
                    <AuditoriaItemAuditarVer
                        data={item}
                        bind:checked={item.checked}
                        estados={estadosAuditar}
                        archivo={archivo.split("/").pop().split("\\").pop()}
                        on:Eauditar={buscar}
                    />
                {/each}
            {:else}
                <tr>
                    <td colspan="20">
                        <div><p>Buscando...</p></div>
                    </td>
                </tr>
            {/if}
        </tbody>
        
    </table>
    <div class="prevNextPage">
        <button
            class={`${desactivarRegreso?"disabled":""} btn outline accent`}
            icon="chevron_left"
            on:click={() => {
                currentPage -= 1
                window.scrollTo({
                    top:0
                })
            }}
            disabled={desactivarRegreso}
        ><span class="material-icons-outlined">chevron_left</span></button>
        <NauiTag 
            label={`Página ${currentPage + 1} de ${totalPages}`}
        />
        <button
            class={`${desactivarAvance?"disabled":""} btn outline accent`}
            on:click={() => {
                window.scrollTo({
                    top:0
                })
                currentPage += 1}}
            disabled={desactivarAvance}
        ><span class="material-icons-outlined">chevron_right</span></button>
        <!-- <NauiInput
            type="number"
            label={`Página ${currentPage +1} de ${totalPages == 0 ? totalPages + 1: totalPages}`}
            bind:value={inputPage}

        /> -->
    </div>
</div>

<style>
    .prevNextPage{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .prevNextPage .disabled{
        background: #f4f5f6;
        border: unset;
    }

    .prevNextPage .disabled span{
        color: white;
    }

    .prevNextPage .disabled:hover{
        cursor: initial;
        border: initial;
    }
</style>