<svelte:options accessors/>
<script lang="ts">
    import NauiExplorer from "../lib/naui/pages/NauiExplorer.svelte";
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import Dialog from "@smui/dialog/src/Dialog.svelte";
    import { Actions, Content, Title } from "@smui/dialog";
    import { onMount } from "svelte";

    export let po;
    export let data;
    export let columns;
    export let filters;
    export let pages;
    export let count;

    let countLabel = '';
    let ExplorerPO;
    export let urlDownload;
    let active = 'detalles';
    let warning = {
        message:"",
        open:false,
        title:"",
        icon : ""
    }

    onMount(() => {
        data.map(e => {
            e.q_pending = e.q_ordered - e.q_delivered
        })
    })

    let currentFilters: any = {};

    $: countLabel = count === 1 ? 'registro' : 'registros';
    $: ExplorerPO && ExplorerPO.updatePages(pages)

    async function handleDownloadCSV() {
        try {
            const poDetail = await fetch(urlDownload,
                {    
                    method: 'GET',
                    headers: {
                                "content-type":"application/json"
                            }
                }
            )
            const info = await poDetail.blob();   
            if(info){
                const url = window.URL.createObjectURL(info)
                let a = document.createElement('a')
                    a.href = url;
                    a.download = `PO_${po}_ITEMS.xlsx`
                    document.body.appendChild(a);
                    a.click()
                    a.remove()
                warning.title = "Descarga completada!"
                warning.icon = "check_circle"
                warning.open = true;
            }else{
                warning.title = "Error!"
                warning.message = "No se pudo descargar el archivo";
                warning.open = true;
            }
        } catch (error) {
            warning.title = "Error!"
            warning.message = "No se pudo descargar el archivo";
            warning.open = true;
        }
    }

    function handlePage(ev) {
        currentFilters = {...ev.detail, ...currentFilters};
        applyFilters();
    }

    function handleFilters(ev) {
        currentFilters = ev.detail;
        applyFilters();
    }

    async function applyFilters() {
        const res = await fetch(`/scanpack/api/po/${po}?${new URLSearchParams(currentFilters)}`);
        const resJson = await res.json();
        data = resJson.data;
        count = resJson.count;
        pages = resJson.pages;
    }
</script>

<div class="mt-1">
    <NauiExplorer {data} {columns} {filters} {pages} {count} {countLabel} bind:this={ExplorerPO} paginate={true}
                  grid="3"
                  searchLabel="Buscar EAN o EAN Prepack"
                  on:filter={handleFilters} on:next={handlePage}>
        <div slot="actions">
            <NauiButton caption="DESCARGAR EXCEL" icon="vertical_align_bottom" mode="stroked"
                        on:click={handleDownloadCSV}/>
        </div>
    </NauiExplorer>
</div>

<Dialog bind:open={warning.open} scrimClickAction="" escapeKeyAction="">
    <Title>
        <div class="box sx-ii py-ii">
            {#if warning.icon}
                <span class="material-icons accent">{warning.icon}</span>
            {/if}
            <h3>{warning.title}</h3>
        </div>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
                {warning.message}
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                caption="ACEPTAR"
                mode="solid"
                color="accent"
                on:click={() => {
                    warning.open = false
                }}
            />
        </div>
    </Actions>
</Dialog>