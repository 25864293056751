<script lang="ts">
  import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
  import NauiCheckbox from "../lib/naui/atoms/NauiCheckbox.svelte";
  import NauiInput from "../lib/naui/atoms/NauiInput.svelte";

  import AuditoriaItem from "./components/AuditoriaItem.svelte";
  import AuditoriaItemXLS from "./components/AuditoriaItemXLS.svelte";

  import { onMount } from "svelte";
  import NauiDatePicker from "../lib/naui/atoms/NauiDatePicker.svelte";
  import NauiSelect from "../lib/naui/atoms/NauiSelect.svelte";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import { vendor } from "../stores/sessionVendor";
  import { TableExport } from "tableexport";

  export let data = [];
  export let vendorAct;
  export let horario;
  export let max_liquidar;

  let MAXIMO_LIQUIDAR;
  let dialogOpen = false;
  let dialogOpenOK = false;
  let deshabilitarBtnAuditar = false;

  let refs = [];
  let tipos_empaque = [
    {
      label: "Individual    ",
      value: "Individual",
    },
    {
      label: "Prepack",
      value: "Prepack",
    },
  ];

  let tipos_auditoria = [
    {
      label: "Primeras",
      value: "Primeras",
    },
    {
      label: "Segundas",
      value: "Segundas",
    },
  ];

  onMount(() => {
    //console.log(data)
    console.log(vendorAct);

    refs = data.map((y) => {
      return {
        label: y.codigo + " - " + y.description,
        value: y.codigo + " - " + y.description,
      };
    });
    console.log(max_liquidar);
    let currentTime = new Date();

    if (
      !(
        currentTime.getHours() * 1 >= horario.inicio * 1 &&
        currentTime.getHours() * 1 < horario.fin * 1
      )
    ) {
      mensaje =
        "No puedes solicitar auditoría en este momento. El horario establecido es desde " +
        horario.inicio +
        ":00 hastas las " +
        horario.fin +
        ":00";
      dialogOpen = true;
    }
    MAXIMO_LIQUIDAR = max_liquidar.valor;
  });

  const calcularQ = () => {
    const f = new Date();
    const mes = f.getMonth();
    var ano = f.getFullYear();
    if (mes >= 10) ano++;
    const qs = [4, 1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4];
    return `Q${qs[mes]}-${ano}`;
  };

  function fechaDeManana() {
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);
    return manana;
  }

  let referencia_sel = "0";
  let TipoAuditoria = "Primeras";
  let TipoEmpaque = "Individual";
  const expRegular = /^Q[1-4]-20[1-9][0-9]/;
  let Q = "";
  let Orden = "";
  let Critica;
  let Auditada;
  let Liquidar;
  let Cantidad = 1;
  let Cantidad_aud = 0;
  let Fecha = formatoFecha(fechaDeManana(), "yyyy-mm-dd"); //.toLocaleDateString();
  let Hora = "08:00";
  let Medida;
  let Empaque;
  let Confeccion;

  let swSegundasDesactivar = false;

  let AuditoriaItems = [];
  let CantTotal = 0;

  let vendors = [];
  let vendors1 = [];

  // $: facturas = facturasR
  let facturas = [];
  let facturasFull = [];
  let cant = 0;

  function agregar() {
    let swOrdenReferencia = validarOrdenCorteYReferencia();

    if (TipoAuditoria === "Primeras") {
      if (Q === "") {
        mensaje = "El campo [Q] es requerido";
        dialogOpenOK = true;
        return false;
      }

      if (!expRegular.test(Q)) {
        mensaje = `El campo [Q] no cumple con el formato requerido. \nEjemplo: "${calcularQ()}"`;
        dialogOpenOK = true;
        return false;
      }

      if (!Liquidar && Cantidad < MAXIMO_LIQUIDAR) {
        mensaje = "No puede solicitar unidades menores a " + MAXIMO_LIQUIDAR;
        dialogOpenOK = true;
        return false;
      }
    }
    
    if (swOrdenReferencia) {
      const item = {
        id: cant,
        referencia_sel: referencia_sel,
        TipoAuditoria: TipoAuditoria,
        TipoEmpaque: TipoAuditoria === "Segundas" ? "" : TipoEmpaque,
        Q: TipoAuditoria === "Segundas" ? "" : Q,
        Orden: Orden,
        Critica: TipoAuditoria === "Segundas" ? false : Critica,
        Auditada: TipoAuditoria === "Segundas" ? false : Auditada,
        Cantidad: Cantidad,
        Fecha: Fecha,
        Hora: Hora,
        Medida: TipoAuditoria === "Segundas" ? false : Medida,
        Empaque: TipoAuditoria === "Segundas" ? false : Empaque,
        Confeccion: TipoAuditoria === "Segundas" ? false : Confeccion,
        checked: false,
        Liquidar: TipoAuditoria === "Segundas" ? false : Liquidar,
      };
      cant++;
      AuditoriaItems.push(item);
      AuditoriaItems = AuditoriaItems;
      CalcularTotal();
    } else {
      return false;
    }
  }

  function quitar() {
    AuditoriaItems = AuditoriaItems.filter((x) => x.checked == false);
    CalcularTotal();
  }
  function cancelar() {
    document.location = "/auditoria/home";
  }

  function CalcularTotal() {
    let Total = 0;
    if (AuditoriaItems.length > 0) {
      AuditoriaItems.forEach((x) => {
        Total += x.Cantidad;
      });
    }
    CantTotal = Total;
    return Total;
  }

  function formatoFecha(fecha, formato) {
    const map = {
      dd: fecha.getDate() >= 10 ? fecha.getDate() : "0" + fecha.getDate(),
      mm:
        fecha.getMonth() * 1 + 1 >= 10
          ? fecha.getMonth() * 1 + 1
          : "0" + (fecha.getMonth() * 1 + 1),
      yyyy: fecha.getFullYear(),
    };

    return formato.replace(/dd|mm|yyyy/gi, (matched) => map[matched]);
  }

  function validarOrdenCorteYReferencia() {
    if (Orden == "" || referencia_sel == "0") {
      mensaje = "Los campos de orden de corte y referencia son requeridos";
      dialogOpenOK = true;
      return false;
    } else {
      return true;
    }
  }

  function opcionSegundasDesactivar() {
    if (TipoAuditoria === "Segundas") {
      swSegundasDesactivar = true;
    } else {
      swSegundasDesactivar = false;
    }
  }

  let resp = [];
  let mensaje = "";
  async function guardar() {
    deshabilitarBtnAuditar = true;
    if (CantTotal != 0) {
      
      const payload = AuditoriaItems;

      const response = await fetch("/auditoria/guardar_solicitud", {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      resp = await response.json();
      if (response.ok) {
        console.log(resp);
        mensaje = resp["mensaje"];
        dialogOpen = true;
        deshabilitarBtnAuditar = false;
        //alert ()
        //window.location.href =  '/auditoria/home'
      }
    } else {
      //alert('Debe Ingresar Items a Auditar')
      mensaje = "Debe Ingresar Items a Auditar";
      deshabilitarBtnAuditar = false;
      dialogOpenOK = true;
    }
  }

  function volver() {
    window.location.href = "/auditoria/home";
  }

  function excel() {
    let tabla = document.querySelector("#tabla");
    let tableExport = new TableExport(tabla, {
      exportButtons: false, // No queremos botones
      filename: "Pedir", //Nombre del archivo de Excel
      sheetname: "Pedir Auditoria", //Título de la hoja
    });
    console.log(tableExport);
    let datos = tableExport.getExportData();
    let preferenciasDocumento = datos.tabla.xlsx;
    tableExport.export2file(
      preferenciasDocumento.data,
      preferenciasDocumento.mimeType,
      preferenciasDocumento.filename,
      preferenciasDocumento.fileExtension,
      preferenciasDocumento.merges,
      preferenciasDocumento.RTL,
      preferenciasDocumento.sheetname
    );
  }
</script>

<section>
  <table class="naui-table w-70">
    <thead>
      <tr>
        <th> Vendor </th>
        <th> Código Sap </th>
        <th> Dirección </th>
        <th> Total unidades </th>
        <th />
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          {vendorAct.name}
        </td>
        <td>
          {vendorAct.codigo_sap}
        </td>
        <td>
          {vendorAct.direccion}
        </td>
        <td>
          {CantTotal}
        </td>
        <td>
          <div>
            <NauiButton
              caption="Pedir Auditoria"
              mode="outline"
              color="accent"
              disabled = {deshabilitarBtnAuditar}
              on:click={deshabilitarBtnAuditar === false ? guardar : () => {}}
            />
          </div>
        </td>
        <td>
          <NauiButton
            caption="Descargar Excel"
            mode="outline"
            color="accent"
            on:click={excel}
          />
        </td>
        <td>
          <div>
            <NauiButton
              caption="Cancelar"
              mode="outline"
              color="accent"
              on:click={cancelar}
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <br />

  <br />
  <table class="naui-table w-100" id="tabla" style="display:none ;">
    <thead>
      <tr>
        <th> Referencia </th>
        <th> Tipo de Auditoría </th>
        <th width="1%"> Liquidar Referencia </th>
        <th> Tipo de Empaque </th>
        <th width="100px"> Q </th>
        <th width="100px"> Orden de Corte </th>
        <th width="1%"> Crítica </th>
        <th width="1%"> Auditada </th>
        <th width="1%"> Cantidad </th>
        <th> Fecha </th>
        <th width="100px"> Hora </th>
        <th width="1%"> Medida </th>
        <th width="1%"> Empaque </th>
        <th width="1%"> Confección </th>
      </tr>
    </thead>
    <tbody>
      {#each AuditoriaItems as itemE (itemE.id)}
        <AuditoriaItemXLS data={itemE} />
      {/each}
    </tbody>
  </table>

  <table class="naui-table w-100">
    <thead>
      <tr>
        <th>
          <NauiButton
            icon="disabled_by_default"
            mode="outline"
            color="accent"
            iconStyle="outlined"
            on:click={quitar}
          />
        </th>

        <th> Referencia </th>
        <th width="1%"> Tipo de Auditoría </th>
        <th width="1%"> Liquidar Referencia </th>
        <th> Tipo de Empaque </th>
        <th> Q </th>
        <th width="100px"> Orden de Corte </th>
        <th width="1%"> Crítica </th>
        <th width="1%"> Auditada </th>
        <th width="1%"> Cantidad </th>
        <th> Fecha </th>
        <th width="100px"> Hora </th>
        <th width="1%"> Medida </th>
        <th width="1%"> Empaque </th>
        <th width="1%"> Confección </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <NauiButton
            icon="add_circle_outline"
            mode="outline"
            color="accent"
            iconStyle="outlined"
            on:click={agregar}
          />
        </td>
        <td>
          <NauiSelect bind:value={referencia_sel} options={refs} label="" />
        </td>
        <td>
          <NauiSelect
            bind:value={TipoAuditoria}
            options={tipos_auditoria}
            label=""
            on:change={opcionSegundasDesactivar}
          />
        </td>
        <td>
          <NauiCheckbox bind:value={Liquidar} disabled={swSegundasDesactivar} />
        </td>
        <td width="110px">
          <NauiSelect
            bind:value={TipoEmpaque}
            options={tipos_empaque}
            label=""
            disabled={swSegundasDesactivar}
          />
        </td>
        <td>
          <NauiInput
            bind:value={Q}
            placeholder={calcularQ()}
            type="text"
            label=""
            isDisabled={swSegundasDesactivar}
          />
        </td>
        <td>
          <NauiInput bind:value={Orden} type="text" label="" />
        </td>
        <td>
          <NauiCheckbox bind:value={Critica} disabled={swSegundasDesactivar} />
        </td>
        <td>
          <NauiCheckbox bind:value={Auditada} disabled={swSegundasDesactivar} />
        </td>
        <td>
          <NauiInput bind:value={Cantidad} min={1} type="number" label="" />
        </td>
        <td>
          <NauiDatePicker bind:value={Fecha} label="" />
        </td>
        <td>
          <!--<NauiInput bind:value={Hora} type="date" label=""  />-->
          <input bind:value={Hora} type="time" label="" />
        </td>
        <td>
          <NauiCheckbox bind:value={Medida} disabled={swSegundasDesactivar} />
        </td>
        <td>
          <NauiCheckbox bind:value={Empaque} disabled={swSegundasDesactivar} />
        </td>
        <td>
          <NauiCheckbox
            bind:value={Confeccion}
            disabled={swSegundasDesactivar}
          />
        </td>
      </tr>
      <tr>
        <td colspan="14">
          <hr />
        </td>
      </tr>
      {#each AuditoriaItems as itemE (itemE.id)}
        <AuditoriaItem data={itemE} {refs} />
      {/each}
    </tbody>
  </table>

  <Dialog bind:open={dialogOpen} scrimClickAction="" escapeKeyAction="">
    <Title>
      <h3 class="text-c">Pedir auditoría</h3>
    </Title>
    <Content>
      <div class="max-w-400px text-c mx-auto px-15">
        <p class="large">
          {mensaje}
        </p>
      </div>
    </Content>
    <Actions>
      <div class="box-r sx-ii px-1 py-ii">
        <NauiButton caption="VOLVER" color="accent" on:click={volver} />
      </div>
    </Actions>
  </Dialog>

  <Dialog bind:open={dialogOpenOK}>
    <Title>
      <h3 class="text-c">Pedir auditoría</h3>
    </Title>
    <Content>
      <div class="max-w-400px text-c mx-auto px-15">
        <p class="large">
          {mensaje}
        </p>
      </div>
    </Content>
    <Actions>
      <div class="box-r sx-ii px-1 py-ii">
        <NauiButton
          caption="Aceptar"
          color="accent"
          on:click={() => {
            dialogOpenOK = false;
          }}
        />
      </div>
    </Actions>
  </Dialog>
</section>

<style>
  /*    .search {
        border-radius: 2rem;
        padding-left: 2.5rem;
    }

    .search + span {
        position: absolute;
        left: .75rem;
        top: .75rem;
        color: var(--secondary);
    }

    .naui-filter {
        min-width: 150px;
        color: var(--secondary);
        opacity: 1;
    }*/
</style>
