<script lang="ts">
    import {onMount} from 'svelte';
    import Steps from "./components/Steps.svelte";
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import InputAutocomplete from "./components/InputAutocomplete.svelte";
    import SelectTableHead from "./components/SelectTableHead.svelte";
    import SelectTableItem from "./components/SelectTableItem.svelte";
    import NauiSlideOver from "../lib/naui/layout/NauiSlideOver.svelte";
    import {removeDuplicates} from "../lib/utils";

    import Dialog, {Actions, Content, Title} from "@smui/dialog";


    let filters = {
        recipiente: '',
        referencia: '',
        ean: ''
    }

    let step: string = 'select';
    let open = false;
    let loaded = false;

    let dialogOpenOK = false;
    let mensaje = "";

    export let data = [];
    let localData = [];
    let globalCheck = false;
    let storage = [];
    let cart = JSON.parse(localStorage.getItem('selectedItems')) ?? [];

    function sortData(){
        data.sort((a, b) => parseInt(a.recipiente)-parseInt(b.recipiente))
    }

    function setCart(value) {
        cart = value;
        localStorage.setItem('selectedItems', JSON.stringify(value));
    }

    onMount(() => {
        sortData()
        localData = data.map((x) => {
            return {...x, checked: false}
        });

        storage = JSON.parse(localStorage.getItem('selectedItems')) ?? [];
        if (storage.length > 0) {
            storage.forEach((x) => {
                const index = localData.findIndex((y) => y.id === x.id);
                if (index >= 0) {
                    x.cantidad = localData[index].cantidad
                    localData[index].checked = true;
                }
            });
        }

        // Compare Store with data
        cart.forEach((x) => {
            const toDelete = data.some((y) => y.id === x.id);
            if (!toDelete) {
                deleteItemFromStore(x.id);
            }
        });
        loaded = true;
    })

    function deleteItemFromStore(id) {
        const index = localData.findIndex((x) => x.id === id);
        if (index >= 0) {
            localData[index].checked = false;
        }
    }


    function clear() {
        globalCheck = false;
        localData.forEach((x) => {
            x.checked = false;
        });
        filters.recipiente = null;
        filters.referencia = null;
        filters.ean = null;
    }


    function globalCheckChange(check) {
        localData.forEach((x) => {
            if (filteredData.includes(x)) {
                x.checked = check;
            }
        });
        localData = localData;
    }

    function consultar()
    {
        window.location.href =  '/maquila/consultar_asn'
    }

    function applyFilters(data, filters) {
        const response = data.map((x) => {
            let pass = true;
            if (filters.recipiente) {
                if (x.recipiente !== filters.recipiente) {
                    pass = false;
                }
            }
            if (filters.referencia) {
                if (x.codigo_referencia_venta !== filters.referencia) {
                    pass = false;
                }
            }
            if (filters.ean) {
                if (x.ean_prepack !== filters.ean) {
                    pass = false
                }
            }
            if (pass) {
                return x
            } else {
                return null
            }
        });
        return response.filter((x) => x);
    }

    $: recipientes = removeDuplicates(localData.map((x) => {
        return x.recipiente
    }));
    $: referencias = removeDuplicates(localData.map((x) => {
        return x.codigo_referencia_venta
    }));
    $: eanes = removeDuplicates(localData.map((x) => {
        return x.ean_prepack
    }));

    $: globalCheckChange(globalCheck);
    $: filteredData = applyFilters(localData, filters);
    $: checkedItems = filteredData.filter((x) => x.checked).map((y) => {
        return y
    });
    $: saveToLocal(checkedItems);

    function saveToLocal(items) {
        if (items && loaded) {
            localStorage.setItem('selectedItems', JSON.stringify(items));
        }
    }

    async function downloadCSV() {
        if (!checkedItems || checkedItems.length == 0)
            return;
        const response = await fetch('/maquila/recipientes-csv', {
            method: 'post',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(checkedItems.map(x => x.id))
        });
        if (response.ok) {
            const data = await response.text();
            const text = new Blob([data], {type: "text/plain:charset=UTF-8"});
            const url = window.URL.createObjectURL(text)
            let a = document.createElement('a')
            a.href = url;
            a.download = `ASN_${(new Date().toLocaleDateString())}.txt`
            document.body.appendChild(a);
            a.click()
            a.remove()
        } else {
            mensaje = "No se puede descargar el archivo";
            dialogOpenOK = true;
        }
    }

</script>
<section>
    <div class="my-1 box-b">
        <Steps {step}/>
        <div class="box-r sx-iii self-end">
            <NauiButton caption="Consultar ASN" mode="outline" color="accent" on:click={consultar}/>
            <NauiButton caption="DESCARGAR CSV" mode="outline" color="accent" on:click={downloadCSV} disabled={checkedItems.length <= 0}/>
            <NauiButton caption="{checkedItems.length} SELECCIONADOS" iconStyle="outlined" icon="check_box"
                        mode="stroked"
                        on:click={()=> open = true}/>

        </div>
    </div>
    <div class="box-l sx-1 my-15">
        <InputAutocomplete id="recipiente" bind:value={filters.recipiente} list="{recipientes}"
                           label="RECIPIENTE"/>
        <InputAutocomplete id="referencia" bind:value={filters.referencia} list="{referencias}"
                           label="REFERENCIA"/>
        <InputAutocomplete id="ean" bind:value={filters.ean} list="{eanes}" label="EAN"/>
        <NauiButton icon="refresh" color="accent" on:click={clear}/>
    </div>
    <div class="mb-1 box-b">
        <p><strong>Seleccione los recipientes</strong> de los cuales descargará los EAN para continuar</p>
        <!--<NauiButton caption="AGREGAR A SELECCIÓN" mode="stroked" size="small" disabled="{checkedItems.length === 0}"
                    on:click={addToSelection.bind(this, checkedItems)}/> -->
    </div>
    <table class="naui-table w-100">
        <SelectTableHead bind:checked={globalCheck}/>
        <tbody>
        {#each filteredData as item}
            <SelectTableItem data="{item}" bind:checked={item.checked}/>
        {:else}
            <tr>
                <td colspan="11">
                    <div><p>No se encontraron recipientes recibidos. Verifique los <a
                            style="color: var(--secondary); text-decoration: underline" href="/maquila/recepcion">recipientes
                        enviados</a> y por recibir</p></div>
                </td>
            </tr>
        {/each}
        </tbody>
    </table>
    <NauiSlideOver title="Recipientes seleccionados" bind:open>
        <div class="mb-1">
            <NauiButton caption="{checkedItems.length} SELECCIONADOS" icon="check_box" iconStyle="outlined"
                        mode="stroked"/>
        </div>
        <table class="naui-table w-100">
            <thead>
            <tr>
                <th style="width: 40%">
                    <div>Recipiente</div>
                </th>
                <th>
                    <div>Unid. Inventario</div>
                </th>
                <th>
                    <div></div>
                </th>
            </tr>
            </thead>
            <tbody>
            {#each checkedItems as item}
                <tr>
                    <td>
                        <div>{item.recipiente}</div>
                    </td>
                    <td>
                        <div>
                            {item.cantidad}
                        </div>
                    </td>
                    <td>
                        <div>
                            <span class="material-icons-outlined accent pointer"
                                  on:click={deleteItemFromStore.bind(this, item.id)}
                                  style="font-size:20px;">delete</span>
                        </div>
                    </td>
                </tr>
            {/each}
            </tbody>
        </table>
        <div slot="actions" class="box">
            {#if checkedItems.length === 0}
                <p>Seleccione un grupo registros para continuar</p>
                <NauiButton caption="CONTINUAR A SCAN AND PACK" mode="solid" color="secondary" disabled={true}/>
            {:else}
                <NauiButton href="/maquila/scanpack" caption="CONTINUAR A SCAN AND PACK" mode="solid"
                            color="secondary"/>
            {/if}

        </div>
    </NauiSlideOver>

    <Dialog bind:open={dialogOpenOK} >
        <Title>
            <h3 class="text-c">Maquila</h3>
        </Title>
        <Content>
            <div class="max-w-400px text-c mx-auto px-15">
                <p class="large">
                   {mensaje}
                </p>
            </div>
        </Content>
        <Actions>
            <div class="box-r sx-ii px-1 py-ii">
                <NauiButton caption="Aceptar" color="accent" on:click={() => {dialogOpenOK = false;}}/>
            </div>
        </Actions>
    </Dialog>

</section>