<script>
  import NauiInput from "../lib/naui/atoms/NauiInput.svelte";
  import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
  import { onMount } from "svelte";
  import Dialog, { Header, Title, Content, Actions } from "@smui/dialog";
  import NauiFloatingAction from "../lib/naui/components/NauiFloatingAction.svelte";
  import NauiSelect from "../lib/naui/atoms/NauiSelect.svelte";
  import InputAutocomplete from "./components/InputAutocomplete.svelte";
  import { TableExport } from "tableexport";
  import TableParoInsumoXls from "./components/TableParoInsumoXLS.svelte";
  import TableParoParteXls from "./components/TableParoParteXLS.svelte";
    import NauiDateRange from "../lib/naui/atoms/NauiDateRange.svelte";
    import { tooltip } from "../lib/components/ToolTip/tooltip";

  export let vendors;
  export let grupos;
  export let nombreVendor;
  let admin = true;
  let bloquear = false;

  let proveedor;
  let estado = "-1";
  let tipo = "-1";
  let codigo_insumo = "";
  let ordenCorte = "";
  let respuesta;

  let mensaje = "";
  let dialogOpenOK = false;
  let desactivar = false;

  let paros = [];

  let estados = [
    { value: "*", label: "Todos" },
    { value: "Solicitado", label: "Solicitado" },
    { value: "En proceso", label: "En proceso" },
    { value: "Despachado", label: "Despachado" },
    { value: "Solicitud muestra", label: "Solicitud muestra" },
    { value: "Envio muestra", label: "Envío de muestra" }
  ];

  let tipos = [
    { value: "I", label: "Insumos" },
    { value: "P", label: "Partes" },
  ];

  let vendorArray = ["Todos"];
  let tipoTabla;

  let vendor = [];

  onMount(() => {
    if (vendors) {
      vendors.forEach((item) => {
        vendorArray.push(item.name);
      });
    }
    vendorArray = vendorArray;

    admin =
      grupos.toUpperCase().includes("SOURCING") ||
      grupos.toUpperCase().includes("ADMINISTRADOR");

    if (!admin) {
      proveedor = nombreVendor;
      bloquear = true;
    }
    setTimeout(() => {
            document.getElementById("filtro_cod_insumo").focus();     
        }, 200);
  });


  // TODO: Método para buscar paros
  function buscarParos() {
    if (proveedor == null) {
      mensaje = "Debe diligenciar el campo PROVEEDOR en la busqueda";
      dialogOpenOK = true;
      return;
    }
    if (estado == "-1") {
      mensaje = "Debe diligenciar el campo ESTADO en la busqueda";
      dialogOpenOK = true;
      return;
    }
    if (tipo == "-1") {
      mensaje = "Debe diligenciar el campo TIPO en la busqueda";
      dialogOpenOK = true;
      return;
    }

    vendor = vendors.find((x) => x.name == proveedor);

    if (!vendor) {
      vendor = {
        id: "*",
      };
    }

    if (tipo == "I") {
      getParosInsumos(vendor.id, estado, codigo_insumo,ordenCorte);
    } else {
      getParosPartes(vendor.id, estado, codigo_insumo,ordenCorte);
    }

    tipoTabla = tipo;
  }

  async function getParosInsumos(pVendor, pEstado, pCod_insumo,pordenCorte) {
    desactivar = true;
    const payload = {
      proveedor: pVendor,
      estado: pEstado,
      codigo: pCod_insumo,
      ordenCorte: pordenCorte
    };
    const response = await fetch("/paro/paros-insumos-proveedor-estado", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    respuesta = await response.json();
    if (response.ok) {
      paros = respuesta.data;
      paros = paros;
      console.log(paros);
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
    desactivar = false;
  }

  async function getParosPartes(pVendor, pEstado, pCod_insumo,pordenCorte) {
    desactivar = true;
    const payload = {
      proveedor: pVendor,
      estado: pEstado,
      codigo: pCod_insumo,
      ordenCorte: pordenCorte
    };
    const response = await fetch("/paro/paros-partes-proveedor-estado", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    respuesta = await response.json();
    if (response.ok) {
      paros = respuesta.data;
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
    desactivar = false;
  }

  function excel() {
    let tabla = document.querySelector("#tabla");
    let tableExport = new TableExport(tabla, {
      exportButtons: false, // No queremos botones
      filename: "Reporte Paros", //Nombre del archivo de Excel
      sheetname: "Paros", //Título de la hoja
      bootstrap: true
    });
    console.log(tableExport);
    let datos = tableExport.getExportData();
    let preferenciasDocumento = datos.tabla.xlsx;
    tableExport.export2file(
      preferenciasDocumento.data,
      preferenciasDocumento.mimeType,
      preferenciasDocumento.filename,
      preferenciasDocumento.fileExtension,
      preferenciasDocumento.merges,
      preferenciasDocumento.RTL,
      preferenciasDocumento.sheetname
    );
  }
</script>

<section>
  <div class="box-b my-15">
    <div class="box-l w-100 sx-iii">
      {#if !bloquear}
        <div class="w-100">
          <InputAutocomplete
            id="cmbProveedor"
            bind:value={proveedor}
            list={vendorArray}
            label="PROVEEDOR"
            isDisabled={bloquear}
            focus={bloquear}
          />
        </div>
      {/if}
      <div class="w-50">
        <NauiSelect
          label="ESTADO"
          options={estados}
          id="cmbEstado"
          bind:value={estado}
        />
      </div>
      <div class="w-50">
        <NauiSelect
          label="TIPO"
          options={tipos}
          id="cmbTipos"
          bind:value={tipo}
        />
      </div>
      <div class="w-50" >
        <NauiInput
        id = "filtro_cod_insumo"
        label = "CODIGO SKU"
        type="text"
        bind:value={codigo_insumo}
        />
      </div>
      <div class="w-50" style="overflow: hidden;">
        <NauiInput
          id = "filtro_orden_corte"
          label = "ORDEN DE CORTE"
          type="text"
          bind:value={ordenCorte}
        />
      </div>
      <div class="w-100">
        <NauiButton
          caption="BUSCAR"
          disabled={desactivar}
          mode="solid"
          color="accent"
          className="mt-ii"
          on:click={buscarParos}
        />
      </div>
    </div>
    <div class="box-r sx-iii">
      <div class="w-100">
        <NauiButton
          caption="DESCARGAR EXCEL"
          mode="solid"
          color="secondary"
          on:click={excel}
        />
      </div>
      <NauiButton
        caption="SALIR"
        mode="outline"
        color="accent"
        on:click={() => {
          history.back();
        }}
      />
    </div>
  </div>
  {#if paros.length > 0}
    <div class="m-1" style="overflow-y:scroll; height:75vh;">
      {#if tipoTabla == "I"}
        <h4>Insumos</h4>
        <div style="overflow-x: scroll; overflow-y:hidden;">
          <table class="naui-table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Proveedor</th>
                <th>Solicitud</th>
                <th>Recipiente</th>
                <th>Orden de Corte</th>
                <th
                  >Referencia<br />
                  Prod</th
                >
                <th>Talla</th>
                <th>Código Insumo</th>
                <th>Descripción Insumo</th>
                <th>Consumo prenda</th>
                <th>Unidad Medida</th>
                <th>Motivo</th>
                <th>Cantidad faltante</th>
                <th>Fecha Solicitud</th>
                <th>Fecha actualización estado</th>
                <th>Estado</th>
                <th>Fecha respuesta</th>
                <th>Salida Bodega #</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              {#each paros as paro, i (paro.id)}
                <tr style="text-align: center;">
                  <td>{i + 1}</td>
                  <td>{paro.vendor_name}</td>
                  <td>{paro.id}</td>
                  <td>{paro.recipiente_codigo_recipiente}</td>
                  <td>{paro.recipiente_orden_corte === "" ? "Sin información" : paro.recipiente_orden_corte}</td>
                  <td>
                    {paro.recipiente_codigo_referencia_produccion}
                  </td>
                  <td>{paro.recipiente_talla}</td>
                  <td>{paro.insumo_codigo_insumo}</td>
                  <td class="descripcion" title={paro.insumo_descripcion} use:tooltip>{paro.insumo_descripcion}</td>
                  <td>{Number(paro.recipiente_insumo_cantidad).toFixed(2)}</td>
                  <td>{paro.insumo_uni_medida}</td>
                  <td>{paro.motivo}</td>
                  <td>{Number(paro.cantidad).toFixed(2)}</td>
                  <td><div div class="div-Fechas">{paro.fecha_sol}</td>
                    <td>
                    { paro.fecha_actualizacion_estado== null ? "Sin información" : paro.fecha_actualizacion_estado }
                  </td>
                  <td>{paro.estado}</td>
                  <td>
                    <div div class="div-Fechas">
                      {paro.fecha_resp == null
                        ? "Sin información"
                        : paro.fecha_resp}
                    </div>
                  </td>
                  <td>
                    {paro.bodega == "" ? "Sin información" : paro.bodega}
                  </td>
                  <td>
                    {paro.observacion == ""
                      ? "Sin información"
                      : paro.observacion}
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
          <TableParoInsumoXls {paros} id="tabla" display="none" />
        </div>
      {:else}
        <h4>Paros</h4>
        <div style="overflow-x: scroll; overflow-y:hidden;">
          <table class="naui-table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Proveedor</th>
                <th>Solicitud</th>
                <th>Recipiente</th>
                <th>Orden de Corte</th>
                <th
                  >Referencia<br />
                  Prod</th
                >
                <th>Talla</th>
                <th>Código Parte</th>
                <th>Descripción Parte</th>
                <th>Consumo prenda</th>
                <th>Motivo</th>
                <th>Cantidad faltante</th>
                <th>Fecha Solicitud</th>
                <th>Estado</th>
                <th>Fecha respuesta</th>
                <th>Fecha actualización estado</th>
                <th>Salida Bodega #</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              {#each paros as paro, i (paro.id)}
                <tr style="text-align: center;">
                  <td>{i + 1}</td>
                  <td>{paro.vendor_name}</td>
                  <td>{paro.id}</td>
                  <td>{paro.recipiente_codigo_recipiente}</td>
                  <td>{paro.recipiente_orden_corte === "" ? "Sin información" : paro.recipiente_orden_corte}</td>
                  <td>
                    {paro.recipiente_codigo_referencia_produccion}
                  </td>
                  <td>{paro.recipiente_talla}</td>
                  <td>{paro.parte_codigo_parte}</td>
                  <td use:tooltip title={paro.parte_descripcion} class="descripcion">{paro.parte_descripcion}</td>
                  <td>{Number(paro.recipiente_parte_cantidad).toFixed(2)}</td>
                  <td>{paro.motivo}</td>
                  <td>{Number(paro.cantidad).toFixed(2)}</td>
                  <td><div class="div-Fechas">{paro.fecha_sol}</div></td>
                  <td>{paro.estado}</td>
                  <td>
                    <div class="div-Fechas">
                      {paro.fecha_resp == null
                        ? "Sin información"
                        : paro.fecha_resp}
                    </div>
                  </td>
                  <td>
                    {paro.fecha_actualizacion_estado== null ? "Sin información" : paro.fecha_actualizacion_estado }
                  </td>
                  <td>
                    {paro.bodega == "" ? "Sin información" : paro.bodega}
                  </td>
                  <td> 
                    {paro.observacion == ""
                      ? "Sin información"
                      : paro.observacion}
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
          <TableParoParteXls {paros} id="tabla" display="none" />
        </div>
      {/if}
    </div>
  {/if}

  <Dialog bind:open={dialogOpenOK}>
    <Title>
      <div class="box sx-ii py-ii">
        <span class="material-icons accent">warning</span>
        <h3>Paros</h3>
      </div>
    </Title>
    <Content>
      <div class="max-w-400px text-c mx-auto px-15">
        <p class="large">
          {mensaje}
        </p>
      </div>
    </Content>
    <Actions>
      <div class="box-r sx-ii px-1 py-ii">
        <NauiButton
          caption="Aceptar"
          color="accent"
          on:click={() => {
            dialogOpenOK = false;
          }}
        />
      </div>
    </Actions>
  </Dialog>
</section>
<style>
  .div-Fechas{
    width: max-content;
  }
  .descripcion{
    text-wrap: nowrap;
    overflow-x: hidden;
    max-width: 250px;
    text-overflow: ellipsis;
  }
</style>