<script lang="ts">
    import { tooltip } from "../../lib/components/ToolTip/tooltip";

    
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
    import NauiSelect from "../../lib/naui/atoms/NauiSelect.svelte";
    import {createEventDispatcher, onMount} from "svelte";
 
    export let dataParte;  
    export let indice;      
    export let checked: boolean = false;
    export let admin;
    export let dataInicialParte;
    export let estadosModal;
    export let estadosModalVendor;
    export let ref;
    const dispatch = createEventDispatcher();


  $: {
    if (!checked) {
      
      dataParte = {...dataInicialParte};
      dataParte.observacion = dataInicialParte.observacion === "" ? "" : dataInicialParte.observacion;
      if(admin)
      {
        dataParte.bodega = dataInicialParte.bodega === "" ? "" : dataInicialParte.bodega;
      }
      else
      {
        dataParte.bodega = dataInicialParte.bodega;
      }
      dataParte.estado = dataInicialParte.estado;
      
      dataParte = dataParte;
    }
  }

  function onKeyPressCTRLEnter(e){
      dispatch('onkeypress', {
        event:e
      });
  }

  function actualizarInformacionParo() {
    if(checked){
      dataParte.observacion = dataInicialParte.observacion === "" ? "" : dataInicialParte.observacion;
      if(admin)
      {
        dataParte.bodega = dataInicialParte.bodega === "" ? "" : dataInicialParte.bodega;
      }
      else
      {
        dataParte.bodega = dataInicialParte.bodega;
      }
      dataParte.estado = dataInicialParte.estado;
    }
  }      
</script>

<tr style="text-align: center;">
  <td>
    <NauiCheckbox bind:value={checked} on:input on:click={actualizarInformacionParo}/>             
  </td>
  <td>{indice}</td>
  <td>{dataParte.vendor_name}</td>
  <td>{dataParte.id}</td>
  <td>{dataParte.recipiente_codigo_recipiente}</td>
  <td>{dataParte.recipiente_orden_corte === "" ? "Sin información" : dataParte.recipiente_orden_corte}</td>
  <td>
      {dataParte.recipiente_codigo_referencia_produccion}
  </td>
  <td>{dataParte.recipiente_talla}</td>
  <td>{dataParte.parte_codigo_parte}</td>
  <td 
    class="{$$restProps.class || ''}"
    title={dataParte.parte_descripcion} 
    use:tooltip
  >
    {dataParte.parte_descripcion}
  </td>
  <td>{Number(dataParte.recipiente_parte_cantidad).toFixed(2)}</td>
  <td>{dataParte.motivo}</td>
  <td>{Number(dataParte.cantidad).toFixed(2)}</td>
  <td><div class="div-Fechas">{dataParte.fecha_sol}</div></td>
  <td>
    <div class="div-Fechas">
      {dataParte.fecha_resp == null
        ? "Sin información"
        : dataParte.fecha_resp}
    </div>
  </td>
  <td>
    {dataParte.fecha_actualizacion_estado == null ? "Sin información" : dataParte.fecha_actualizacion_estado }
  </td>
  <td>
      {#if dataParte.checked }
        {#if admin}
          <NauiSelect
            label=""
            options={estadosModal}
            id="cmbEstadoModal"
            bind:value={dataParte.estado}
          />
        {:else}
          <NauiSelect
            label=""
            options={estadosModalVendor}
            id="cmbEstadoModal"
            bind:value={dataParte.estado}
          />
        {/if}
      {:else}
        {dataParte.estado}
      {/if}  
  </td>
  <td>
      {#if dataParte.checked }
        {#if admin}
          <NauiInput label="" id={`txtBodega-${dataParte.id}`} bind:value={dataParte.bodega} on:keypress={onKeyPressCTRLEnter} />
        {/if}
      {:else}
        {dataParte.bodega == "" ? "Sin información" : dataParte.bodega}
      {/if}
  </td>
  <td>
      {#if dataParte.checked }
        <NauiInput
          label=""
          id={`txtObservaciones-${dataParte.id}`}
          bind:value={dataParte.observacion}
          on:keypress={onKeyPressCTRLEnter}
        />
      {:else}  
        {dataParte.observacion == "" ? "Sin información" : dataParte.observacion}
      {/if}
  </td>
</tr>