<script lang="ts">
    import { onMount } from "svelte";

    export let label;
    export let type = "text";
    export let value;
    export let isDisabled = false;
    export let id = null;
    export let step = "1";
    export let required = false;
    export let min = "0";
    export let max = "100";

    let fill = false;
    let focus = false;
    let input;

    onMount(() => {
        setStatus();
    });

    function setStatus() {
        fill = value !== "" && value !== undefined;
        focus = false;
    }
</script>

<div class="naui-field" class:active={fill}>
    <div class="naui-field-back" class:fill>
        <label class:focus>{label}</label>
        {#if type === "email"}
            <input
                class="naui-input w-100"
                readonly={isDisabled}
                type="email"
                {id}
                {required}
                bind:value
                on:input
                on:change
                on:focus={() => (fill = true)}
                on:focusin={() => (focus = false)}
            />
        {:else if type === "password"}
            <input
                class="naui-input w-100"
                readonly={isDisabled}
                type="password"
                {required}
                {id}
                bind:value
                on:input
                on:focus={() => (fill = true)}
                on:focusin={() => (focus = false)}
            />
        {:else if type === "number"}
            <input
                class="naui-input w-100"
                readonly={isDisabled}
                type="number"
                {required}
                {step}
                {id}
                {min}
                {max}
                bind:value
                on:input
                on:change
                on:focus={() => (fill = true)}
                on:focusin={() => (focus = false)}
            />
        {:else}
            <input
                class="naui-input w-100"
                readonly={isDisabled}
                type="text"
                {required}
                {id}
                bind:value
                bind:this={input}
                on:input
                on:focus={() => (fill = true)}
                on:focusin={() => (focus = false)}
            />
        {/if}
    </div>
    <slot />
</div>

<style>
    .naui-field-back.fill {
        background: white;
    }

    .naui-field {
        width: 200%;
    }
</style>
