<script>
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    export let checked = false;
</script>

<thead>
<tr>
    <th>
        <div>
            <NauiCheckbox bind:value={checked}/>
        </div>
    </th>
    <th>
        <div>Grupo</div>
    </th>
    <th>
        <div>Nombre</div>
    </th>
    <th>
        <div>Valor</div>
    </th>
    <th>
        <div>Estado</div>
    </th>
    <th>
        <div>Actualizar</div>
    </th>
</tr>
</thead>
