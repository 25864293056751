<script lang="ts">
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import ASNItem from "./components/ASNItem.svelte";
    import ASNHead from "./components/ASNHead.svelte";
    import EmpaqueItem from "./components/EmpaqueItem.svelte";
    import EmpaqueHead from "./components/EmpaqueHead.svelte";
    import NauiInput from "../lib/naui/atoms/NauiInput.svelte";
    import {onMount} from 'svelte';
    import Dialog, {Actions, Content, Title} from "@smui/dialog";
    

    export let data = [];
    let globalCheck = false;
    let globalCheckE = false;
   
    
    let searchKey: string = null;

    let empaquesItems = [];
    let localData = [];
     

    let NoFact = ''   

    //let url_maquila = S
    onMount(() => {       
        loadData();
        searchKey = '';
        var link = document.getElementById('Empaques');
        link.style.display ='none';

        var link = document.getElementById('Basn');
        link.style.display ='none';
        
    });

    function loadData() {
        localData = data.map((x) => {
            return {...x, checked: false}
        });
    }

    function search(key) {
        if (!!key) {
            loadData();
            localData = localData.filter((x) => (x.id.toString().includes(key)))
        } else {
            loadData();
        }
    }



    function globalCheckChange(check) {        
        localData.forEach((x) => {
            if (!!filteredData) {
                if (filteredData.includes(x)) {
                    x.checked = check;
                }
            }
        });
        localData = localData;
    }


    function globalCheckEChange(check) { 
      //  alert(CalcularTotal()     )
            empaquesItems.forEach((x) => {                          
                    x.checked = check; 
                //    alert(x.checked)
           
            }
        );
        empaquesItems =  empaquesItems 
    }

    $: enabled = TotalFact != 0;

    $: globalCheckChange(globalCheck);
    $: globalCheckEChange(globalCheckE);
    $: filteredData = localData.filter( (x) => x.id.toString().includes(searchKey)); 
   
    $: checkedItems = filteredData.filter((x) => x.checked).map((y) => {
        return {
            id: y.id,
            empaques: y.empaques,
            checked: false
        }
    })
     
   function CalcularTotal()
    {
     
        let Total = 0;
        if(empaquesItems.length > 0){
            empaquesItems.forEach((x) => {
                Total += x.cantidad * x.valoru
            }       
           )
        }     
        TotalFact = Total
        return Total
    }    

    let TotalFact = 0
    

    function consultar_paquetes() {
        empaquesItems = []
        checkedItems.forEach((x) => {
          //  alert(x.id)
            x.empaques.data.forEach((y)=> {
                empaquesItems.push(y)
                                }
        )})

                                
        empaquesItems = empaquesItems.map((x) => {
            return {...x, valoru: 0, checked: false} })

        var link = document.getElementById('buscador');
        link.style.display ='none';
        link = document.getElementById('asn');
        link.style.visibility ='collapse';
        link = document.getElementById('bce');
        link.style.display ='none';
        link = document.getElementById('Basn');
        link.style.display ='block';
        
        link = document.getElementById('Empaques');
        link.style.display ='block';
        
    }
    let dialogOpen=false
    let dialogOpenReq =false
    async function facturar() {
           if (NoFact != '' && TotalFact != 0) {
            const payload = empaquesItems.map((x) => {
                return {...x, "nro" : NoFact}
            })
            
            const response = await fetch('/facturacion/guardar_factura', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)
            });
            let respuesta = await response.json();
            if (response.ok){                
                //alert('Factura Generada Exitosamente')           
                //window.location.href =  '/facturacion/home'
                mensaje = 'Factura Generada Exitosamente'
                dialogOpen = true
            }
            else
            {

                dialogOpen = true
                mensaje = respuesta.mensaje;
            }

        } else {
            mensajeReq = "Debe Ingresar un número de factura y el valor de la factura no debe ser 0"
            dialogOpenReq =true
            //alert('Debe Ingresar un número de factura y el valor de la factura no debe ser 0')
        }
    }

    function eliminar_empaques() {       

        let disAsnChk = [...new Set(empaquesItems.filter( (x) => x.checked == true ).map(x=> x.id))] 
        //console.log(disAsnChk)
        empaquesItems = empaquesItems.filter( (x) =>  disAsnChk.indexOf(x.id) == -1 )
        CalcularTotal()   
        
        if(disAsnChk.length == 0 )
        {  
            mensajeReq = "Seleccionar registros para borrar"
            dialogOpenReq =true         
        }    
         
    }


    function consultar_x_asn() {
        empaquesItems = []
        var link = document.getElementById('buscador');
        link.style.display ='block';
        link = document.getElementById('asn');
        link.style.visibility ='visible';
        link = document.getElementById('bce');
        link.style.display ='block';
        link = document.getElementById('Basn');
        link.style.display ='none';
        link = document.getElementById('Empaques');
        link.style.display ='none';
        TotalFact = 0
       
    }
    let mensaje 
    let mensajeReq 

</script>
<section>
 



   <!-- {empaquesItems}

    {empaquesItems.length}
  -->
   <div class="box-l sx-1 mt-1 mb-15" id="Nofac">
        <div class="relative">
            Número de Factura <input bind:value={NoFact} type="text" class="naui-input text min-w-200px"
                   placeholder="Ingrese No.">            
        </div>


        <div class="relative">
            <table>
                <tr>
                    <td width="150px">
                        Total a facturar:  
                    </td>                    
                    <td width="150px">
                        <NauiInput value={TotalFact} type="currency" label=""  isDisabled={true} /> 
                    </td>
                </tr>
            </table>
                     
        </div>

        <div class="relative">
            <NauiButton  caption="Generar Factura "  mode="outline" color="accent"   on:click={() => {
                enabled && facturar(); 
            }}    disabled={!enabled} /> 
        </div>


       
        <!-- <NauiButton caption="BUSCAR ASN"  mode="outline" color="accent"/>-->
    </div>




   


    <div id="bce">
        <center> <NauiButton  caption="Ver ASN(s)"  mode="outline" color="accent" on:click={consultar_paquetes} /> </center>
    </div> 

    <div id="Basn">
        <table class="naui-table w-70" >
        <tr>
            <td width="30px">
                <NauiButton  caption="Eliminar ASN(s)"  mode="outline" color="accent" on:click={eliminar_empaques} />
            </td>
            <td >
                <NauiButton  caption="Buscar por ASN "  mode="outline" color="accent" on:click={consultar_x_asn} /> 
            </td>
        </tr>
    </table>
             
    </div> 



    <div class="box-l sx-1 mt-1 mb-15" id="buscador">        
        
        <div class="relative">
            <input bind:value={searchKey} type="search" class="naui-input search min-w-400px"
                   placeholder="Buscar ASN">
            <span class="absolute material-icons-outlined" style="color: #888;">search</span>
        </div>
        <!-- <NauiButton caption="BUSCAR ASN"  mode="outline" color="accent"/>-->
    </div>

    <table class="naui-table w-100"  id="asn">
        <ASNHead bind:checked={globalCheck}/>
        <tbody>
        {#each filteredData as item (item.id)}
            <ASNItem data="{item}" bind:checked={item.checked} />
        {:else}
            <tr>
                <td colspan="6">
                    <div><p>No se encontraron registros</p></div>
                </td>
            </tr>
        {/each}
        </tbody>
    </table>
   
    <br>
    <br>
    <table class="naui-table w-100" id='Empaques'>
        <EmpaqueHead bind:checked={globalCheckE}/>
        <tbody>
        {#each empaquesItems as itemE}
            <EmpaqueItem data="{itemE}" bind:checked={itemE.checked} on:change={CalcularTotal}  />
        {:else}
            <tr>
                <td colspan="6">
                    <div><p>No se encontraron registros</p></div>
                </td>
            </tr>
        {/each}
        </tbody>
    </table>

    <Dialog bind:open={dialogOpen} >
        <Title>
            <h3 class="text-c">Generación de Factura</h3>
        </Title>
        <Content>
            <div class="max-w-400px text-c mx-auto px-15">
                <p class="large">
                    {mensaje} 
                </p>
            </div>
        </Content>
        <Actions>
            <div class="box-r sx-ii px-1 py-ii">
                <NauiButton caption="VOLVER" color="accent" on:click={()=> {window.location.href =  '/facturacion/home' } }/>
            </div>
        </Actions>
    </Dialog>
    
    <Dialog bind:open={dialogOpenReq} >
        <Title>
            <h3 class="text-c">Generación de Factura</h3>
        </Title>
        <Content>
            <div class="max-w-400px text-c mx-auto px-15">
                <p class="large">
                    {mensajeReq}                     
                </p>
            </div>
        </Content>
        <Actions>
            <div class="box-r sx-ii px-1 py-ii">
                <NauiButton caption="ACEPTAR" color="accent" on:click={()=> {dialogOpenReq=false } }/>
            </div>
        </Actions>
    </Dialog>

</section>
<style>
    .search {
        border-radius: 2rem;
        padding-left: 2.5rem;
    }

    .search + span {
        position: absolute;
        left: .75rem;
        top: .75rem;
        color: var(--secondary);
    }
/*
    .naui-filter {
        min-width: 150px;
        color: var(--secondary);
        opacity: 1;
    }*/
</style>