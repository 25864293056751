<script lang="ts">
    import NauiCheckbox from "../../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiTag from "../../../lib/naui/atoms/NauiTag.svelte";
    import {onMount} from "svelte";

    export let data;
    export let checked: boolean = false;
    let date = new Date();

    onMount(() => {
        date = new Date(data.date);
    })


</script>
<tr>
    <td>
        <div>
            <NauiCheckbox bind:value={checked}/>
        </div>
    </td>
    <td>
        <div>{data.recipiente}</div>
    </td>
    <td>
        <div>
            {#if data.estado === 'enviado'}
                <NauiTag label="ENVIADO" backColor="#EF9925" textColor="white"/>
            {:else if data.estado === 'recibido'}
                <NauiTag label="RECIBIDO" backColor="#25D0EF" textColor="white"/>
            {/if}
        </div>
    </td>
    <td>
        <div>{data.remision}</div>
    </td>
    <td>
        <div>{data.proveedor}</div>
    </td>
    <td>
        <div>{data.cantidad}</div>
    </td>
    <td>
        <div>{data.codigo_referencia_produccion}</div>
    </td>
    <td>
        <div>{data.descripcion_referencia_produccion}</div>
    </td>
    <td>
        <div>{data.talla_produccion}</div>
    </td>
    <td>
        <div>{data.color_produccion}</div>
    </td>
    <td>
        <div>
            <div class="naui-date">
                {date.toISOString().slice(0,10)}
            </div>
        </div>
    </td>
</tr>
