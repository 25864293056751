<script lang="ts">    
    
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
    import {onMount} from "svelte";
 
    export let data;        

    $: refrescar()

    function refrescar()
    {
        data = data
    }
    onMount(() => {
 
    })

    
</script>
        <tr>             
           
            <td >
                {data.recipiente} 
            </td>
            <td >
                {data.codigo_pro} 
            </td>
            <td >
                {data.desc_pro} 
            </td>              
            <td >
                {data.talla_pro} 
            </td>              
            <td >
                {data.color_pro} 
            </td>              
            <td >
                {data.codigo_ven} 
            </td>              
            <td >
                {data.desc_ven} 
            </td>              
            <td >
                {data.talla_ven} 
            </td>              
            <td >
                {data.color_ven} 
            </td>              
            <td >
                {data.cantidad} 
            </td>      
            <td >
                {data.cantidad_restante} 
            </td>     
            <td >
                {data.sam} 
            </td>                
            <td>
                {data.personas}
            </td>          
            <td>
                {data.peficiencia} 
            </td>     
            <td>
                {data.jornada}
            </td>
            <td >
                {data.min_dispo} 
            </td>       
            <td >
                {data.min_nece} 
            </td>       
            <td >
                {data.dias.toFixed(2)} 
            </td>       
            <td>{data.modulo} 
            </td> 
            <td>{data.prioridad}
            </td>    
            <td>{data.cantidad_confeccionada} 
            </td>
            <td>{data.estado_referencia} 
            </td>                       
</tr>
