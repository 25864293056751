<script>
  import { onMount } from "svelte";
  import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
  import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
  import InputAutocomplete from "./InputAutocomplete.svelte";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";

  export let data;
  export let auditables_defectos = [];

  // Información final y limpia del auditable y de los defectos que se envían al auditar
  export let auditable_defecto_data = [];
  
  let cantidad_defecto = 1;
  let id_defecto_auditable = 0;
  let defecto_id = 1;
  let defecto_select = "";
  let mensaje = "";
  let valores_lista_defecto;
  let defecto_existente;
  let dialogOpen = false;

  // Arreglo de defectos existentes
  let defectos = [];

  // Arreglo de tipo y descripción de la lista de defectos
  let tipo_descripcion_defectos = [];

  // Arreglo de los componentes en su estado inicial
  let defectoItemsComponente = [];

  // Arreglo de los componentes con valores diferentes al inicial
  let data_defectos = [];

  onMount(() => {
    auditables_defectos = data.lista_defectos_auditables;
    defectos = data.lista_defectos;
    procesar_lista_defectos(defectos);
    defectosExistentesAComponente(auditables_defectos);
  });

  function procesar_lista_defectos(defectos) {
    tipo_descripcion_defectos = defectos.map((el) => {
      return el.tipo + " - " + el.descripcion;
    });
    tipo_descripcion_defectos = tipo_descripcion_defectos;
  }

  async function defectosExistentesAComponente(defectosExistentes) {
    if (defectosExistentes.length > 0) {
      defectosExistentes.map((x) => {
        const itemExiste = {
          id: x.id,
          defecto_selec: x.defecto.tipo + " - " + x.defecto.descripcion,
          cant_defecto: x.cantidad,
          lista_defectos: tipo_descripcion_defectos,
          checked: false,
        };
        defectoItemsComponente.push(itemExiste);
        defectoItemsComponente = defectoItemsComponente;
      });
      if (defectoItemsComponente.length > 0) {
        defectoItemsComponente.map((x) => {
          agregarModificarComponenteDef(x);
        });
      }
    }
  }

  function validarInformacionArreglos() {
    if (defectoItemsComponente.length === 0) {
      data_defectos = [];
      data_defectos = data_defectos;
    }
    defectoItemsComponente = defectoItemsComponente;

    if (data_defectos.length === 0) {
      auditable_defecto_data = [];
      auditable_defecto_data = auditable_defecto_data;
    } else {
      data_defectos = data_defectos;
      auditable_defecto_data = data_defectos.map((x) => {
        return {
          id: x.id_item_defecto,
          defecto_id: x.id_defecto,
          auditable_id: x.id_auditable,
          cantidad: x.cantidad,
        };
      });
      auditable_defecto_data = auditable_defecto_data;
    }
  }

  function calcularCantidades() {
    data.cantidad_segundas = 0;
    data_defectos.map((x) => {
      data.cantidad_segundas += x.cantidad;
    });
    data.cantidad_aud = 0;
    data.cantidad_aud = data.cantidad_primeras + data.cantidad_segundas;
  }

  async function nuevoCampoDefecto() {
    if (data_defectos.length > 0) {
      let ultimo_id = data_defectos.slice(-1)[0].id_item_defecto;
      defecto_id = ultimo_id + 1;
    }

    const item = {
      id: defecto_id,
      defecto_selec: defecto_select,
      cant_defecto: cantidad_defecto,
      lista_defectos: tipo_descripcion_defectos,
      checked: false,
    };
    console.table(item);
    defecto_id++;

    defectoItemsComponente.push(item);
    defectoItemsComponente = defectoItemsComponente;
  }

  function quitarDefectoComponente(item) {
    item.checked = true;
    defectoItemsComponente = defectoItemsComponente.filter(
      (x) => x.checked === false
    );

    data.cantidad_segundas -= item.cant_defecto;

    data_defectos = data_defectos.filter((x) => x.id_item_defecto !== item.id);
    data_defectos = data_defectos;
    validarInformacionArreglos();
    calcularCantidades();
  }

  function modalAdvertenciaDefecto(defecto_no_existe) {
    dialogOpen = true;
    mensaje = `El defecto "${defecto_no_existe}" no se encuentra en la lista de defectos.`;
  }

  async function agregarModificarComponenteDef(item_defecto) {
    let defectoAudExiste = data_defectos.find((x) => x.id_item_defecto === item_defecto.id);
    let defectoExiste = defectoItemsComponente.find((x) => x.id === item_defecto.id);

    if (defectoAudExiste) {
      actualizarAuditableDefecto(item_defecto, defectoExiste, defectoAudExiste);
      calcularCantidades();
    } else {
      valores_lista_defecto = item_defecto.defecto_selec.split(" - ");
      defecto_existente = defectos.find((x) =>
          x.tipo === valores_lista_defecto[0] &&
          x.descripcion === valores_lista_defecto[1]
      );

      crearAuditableDefecto(item_defecto, defecto_existente);
      calcularCantidades();
    }
    validarInformacionArreglos();
  }

  function crearAuditableDefecto(item_defecto, defecto_existente) {
    const item = {
      id: id_defecto_auditable,
      id_item_defecto: item_defecto.id,
      cantidad: item_defecto.cant_defecto,
      id_defecto: defecto_existente === undefined ? 0 : defecto_existente.id,
      id_auditable: data.id,
      checked: item_defecto.checked,
    };
    id_defecto_auditable++;

    data_defectos.push(item);
    data_defectos = data_defectos;

    if (item.id_defecto === 0) {
      modalAdvertenciaDefecto(item_defecto.defecto_selec);
    }
  }

  function actualizarAuditableDefecto(item_defecto, defectoExiste, defectoAudExiste) {
    defectoExiste.defecto_selec = item_defecto.defecto_selec;
    defectoExiste.cant_defecto = item_defecto.cant_defecto;
    defectoItemsComponente = defectoItemsComponente;

    valores_lista_defecto = item_defecto.defecto_selec.split(" - ");
    defecto_existente = defectos.find(
      (x) =>
        x.tipo === valores_lista_defecto[0] &&
        x.descripcion === valores_lista_defecto[1]
    );

    if (defecto_existente) 
    {
      defectoAudExiste.cantidad = defectoExiste.cant_defecto;
      defectoAudExiste.id_defecto = defecto_existente.id;
    } else 
    {
      defectoAudExiste.id_defecto = null;
      modalAdvertenciaDefecto(item_defecto.defecto_selec);
    }
  }
</script>

<tr id="tr-cant-aud">
  <th> Cantidad auditada </th>
  <td>
    <NauiInput
      bind:value={data.cantidad_aud}
      type="number"
      label=""
      isDisabled={true}
    />
  </td>
</tr>

<tr class="segundas-table-row">
  <th> Cantidad primeras </th>
  <td>
    <NauiInput
      bind:value={data.cantidad_primeras}
      type="number"
      label=""
      min={0}
      on:change={calcularCantidades}
      on:input={calcularCantidades}
    />
  </td>
</tr>

<tr class="segundas-table-row">
  <th> Cantidad segundas </th>
  <td>
    <NauiInput
      bind:value={data.cantidad_segundas}
      isDisabled={true}
      type="number"
      min={0}
      label=""
    />
  </td>
</tr>
<tr class="segundas-table-row">
  <th>
    <NauiButton
      caption="Añadir Defecto"
      mode="outline"
      color="accent"
      on:click={nuevoCampoDefecto}
    />
  </th>

  {#each defectoItemsComponente as itemD}
    <td class="container-defectos">
      <InputAutocomplete
        id="cmbDefectos"
        bind:value={itemD.defecto_selec}
        list={itemD.lista_defectos}
        label="DEFECTOS"
        on:change={agregarModificarComponenteDef(itemD)}
      />
      <NauiInput
        bind:value={itemD.cant_defecto}
        type="number"
        min={1}
        label=""
        on:change={agregarModificarComponenteDef(itemD)}
        on:input={agregarModificarComponenteDef(itemD)}
      />

      <NauiButton
        icon="disabled_by_default"
        mode="outline"
        color="accent"
        iconStyle="outlined"
        on:click={quitarDefectoComponente(itemD)}
      />
    </td>
  {/each}
</tr>

<Dialog bind:open={dialogOpen} scrimClickAction="" escapeKeyAction="">
  <Title>
    <h3 class="text-c">Auditar</h3>
  </Title>
  <Content>
    <div class="max-w-400px text-c mx-auto px-15">
      <p class="large">
        {mensaje}
      </p>
    </div>
  </Content>
  <Actions>
    <div class="box-r sx-ii px-1 py-ii">
      <NauiButton
        caption="Aceptar"
        color="accent"
        on:click={() => {
          dialogOpen = false;
        }}
      />
    </div>
  </Actions>
</Dialog>

<style>
  .container-defectos {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
</style>
