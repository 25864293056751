<svelte:options accessors/>
<script lang="ts">
    import { createEventDispatcher, tick } from "svelte";

    import type { IBox, IEanData } from "../../models/interfaces";
    import Dialog, { Actions, Content, Title } from "@smui/dialog";
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
    import NauiTag from "../../lib/naui/atoms/NauiTag.svelte";
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import NauiFloatingAction from "../../lib/naui/components/NauiFloatingAction.svelte";
    import MaterialsDialog from "./MaterialsDialog.svelte";

    export let data: Array<IEanData> = [];
    export let boxes: Array<IBox>;
    export let checked: Array<any> = [];
    export let warningBox = null;

    export let box;
    let dispatch = createEventDispatcher();

    let ordering = {
        by: '',
        dir: 1
    };

    let checkedItems: any = {};

    let columns = [
        {
            slug: 'ean_prepack',
            label: 'EAN Prepack',
        },
        {
            slug: 'ean',
            label: 'EAN'
        },
        {
            slug: 'referencia',
            label: 'Referencia'
        },
        {
            slug: 'talla',
            label: 'Talla'
        },
        {
            slug: 'color',
            label: 'Color'
        },
        {
            slug: 'um',
            label: 'UM'
        },
        {
            slug: 'material',
            label: 'Material'
        },
        {
            slug: 'precio',
            label: 'PVP'
        },
        {
            slug: 'cantidad',
            label: 'Cantidad'
        },
        {
            slug: 'caja',
            label: 'Caja No.'
        },
        {
            slug: 'estado',
            label: 'Estado caja',
            order: false
        },
        {
            slug: 'adhesivo',
            label: 'Adhesivo'
        },
    ];
    let qt = 0;

    /* Dialogo precio */
    let dialogOpen = false;
    let priceItem = null;
    let materialsDialog;

    const colors = ['#B2EBF2', '#BBDEFB', '#FFE0B2', '#E1BEE7', '#C8E6C9', '#FFF9C4'];

    export function loadPackedItems(items, currentBox) {
        for (const item of items) {
            item.box = getBox(item.box_number)
            item.box.open = item.box.number === currentBox;
            item.box.items.push(item);
        }
        data = items;
        updateQuantity();
    }

    export function updateDeleted(newBox) {
        box = newBox;
        checkedItems = {};
        const preserve = [];
        for (const item of boxes) {
            if (item.number <= box) {
                preserve.push(item);
            } else {
                item.items = [];
            }
        }
        boxes = preserve;
        updateQuantity();
    }

    export function addItem(item: IEanData, newNumber) {
        //alert(box)
        let currentBox = getBox(box);

        if (!currentBox.open) {
            //alert('pin')
            //getBoxIndex(boxes[boxes.length - 1].number + 1)
            //currentBox = getBox(boxes[boxes.length - 1].number + 1);
            currentBox = getBox(newNumber);            
            box = currentBox.number; 
            changeBox(box)    
            //alert(box)       
        }

        const boxItems = data.filter(d => d.box.number === box);
        const obj = boxItems.find(d => (item.ean_128 && item.ean_128 === d.ean_128) || (d.ean_prepack_128 && d.ean_prepack_128 === item.ean_prepack_128 && d.ean_componente === item.ean_componente));

        if (obj) {
            obj.quantity += 1;
            data = data;
        } else {
            let differentEan;
            if (item.ean_128) {
                differentEan = boxItems.find(d => d.ean_128 !== item.ean_128);
            } else if (item.ean_prepack_128) {
                differentEan = boxItems.find(d => d.ean_prepack_128 !== item.ean_prepack_128)
            }
            if (differentEan) {
                return false
            }
            const currentBox = getBox(box);
            const newItem = Object.assign({}, item);

            newItem.box = currentBox;
            newItem.quantity = 1;
            newItem.original = item;
            currentBox.items.push(newItem);
            data = [...data, newItem];
            
        }     
        data = data.sort((a, b) => a.box.open > b.box.open ? -1 : a.box_number < a.box_number ? -1 : 0);
        updateQuantity();
        
        return true;
    }

    async function updateQuantity(item: IEanData = null) {
        await tick();
        if (item && item.ean_prepack_128) {
          data
            .filter(d => d.ean_prepack_128 === item.ean_prepack_128 && d.box.number === item.box.number)
            .forEach(d => d.quantity = item.quantity);
        }
        qt = data ? data.reduce((t, item) => t + (+item.quantity), 0) : 0;
        data = data;
    }
    
    export function getBox(numBox) {
        const boxItem: IBox = boxes.find(b => b.number === numBox);
        if (!boxItem) {
            const newBox = {
                number: numBox,
                open: false,
                items: []
            };
            boxes = [...boxes, newBox];
            return newBox;
        } else {
            return boxItem;
        }
    }

    function changeBox(num) {
        box = num;
        const currentBox = getBox(box);

        currentBox.open = !currentBox.open;

        for (const b of boxes) {
            if (b === currentBox) continue;
            b.open = false;
        }
        
        data = data.sort((a, b) => a.box.open > b.box.open ? -1 : a.box_number < a.box_number ? 1 : 0);
    }



    function setOrdering() {
        // TODO ordenamiento?
    }

    function selectAll(ev) {
        const checkItem = ev.target.checked;
        for (let i = 0; i < data.length; i++) {
            checkedItems[i] = checkItem;
        }
        dispatchChecked();
    }

    function updateSelected(item: IEanData, index: number) {
        const checkItem = !checkedItems[index];
        // for (let i = index; i < data.length; i++) {
        //     checkedItems[i] = checkItem;
        // }
        // checkedItems = checkedItems;
        for(let i = 0; i < data.length; i++){
            if (data[i].box.number == item.box.number)
                checkedItems[i] = checkItem;
        }
        checkedItems = checkedItems;
        dispatchChecked()
    }

    function dispatchChecked() {
        const listChecked = Object.keys(checkedItems).filter(key => checkedItems[key]);
        dispatch('checked', listChecked);
    }

    function save() {
        dispatch('save', data);
    }

    function validatePrice(item: IEanData, newPrice: number) {
        const xprice = +(newPrice.toString().replace(/\D+/g, ''));
        item.price = xprice;
        data = data;
        if (xprice !== item.original.price) {
            showPriceDialog(item);
        }
    }

    function showPriceDialog(item: IEanData) {
        priceItem = item;
        dialogOpen = true;
    }

    function restorePriceItem() {
        priceItem.price = priceItem.original.price;
        data = data;
        priceItem = null;
        dialogOpen = false;
    }

    function getColor(box: IBox) {
        return colors[box.number % 2];
    }
</script>
<div class="mt-1" style="margin-bottom: 120px">
    <table class="naui-table w-100" class:hidden={data.length === 0}>
        <thead>
        <tr>
            <th>
                <div>
                    <NauiCheckbox on:input={selectAll}/>
                </div>
            </th>
            {#each columns as col}
                <th style="width: {col.width? col.width: 'auto'}">
                    <div class="box-l pointer w-max-content" on:click={setOrdering.bind(this, col.slug)}>
                        <div class="flex-col box mr-i">
                            {#if ordering.by === col.slug}
                                {#if ordering.dir === 1}
                                    <div class="order-caret">
                                        <i class="fa fa-caret-up self-start"></i>
                                    </div>
                                {:else}
                                    <div class="order-caret">
                                        <i class="fa fa-caret-down self-end"></i>
                                    </div>
                                {/if}
                            {:else}
                                <div class="order-caret">
                                    <i class="fa fa-caret-up self-end"></i>
                                </div>
                                <div class="order-caret">
                                    <i class="fa fa-caret-down self-start"></i>
                                </div>
                            {/if}
                        </div>
                        {#if col.slug === "caja" && warningBox}
                            <span class="material-icons accent" title={warningBox}>warning</span>
                        {/if}
                        <div class="primary">{col.label}</div>
                    </div>
                </th>
            {/each}
        </tr>
        </thead>
        {#each data as item, i}
            <tbody>
            <tr>
                <td>
                    <NauiCheckbox bind:value={checkedItems[i]} on:input={() => {updateSelected(item, i)}}/>
                </td>
                <td>{item.ean_prepack || '-'}</td>
                <td>{item.ean || item.ean_componente}</td>
                <td>{item.reference}</td>
                <td>{item.size || item.talla_componente}</td>
                <td>{item.color || item.color_componente}</td>
                <td>{item.unit}</td>
                <td>
                    <div class="text-c accent pointer" style="text-decoration: underline"
                         on:click={() => materialsDialog.showInfo(item)}>
                        Material
                    </div>
                </td>
                <td>
                    <div style="margin-top: -0.5em; max-width:150px" class="relative">
                        <NauiInput value={item.price} type="currency" label="" on:change={(ev) => {validatePrice(item, ev.target.value)}}>
                            {#if item.price !== item.original.price}
                                <div class="absolute" style="right: 8px; top: 20px; z-index: 10;"
                                     on:click={() => {showPriceDialog(item)}}>
                                    <i class="fas fa-exclamation-triangle" style="color: orangered;"></i>
                                </div>
                            {/if}
                        </NauiInput>
                    </div>
                </td>
                <td>
                    <div style="margin-top: -0.5em; max-width:100px">
                        <NauiInput bind:value={item.quantity} type="number" label="" on:change={() => updateQuantity(item)}/>
                    </div>
                </td>
                <td>
                    <div class="box-c">
                        <NauiTag borderColor="transparent" label={item.box.number} backColor="{getColor(item.box)}"/>
                    </div>
                </td>
                <td>
                    <div class="box-c">
                        <NauiButton caption={item.box.open ? 'CERRAR CAJA' : 'ABRIR CAJA'} color="accent"
                                    size="small" on:click={() => changeBox(item.box.number)}/>
                    </div>
                </td>
                <td>
                    <div class="box-c">
                        {#if item.url_sticker}
                            <NauiButton fa="far fa-sticky-note" color="accent"
                                        href="/scanpack/api/sticker/{item.box ? item.box.number : item.box_number}"/>

                        {:else }
                            <small class="text-c p-i" style="font-size:13px">Se requiere<br>guardar...</small>
                        {/if}
                    </div>
                </td>
            </tr>
            </tbody>
        {/each}
    </table>

    {#if data.length > 0 || checked.length > 0}
    <NauiFloatingAction>
        <div class="box-r sx-1">
            <div class="grid-2 py-1" style="border-top: 1px solid var(--border-color)">
                <div class="text-l"><strong>Cantidad total</strong></div>
                <div class="text-c">{qt}</div>
            </div>
            <NauiButton fa="far fa-save" on:click={save} caption="GUARDAR" color="secondary" mode="solid"/>
        </div>
    </NauiFloatingAction>
    {/if}
</div>

<Dialog bind:open={dialogOpen}>
    <Title>
        <div class="py-ii max-w-300px mx-auto text-c">
            <div class="box mb-ii">
                <span class="material-icons" style="color: orangered">warning</span>
            </div>
            <h5 style="line-height: 1.4">El precio ingresado no corresponde con el precio en SAP</h5>
        </div>
    </Title>
    <Content>
        <div class="max-w-400px py-ii text-c mx-auto">
            <p class="large">
                Valide el precio ingresado. Podrá omitir este aviso y continuar, pero la alerta seguirá visible mientras
                el precio no coincida.
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box sx-1 px-1">
            <NauiButton caption="REESTABLECER PRECIO" color="accent" on:click={restorePriceItem}/>
            <NauiButton caption="OMITIR" color="accent" on:click={() => {dialogOpen = false;}}/>
        </div>
    </Actions>
</Dialog>
<MaterialsDialog bind:this={materialsDialog}/>

<style>
    .order-caret {
        line-height: 5px;
        font-size: .8rem;
        opacity: .5;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .order-caret i {
        line-height: 8px;
    }

    .order-caret:hover {
        opacity: .8;
    }

    .order-caret:active {
        opacity: 1;
    }
</style>
