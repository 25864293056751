<script lang="ts">
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import {onMount} from "svelte";
    import { validate_store } from "svelte/internal";
    import EmpaqueItem from "./EmpaqueItemVer.svelte";
    import EmpaqueHead from "./EmpaqueHead.svelte";
    import { formats } from "flatpickr/dist/utils/formatting";


    export let data;   
    //let empaquesItems  = []
    let empaquesFull = [] //data.emp

    function ver(){
        var link = document.getElementById('detalle_factura' + data.id);
        if (!viendo )        
            link.style.visibility ='visible';
        else
            link.style.visibility ='collapse';

            viendo = !viendo
    }

    function descargar(){
         var forma = document.getElementById(data.id)
         forma.submit();
    }
    
    let viendo = false

    onMount(() => {    
        var link = document.getElementById('detalle_factura' + data.id);
        link.style.visibility ='collapse';
        empaquesFull = data.emp.map((x) => {
                    return {id: x.id,
                            codigo_referencia_venta: x.codigo_referencia_venta,
                            descripcion_referencia_venta: x.descripcion_referencia_venta,
                            talla: x.talla,
                            color: x.color,
                            cantidad: x.cantidad,                            
                            valoru: x.valor
                        }
                });
        console.log(empaquesFull);     
    })
   
    const formatterPeso = new Intl.NumberFormat('es-CO', {
       style: 'currency',
       currency: 'COP',
       minimumFractionDigits: 0
     })
    
</script>
<tr>    
    <form action="/facturacion/download" method="get" target="_blank" id="{data.id}">
        <input type="hidden" name="id" value="{data.id}">
        <input type="hidden" name="fecha" value="{data.date}">
     </form>
    <td>
        <div>{data.id}</div>
    </td>
    <td>
        <div>{data.date}</div>
    </td>   
    <td>
        <div>{data.vendor}</div>
    </td>   
    <td>
        <div>{data.sap}</div>
    </td>   
    <td>
        <div>{data.factura}</div>
    </td>   
    <td style="text-align:right" width="150px">
        {formatterPeso.format(data.total)} &nbsp; 
    </td>   
    <td>
        <NauiButton caption="Ver"  mode="outline" color="accent" on:click={ver}/>
    </td>   
    <td>
        <NauiButton icon="file_download" mode="outline"   color="accent" iconStyle="outlined" on:click={descargar} />
        
    </td>       
</tr>

<tr id='detalle_factura{data.id}' >    
    <td>
        
    </td>   
    <td colspan="6">
        <table class="naui-table w-100" id='Empaques'>
            <EmpaqueHead />
            <tbody>
            {#each empaquesFull as itemE}
                <EmpaqueItem data="{itemE}"  />
            {:else}
                <tr>
                    <td colspan="6">
                        <div><p>No se encontraron registros</p></div>
                    </td>
                </tr>
            {/each}
            </tbody>
        </table>
    </td>   
    <td>
        
    </td>    
</tr>
