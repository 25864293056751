<script lang="ts">
    export let shadow: boolean = true;
</script>
<div class:shadow class="floating-actions p-1 box-r sx-1 back-white radius">
    <slot>

    </slot>
</div>
<style>
    .floating-actions{
        position: fixed;
        bottom:0;
        right:0;
        z-index:10;
    }
</style>
