<script lang="ts">
    
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import {onMount} from "svelte";
    import NauiSelect from "../../lib/naui/atoms/NauiSelect.svelte";
    import NauiDatePicker from "../../lib/naui/atoms/NauiDatePicker.svelte";
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
   
    export let data;   
    export let estados
    export let checked: boolean = false;

    onMount(() => {
    })
    
</script>
 <tr> 
            <td>
                <div>
                    <NauiCheckbox bind:value={checked}/>
                </div>
            </td>
            <td >
                {data.solicitud} 
            </td>  
            <td >
                {data.vendor} 
            </td>    
            <td >
                {data.referencia} 
            </td>
            <td >
                {data.tipo_auditoria} 
            </td>
            <!--
                <td >
                <NauiCheckbox bind:value={data.liquidar}  disabled = {true}/>                             
                </td>
            --> 
            <td >
                {data.tipo_empaque} 
            </td>                
            <td >
                {data.Q} 
            </td>
            <!--
                <td >
                {data.orden} 
                </td>
                <td >
                <NauiCheckbox bind:value={data.critica}  disabled = {true}/>  
                </td>            
                <td >                   
                    <NauiCheckbox bind:value={data.auditada} disabled = {true}/>  
                </td>
            -->
            <td >
                {data.cantidad} 
            </td>   
            <td >
                <NauiSelect  bind:value={data.estado} options={estados}  label=""   />
            </td>         
            <td >
                <NauiDatePicker  bind:value={data.fecha_sug}  label=""  />
                
            </td>
            <td >
                <input bind:value={data.hora_sug} type="time"  label=""  />
                
            </td>
            <td >
                <NauiInput bind:value={data.auditor} type="text" label=""  />
                
            </td>            
            <td >
                <NauiCheckbox bind:value={data.medida}  disabled={true}/>                       
            </td>            
            <td >                    
                <NauiCheckbox bind:value={data.empaque}  disabled={true}/>                    
            </td>            
            <td >
                <NauiCheckbox bind:value={data.confeccion}  disabled={true}/>                    
            </td>                   

</tr>