<script lang="ts">
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
    import {onMount} from "svelte";
    import { validate_store } from "svelte/internal";

    export let data;   

    
    export let checked: boolean = true;
    
    $: total = data.cantidad *  data.valoru //(isNaN(valoru)?0:valoru)
   
        

    onMount(() => {
        console.log(data);      
    })

    
</script>
<tr>
    <td>
        <div>
            <NauiCheckbox bind:value={checked}/>             
        </div>
    </td>
   <!-- <td>
        <div>{data.id}</div>
    </td>   
-->
    <td>
        <div>{data.id}</div>
    </td>
    <td>
        <div>{data.codigo_referencia_venta}</div>
    </td>
    
    <td>
        <div>{data.descripcion_referencia_venta}</div>
    </td>
    <td>
        <div>{data.talla}</div>
    </td>
    <td>
        <div>{data.color}</div>
    </td>
    <td>
        <div>{data.cantidad}</div>
    </td>    
    <td>
        <NauiInput bind:value={data.valoru} type="currency" label="" on:change />                     
    </td>  
    <td>
        <div>
            <NauiInput value={total} type="currency" label=""  isDisabled={true} />
        </div>
    </td>   
</tr>
