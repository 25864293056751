<svelte:options accessors/>
<script lang="ts">
    import NauiCheckbox from "../atoms/NauiCheckbox.svelte";
    import NauiTableField from "./NauiTableField.svelte";
    import {createEventDispatcher} from "svelte";
    import type {ITableField} from "../interfaces";


    export let columns: Array<ITableField> = [];
    export let data;
    export let total;
    export let optionGroup = null;

    const dispatch = createEventDispatcher();

    $: cols = columns.length;
    $: {
        listenChanges(data)
    }

    let ordering = {
        by: '',
        dir: 1
    };

    function updateSelected(ev) {
        data.forEach(d => {
            d.selected = ev.target.checked;
        });
        data = [...data];
    }

    function listenChanges(newValue) {
        dispatch('checked', newValue.filter(i => i.selected));
    }

    function setOrdering(slug) {
        if (ordering.by === slug) {
            ordering.dir *= -1;
        } else {
            ordering.dir = -1;
        }
        ordering.by = slug;
        if (total > data.length) {
            dispatch('order', ordering);
        } else {
            data.sort((a, b) => {
                let test;
                if(!isNaN(a[slug]) && !isNaN(b[slug])){
                    test = a[slug] - b[slug];
                }
                else if (typeof a[slug] === 'string') {
                    test = a[slug].localeCompare(b[slug]);
                } else if (typeof a[slug] === 'number') {
                    test = a[slug] - b[slug];
                } else if (slug === 'estado') {
                    test = a[slug].code - b[slug].code;
                }
                return test * -(ordering.dir);
            });
            data = data;
        }
    }

</script>
<div>
    <table class="naui-table w-100">
        <thead>
        <tr>
            {#each columns as col}
                <th style="width: {col.width}" class="{col.class}">
                    <div class:box={col.alignment==='center'}>
                        {#if col.type === 'checkbox'}
                            <NauiCheckbox on:input={updateSelected}/>
                        {:else}
                            {#if col.order}
                                <div class="box-l pointer" on:click={setOrdering.bind(this, col.slug)}>
                                    <div class="flex-col box mr-i">
                                        {#if ordering.by === col.slug}
                                            {#if ordering.dir === 1}
                                                <div class="order-caret">
                                                    <i class="fa fa-caret-up self-start"></i>
                                                </div>
                                            {:else}
                                                <div class="order-caret">
                                                    <i class="fa fa-caret-down self-end"></i>
                                                </div>
                                            {/if}
                                        {:else}
                                            <div class="order-caret">
                                                <i class="fa fa-caret-up self-end"></i>
                                            </div>
                                            <div class="order-caret">
                                                <i class="fa fa-caret-down self-start"></i>
                                            </div>
                                        {/if}
                                    </div>
                                    <div class="primary w-max-content">{col.label}</div>
                                </div>
                            {:else}
                                {col.label}
                            {/if}
                        {/if}
                    </div>
                </th>
            {/each}
        </tr>
        </thead>
        <tbody>
        {#each data as item (item.slug)}
            <tr>
                {#each columns as col}
                    <td>
                        <div>
                            <NauiTableField field={col} data={item[col.slug]} bind:selected={item.selected}
                                            options={optionGroup ? optionGroup[item.slug] : [] } on:action/>
                        </div>
                    </td>
                {/each}
            </tr>

        {:else}
            <tr>
                <td colspan="{cols}">
                    <div>
                        <p>No se han encontrado registros</p>
                    </div>
                </td>
            </tr>
        {/each}
        </tbody>
    </table>
</div>
<style>
    .order-caret {
        line-height: 5px;
        font-size: .8rem;
        opacity: .5;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .order-caret i {
        line-height: 8px;
    }

    .order-caret:hover {
        opacity: .8;
    }

    .order-caret:active {
        opacity: 1;
    }

    .el-actions {
        justify-content: flex-start;
    }

    @media (max-width: 1024px) {
        .naui-table {
            border: 1px solid red;
            display: block;
        }

        .naui-table tr, .naui-table td {
            display: block;
            border: none;
        }

        .naui-table td > div {
            height: auto;
            min-height: 30px;
        }
    }


</style>
