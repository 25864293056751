<svelte:options accessors/>
<script lang="ts">
    import NauiTable from "../components/NauiTable.svelte";
    import NauiFilters from "../components/NauiFilters.svelte";
    import NauiPagination from "../components/NauiPagination.svelte";
    import {createEventDispatcher} from "svelte";
    import type {ITableField} from "../interfaces";

    export let count = 0;
    export let pages = 0;
    export let searchLabel = 'Search by keyword';
    export let countLabel = 'Entries';
    export let filters = null;
    export let hideSearch = false;
    export let hideCount = false;
    export let columns: Array<ITableField>;
    export let data;
    export let paginate = false;
    export let paginator = {
        total: pages,
        page: 1
    }

    const dispatch = createEventDispatcher();

    let filtersCmpt;
    let table;
    let edit = false
    let searchVal = ''
    let term;
    let selectedFilters = {};
    let timer;
    let ordering = {};

    const debounce = v => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            term = v;
            emitFilters();
        }, 300);
    }

    function handleFilter(evt) {
        selectedFilters = JSON.parse(JSON.stringify(evt.detail));
        if (Object.keys(selectedFilters).length === 0) {
            searchVal = '';
            term = ''
        }
        emitFilters();
    }

    function handleOrder(evt) {
        ordering = evt.detail;
        emitFilters();
    }

    function emitFilters() {
        paginator.page = 1;
        dispatch('filter', {
            term: term || '',
            ...selectedFilters,
            ...ordering
        });
    }

    export function getParams() {
        const params = {
            page: paginator.page
        }
        if (term) {
            params.term = term
        }
        const order = {};
        if (ordering.by && ordering.dir) {
            order.order_by = ordering.by;
            order.order_dir = ordering.dir === 1 ? 'd' : 'a';
        }
        return {
            ...params,
            ...selectedFilters,
            ...order
        };
    }

    export function updatePages(pages) {
        paginator.total = pages;
        paginator = paginator;
    }

    export function clear() {
        term = '';
        filtersCmpt.clear();
    }
</script>
<div>
    <div class="box-b">
        {#if !hideSearch}
            <div class="box-l sx-ii">
                <div class="search-wrap relative">
                    <input class="naui-input search-input"
                           type="text"
                           on:keyup={({ target: { value } }) => debounce(value)}
                           bind:value={searchVal}
                           placeholder={searchLabel}/>

                    <span class="material-icons-outlined" style="color:#888">search</span>
                </div>
                {#if !hideCount}
                    <div class="radius py-ii px-1 back-gray"><strong>{count}</strong> {countLabel}</div>
                {/if}
            </div>
            <div>
                <slot name="actions"/>
            </div>
        {/if}
    </div>
    {#if filters}
        <div class="my-1">
            <div class="box-b">
                <NauiFilters bind:this={filtersCmpt} {filters} on:filtered={handleFilter}/>
                <div>
                    <slot name="table-actions"/>
                </div>
                {#if hideSearch}
                    <div>
                        <slot name="actions"/>
                    </div>
                {/if}
            </div>
        </div>
    {/if}
    <div>
        <div class="pb-1" style="max-width: calc(100vw - 64px - 2rem); overflow-x: scroll">
            <NauiTable {data}
                       bind:this={table}
                       bind:total={count}
                       {columns}
                       on:action={(evt) => dispatch('action', evt.detail)}
                       on:checked
                       on:order={handleOrder}/>
        </div>
        {#if paginate}
            <NauiPagination bind:data={paginator}
                            on:next/>
        {/if}
    </div>
</div>
<style>
    .search-input {
        min-width: 400px;
        border-radius: 3rem;
        padding-left: 2.5rem;
    }

    .search-wrap span {
        position: absolute;
        left: .75rem;
        top: 12px;
        z-index: 2;
    }
</style>
