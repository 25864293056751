export async function buscarWips(recipiente, referencia, vendor) {
  const payload = {
    recipiente: recipiente,
    referencia: referencia,
    vendor: vendor,
  };

  const response = await fetch("/wip/buscar", {
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const resp = await response.json();
  if (response.ok) {
    const wips = resp["data"];
    const infoModulos = new Map();
    for (const x of wips) {
      const k = claveModulo(x);
      let modulo = infoModulos.get(k);
      if (!modulo) {
        modulo = {
          vendorId: x.vendor,
          nombreVendor: x.nombre_vendor,
          codigo: x.modulo,
          recipientes: [],
        };
        infoModulos.set(k, modulo);
      }
      modulo.recipientes.push(x);
    }
    return new Map([...infoModulos].sort());
  }
  return null;
}

export async function consultaDeFiltros(codigo_recipiente, referencia_venta) {
  const payload = {
    codigo_recipiente: codigo_recipiente,
    referencia_venta: referencia_venta,
  };
  const response = await fetch("/wip/consultar", {
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const resp = await response.json();
  if (resp != null) {
    return resp['data'];
  } else {
    return null;
  }
}

function claveModulo(wip) {
  return `${wip.vendor.toString().padStart(6, "0")}-${wip.modulo}`;
}
