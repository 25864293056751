<script lang="ts">
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiTag from "../../lib/naui/atoms/NauiTag.svelte";
    import {onMount} from "svelte";
    import { validate_store } from "svelte/internal";
    import NauiSelect from "../../lib/naui/atoms/NauiSelect.svelte";

    export let data;   
    export let refs =[];

 
    let tipos_empaque = [{
            label: 'Individual    ',
            value: 'Individual'
        },
        {
            label: 'Prepack',
            value: 'Prepack'
        },
    ]

    let tipos_auditoria = [{
            label: 'Primeras',
            value: 'Primeras'
        },
        {
            label: 'Segundas',
            value: 'Segundas'
        }
    ]
        

    onMount(() => {
        console.log(data);      
    })

    
</script>
        <tr> 
            <td>
                <div>
                    <NauiCheckbox bind:value={data.checked}/>             
                </div>
            </td>       
            <td >
                <NauiSelect  bind:value={data.referencia_sel} options={refs}  label=""   />
            </td>
            <td >
                <NauiSelect  bind:value={data.TipoAuditoria} options={tipos_auditoria} disabled={true} label=""  />
            </td>
            <td >
                <NauiCheckbox bind:value={data.Liquidar}  disabled = {true}/>  
            </td>
            <td >
                <NauiSelect  bind:value={data.TipoEmpaque} options={tipos_empaque} disabled={true} label=""  />
            </td>
            <td >
                {data.Q != "" ? data.Q : "N/A"} 
            </td>
            <td >
                {data.Orden} 
            </td>              
            <td >
                <NauiCheckbox bind:value={data.Critica}  disabled = {true}/>  
            </td>
            <td >                    
                <NauiCheckbox bind:value={data.Auditada} disabled = {true}/>  
            </td>
            <td >
                {data.Cantidad} 
            </td>            
            <td >
                {data.Fecha}
                
            </td>
            <td >
                {data.Hora}
            </td>
            <td >
                <NauiCheckbox bind:value={data.Medida}  disabled={true}/>                       
            </td>            
            <td >                    
                <NauiCheckbox bind:value={data.Empaque}  disabled={true}/>                    
            </td>            
            <td >
                <NauiCheckbox bind:value={data.Confeccion}  disabled={true}/>                    
            </td>                   

</tr>
