<script>
  import NauiInput from "../lib/naui/atoms/NauiInput.svelte";
  import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
  import { onMount } from "svelte";
  import Dialog, { Header, Title, Content, Actions } from "@smui/dialog";
  import NauiFloatingAction from "../lib/naui/components/NauiFloatingAction.svelte";
  import { empty } from "svelte/internal";

  let recipientes = [];
  let recipiente = "";
  let respuesta;
  let filtro = "";

  let mensaje = "";
  let dialogOpenOK = false;
  let desactivar = false;
  let desactivarBuscar = true;
  let desactivarGrabar = true;
  let desactivarConsultar = true;
  let modalInsumos = false;
  let modalPartes = false;
  let modalParos = false;

  let insumos = [];
  let partes = [];

  let motivoInsumos = [];
  let cantidadInsumos = [];
  let observacionInsumo = [];

  let motivoPartes = [];
  let cantidadPartes = [];
  let observacionParte = [];

  let parosInsumos = [];
  let parosPartes = [];

  async function getRecipientes() {
    //desactivar = true;
    const payload = filtro;
    const response = await fetch("/maquila/recipientes-codRecipiente", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ codigo: payload }),
    });
    respuesta = await response.json();
    if (response.ok) {
      recipientes = respuesta.data;
      modalRecipienteError(recipientes);
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
    recipiente = "";
    filtro = "";
    desactivarBuscar = true;
  }

  function validarInputBusqueda(filtro){
    if(filtro !== ""){
          desactivarBuscar = false;
    } else {
      desactivarBuscar = true;
    }
  }

  function validarEliminarParos(parosInsumo, parosParte){
    if(parosInsumo.length <= 0 && parosParte.length <= 0){
      desactivarConsultar = true;
      desactivarGrabar = true;
    } else{
      desactivarConsultar = false;
      desactivarGrabar = false; 
    }
  }

  function validarTotalParos(paros){
    if(paros.length !== 0){
      desactivarConsultar = false;
      desactivarGrabar = false;
    } else {
      desactivarConsultar = true;
      desactivarGrabar = true;
    }
  }

  function modalRecipienteError(recipiente){
    if(recipiente.length === 0){
      desactivarGrabar = true
      mensaje = "El recipiente no se encuentra en el estado recibido.";
      dialogOpenOK = true;
    }
  }

  function ValidacionCantInferior(){
    desactivarGrabar = true;
    desactivarConsultar = true;
    mensaje = "La cantidad faltante no puede ser menor a cero.";
    dialogOpenOK = true;
  }

  function modalValidacionCantidad(){
    desactivarGrabar = true;
    desactivarConsultar = true;
    mensaje = "La cantidad faltante no puede ser mayor a la cantidad de consumo prenda.";
    dialogOpenOK = true;
  }

  function abrirModalInsumos(pRecipiente) {
    recipiente = pRecipiente;
    insumos = [];
    motivoInsumos = [];
    cantidadInsumos = [];
    observacionInsumo = [];
    getInsumosxRecipiente(recipiente);
  }

  function abrirModalPartes(pRecipiente) {
    recipiente = pRecipiente;
    partes = [];
    motivoPartes = [];
    cantidadPartes = [];
    observacionParte = [];
    getPartesxRecipiente(recipiente);
  }

  async function getInsumosxRecipiente(pRecipiente) {
    desactivar = true;
    const payload = pRecipiente;
    const response = await fetch("/maquila/insumos-recipiente", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ payload: payload }),
    });
    respuesta = await response.json();
    if (response.ok) {
      insumos = respuesta.data;

      insumos.forEach((insumo) => {
        motivoInsumos[insumo.id] = "";
        cantidadInsumos[insumo.id] = 0;
        observacionInsumo[insumo.id] = "";
      });
      modalInsumos = true;
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
    desactivar = false;
  }

  function guardarTemporalInsumos() {
    let swModal = false;
    insumos.forEach((insumo) => {
      if (cantidadInsumos[insumo.id] != 0) {
        const index = parosInsumos.findIndex((x) => x.id == insumo.id);
        if (index !== -1) {
          parosInsumos.splice(index, 1);
        }
        const vObj = {
          id: insumo.id,
          codigo: insumo.insumo_codigo,
          descripcion: insumo.insumo_descripcion,
          cantidad: insumo.cantidad,
          uni_medida: insumo.insumo_uni_medida,
          motivo: motivoInsumos[insumo.id],
          cantidad_faltante: cantidadInsumos[insumo.id],
          observacion_insumo: observacionInsumo[insumo.id]
        };
        if(cantidadInsumos[insumo.id] < 0){
          ValidacionCantInferior();
          swModal = true;
        } else if(cantidadInsumos[insumo.id] > insumo.cantidad){
          modalValidacionCantidad();
          swModal = true;
        } else {
          parosInsumos.push(vObj);
          swModal = false;
          validarTotalParos(parosInsumos);
        }
      }
    });
    parosInsumos = parosInsumos;
    console.log(parosInsumos);
    modalInsumos = swModal;
  }

  function abrirModalParos() {
    modalParos = true;
  }

  function eliminarParo(pParo, pTipo) {
    if (pTipo == "I") {
      const index = parosInsumos.findIndex((x) => x.id == pParo.id);
      if (index !== -1) {
        parosInsumos.splice(index, 1);
      }
      parosInsumos = parosInsumos;
    } else {
      const index = parosPartes.findIndex((x) => x.id == pParo.id);
      if (index !== -1) {
        parosPartes.splice(index, 1);
      }
      parosPartes = parosPartes;
    }
    validarEliminarParos(parosInsumos, parosPartes);
  }

  async function getPartesxRecipiente(pRecipiente) {
    desactivar = true;
    const payload = pRecipiente;
    const response = await fetch("/maquila/partes-recipiente", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ payload: payload }),
    });
    respuesta = await response.json();
    if (response.ok) {
      partes = respuesta.data;

      partes.forEach((parte) => {
        motivoPartes[parte.id] = "";
        cantidadPartes[parte.id] = 0;
        observacionParte[parte.id] = "";
      });
      modalPartes = true;
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
    desactivar = false;
  }

  function guardarTemporalPartes() {
    let swModal = false;
    partes.forEach((parte) => {
      if (cantidadPartes[parte.id] != 0) {
        const index = parosPartes.findIndex((x) => x.id == parte.id);
        if (index !== -1) {
          parosPartes.splice(index, 1);
        }
        const vObj = {
          id: parte.id,
          codigo: parte.parte_codigo,
          descripcion: parte.parte_descripcion,
          cantidad: parte.cantidad,
          motivo: motivoPartes[parte.id],
          cantidad_faltante: cantidadPartes[parte.id],
          observacion_parte: observacionParte[parte.id]
        };
        if(cantidadPartes[parte.id] < 0){
          ValidacionCantInferior();
          swModal = true;
        } else if(cantidadPartes[parte.id] > parte.cantidad){
          modalValidacionCantidad();
          swModal = true;
        } else{
          parosPartes.push(vObj);
          swModal = false;
          validarTotalParos(parosPartes);
        }
      }
    });
    parosPartes = parosPartes;
    modalPartes = swModal;
  }

  async function guardarParos() {
    desactivarGrabar = true;
    desactivarConsultar = true;
    const insumos = parosInsumos;
    const partes = parosPartes;
    const response = await fetch("/paro/crear-paros", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ insumos: insumos, partes: partes }),
    });
    respuesta = await response.json();
    if (response.ok) {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;

      setTimeout(() => {
        location.reload();
      }, "1000");
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
    desactivar = false;
  }

  $: totalParos = parosInsumos.length + parosPartes.length;
</script>

<section>
  <div class="box-b my-15">
    <div class="box-l sx-iii self-start">
      <NauiInput label="RECIPIENTE" id="txtRecipiente" bind:value={filtro} on:input={validarInputBusqueda(filtro)}/>
      <NauiButton
        caption="BUSCAR"
        disabled={desactivarBuscar}
        mode="solid"
        color="accent"
        className="mt-ii"
        on:click={filtro === "" ? () => {} : getRecipientes}
      />
    </div>
    <div class="box-r sx-iii self-end">
      <NauiButton
        caption="CANCELAR"
        mode="outline"
        color="accent"
        on:click={() => {
          history.back();
        }}
      />
      <NauiButton
        caption="CONSULTAR ({totalParos})"
        disabled={desactivarConsultar}
        mode="solid"
        color="accent"
        on:click={desactivarConsultar === false ? abrirModalParos : ()=>{}}
      />
    </div>
  </div>
  <table class="naui-table w-100" style="margin-bottom:100px;">
    <thead>
      <tr>
        <th>#</th>
        <th>Recipiente</th>
        <th>Orden de Corte</th>
        <th
          >Referencia<br />
          Prod</th
        >
        <th>Talla Prod</th>
        <th>Color Prod</th>
        <th>Ref Venta</th>
        <th
          >Cantidad<br />
          Unidades</th
        >
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      {#if recipientes.length > 0}
        {#each recipientes as recipiente, i (recipiente.id)}
          <tr style="text-align: center;">
            <td><div class="display-div-table">{i + 1}</div></td>
            <td><div class="display-div-table">{recipiente.recipiente}</div></td>
            <td
              ><div class="display-div-table">
                {recipiente.orden_corte === ""
                  ? "Sin información"
                  : recipiente.orden_corte}
              </div></td
            >
            <td
              ><div class="display-div-table">
                {recipiente.codigo_referencia_produccion}
              </div></td
            >
            <td><div class="display-div-table">{recipiente.talla_produccion}</div></td>
            <td><div class="display-div-table">{recipiente.color_produccion}</div></td>
            <td><div class="display-div-table">{recipiente.codigo_referencia_venta}</div></td>
            <td><div class="display-div-table">{recipiente.cantidad}</div></td>
            <td>
              <div style="display: inline-flex;" >
                <NauiButton
                  caption="INSUMOS"
                  disabled={desactivar}
                  mode="solid"
                  color="accent"
                  className="mr-1"
                  on:click={abrirModalInsumos(recipiente)}
                />
                <NauiButton
                  caption="PARTES"
                  disabled={desactivar}
                  mode="solid"
                  color="secondary"
                  on:click={abrirModalPartes(recipiente)}
                />
              </div>
            </td>
          </tr>
        {/each}
      {:else}
        <tr>
          <td colspan="9">
            <p>No hay registros</p>
          </td>
        </tr>
      {/if}
    </tbody>
  </table>
  <NauiFloatingAction>
    <div class="box-r sx-1">
      <div>
        <NauiButton
          fa="far fa-save"
          disabled={desactivarGrabar}
          caption="GRABAR Y ENVIAR CORREO"
          color="secondary"
          mode="solid"
          on:click={desactivarGrabar === false ? guardarParos() : ()=>{}}
        />
      </div>
    </div>
  </NauiFloatingAction>

  <Dialog
    bind:open={modalInsumos}
    fullscreen
    aria-labelledby="fullscreen-title"
    aria-describedby="fullscreen-content"
  >
    <Header>
      <div class="box sx-ii py-ii">
        <h3>Insumos</h3>
      </div>
      <span
        style="cursor: pointer;"
        on:click={() => {
          modalInsumos = false;
        }}
        class="box sx-ii py-ii material-symbols-outlined">close</span
      >
    </Header>
    <Content>
      <div class="m-i">
        <table class="naui-table w-100">
          <thead>
            <tr>
              <th>Recipiente</th>
              <th>Orden de Corte</th>
              <th>Referencia<br/>Prod</th>
              <th>Talla Prod</th>
              <th>Color Prod</th>
              <th>Ref Venta</th>
              <th
                >Cantidad<br />
                Unidades</th
              >
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{recipiente.recipiente}</td>
              <td>{recipiente.orden_corte === "" ? "Sin información" : recipiente.orden_corte}</td>
              <td>{recipiente.codigo_referencia_produccion}</td>
              <td>{recipiente.talla_produccion}</td>
              <td>{recipiente.color_produccion}</td>
              <td>{recipiente.codigo_referencia_venta}</td>
              <td>{recipiente.cantidad}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="m-2">
        <h4>Insumos de Confección</h4>
        <table class="naui-table w-100">
          <thead>
            <tr>
              <th>Código Insumo</th>
              <th>Descripción</th>
              <th>Consumo prenda</th>
              <th>Unidad de Medida</th>
              <th>Motivo</th>
              <th>Cantidad faltante</th>
              <th>Observación</th>
            </tr>
          </thead>
          <tbody>
            {#if insumos.length > 0}
              {#each insumos as insumo}
                {#if insumo.insumo_tipo == "COSTURA"}
                  <tr style="text-align: center;">
                    <td
                      ><div>
                        {insumo.insumo_codigo}
                      </div></td
                    >
                    <td
                      ><div>
                        {insumo.insumo_descripcion}
                      </div></td
                    >
                    <td><div>{Number(insumo.cantidad).toFixed(2)}</div></td>
                    <td
                      ><div>
                        {insumo.insumo_uni_medida}
                      </div></td
                    >
                    <td>
                      <div>
                        <input
                          type="text"
                          style="max-width: 150px; text-align: center"
                          class="naui-input"
                          bind:value={motivoInsumos[insumo.id]}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <input
                          type="number"
                          min="0"
                          style="max-width: 50px; text-align: center"
                          class="naui-input"
                          bind:value={cantidadInsumos[insumo.id]}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <input
                          type="text"
                          style="max-width: 150px; text-align: center"
                          class="naui-input"
                          bind:value={observacionInsumo[insumo.id]}
                        />
                      </div>
                    </td>
                  </tr>
                {/if}
              {/each}
            {:else}
              <tr>
                <td colspan="6">
                  <p>No hay registros</p>
                </td>
              </tr>
            {/if}
          </tbody>
        </table>
      </div>
      <div class="m-2">
        <h4>Insumos de Empaque</h4>
        <table class="naui-table w-100">
          <thead>
            <tr>
              <th>Código Insumo</th>
              <th>Descripción</th>
              <th>Consumo prenda</th>
              <th>Unidad de Medida</th>
              <th>Motivo</th>
              <th>Cantidad faltante</th>
              <th>Observación</th>
            </tr>
          </thead>
          <tbody>
            {#if insumos.length > 0}
              {#each insumos as insumo}
                {#if insumo.insumo_tipo == "EMPAQUE"}
                  <tr style="text-align: center;">
                    <td
                      ><div>
                        {insumo.insumo_codigo}
                      </div></td
                    >
                    <td
                      ><div>
                        {insumo.insumo_descripcion}
                      </div></td
                    >
                    <td><div>{Number(insumo.cantidad).toFixed(2)}</div></td>
                    <td
                      ><div>
                        {insumo.insumo_uni_medida}
                      </div></td
                    >
                    <td>
                      <div>
                        <input
                          type="text"
                          style="max-width: 150px; text-align: center"
                          class="naui-input"
                          bind:value={motivoInsumos[insumo.id]}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <input
                          type="number"
                          min="0"
                          style="max-width: 50px; text-align: center"
                          class="naui-input"
                          bind:value={cantidadInsumos[insumo.id]}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <input
                          type="text"
                          style="max-width: 150px; text-align: center"
                          class="naui-input"
                          bind:value={observacionParte[insumo.id]}
                        />
                      </div>
                    </td>
                  </tr>
                {/if}
              {/each}
            {:else}
              <tr>
                <td colspan="6">
                  <p>No hay registros</p>
                </td>
              </tr>
            {/if}
          </tbody>
        </table>
      </div>
    </Content>

    <Actions>
      <div class="box-r sx-ii px-1 py-ii">
        <NauiButton
          caption="Cerrar"
          mode="outline"
          color="accent"
          on:click={() => {
            modalInsumos = false;
          }}
        />
        <NauiButton
          caption="Guardar"
          mode="solid"
          color="accent"
          on:click={guardarTemporalInsumos}
        />
      </div>
    </Actions>
  </Dialog>

  <Dialog
    bind:open={modalPartes}
    fullscreen
    aria-labelledby="fullscreen-title"
    aria-describedby="fullscreen-content"
  >
    <Header>
      <div class="box sx-ii py-ii">
        <h3>Partes</h3>
      </div>
      <span
        style="cursor: pointer;"
        on:click={() => {
          modalPartes = false;
        }}
        class="box sx-ii py-ii material-symbols-outlined">close</span
      >
    </Header>
    <Content>
      <div class="m-i">
        <table class="naui-table w-100">
          <thead>
            <tr>
              <th>Recipiente</th>
              <th>Orden de Corte</th>
              <th
                >Referencia<br />
                Prod</th
              >
              <th>Talla Prod</th>
              <th>Color Prod</th>
              <th>Ref Venta</th>
              <th
                >Cantidad<br />
                Unidades</th
              >
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{recipiente.recipiente}</td>
              <td>{recipiente.orden_corte === "" ? "Sin información" : recipiente.orden_corte}</td>
              <td>{recipiente.codigo_referencia_produccion}</td>
              <td>{recipiente.talla_produccion}</td>
              <td>{recipiente.color_produccion}</td>
              <td>{recipiente.codigo_referencia_venta}</td>
              <td>{recipiente.cantidad}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="m-2">
        <h4>Partes de Corte</h4>
        <table class="naui-table w-100">
          <thead>
            <tr>
              <th>Código Parte</th>
              <th>Descripción</th>
              <th>Consumo prenda</th>
              <th>Motivo</th>
              <th>Cantidad faltante</th>
              <th>Observación</th>
            </tr>
          </thead>
          <tbody>
            {#if partes.length > 0}
              {#each partes as parte}
                <tr style="text-align: center;">
                  <td
                    ><div>
                      {parte.parte_codigo}
                    </div></td
                  >
                  <td
                    ><div>
                      {parte.parte_descripcion}
                    </div></td
                  >
                  <td><div>{Number(parte.cantidad).toFixed(2)}</div></td>
                  <td>
                    <div>
                      <input
                        type="text"
                        style="max-width: 150px; text-align: center"
                        class="naui-input"
                        bind:value={motivoPartes[parte.id]}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <input
                        type="number"
                        min="0"
                        style="max-width: 50px; text-align: center"
                        class="naui-input"
                        bind:value={cantidadPartes[parte.id]}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        style="max-width: 150px; text-align: center"
                        class="naui-input"
                        bind:value={observacionParte[parte.id]}
                      />
                    </div>
                  </td>
                  <td>
                </tr>
              {/each}
            {:else}
              <tr>
                <td colspan="6">
                  <p>No hay registros</p>
                </td>
              </tr>

            {/if}
          </tbody>
        </table>
      </div>
    </Content>

    <Actions>
      <div class="box-r sx-ii px-1 py-ii">
        <NauiButton
          caption="Cerrar"
          mode="outline"
          color="accent"
          on:click={() => {
            modalPartes = false;
          }}
        />
        <NauiButton
          caption="Guardar"
          mode="solid"
          color="accent"
          on:click={guardarTemporalPartes}
        />
      </div>
    </Actions>
  </Dialog>

  <Dialog
    bind:open={modalParos}
    fullscreen
    aria-labelledby="fullscreen-title"
    aria-describedby="fullscreen-content"
  >
    <Header>
      <div class="box sx-ii py-ii">
        <h3>Consultar/Eliminar Paros</h3>
      </div>
      <span
        style="cursor: pointer;"
        on:click={() => {
          modalParos = false;
        }}
        class="box sx-ii py-ii material-symbols-outlined">close</span
      >
    </Header>
    <Content>
      <div class="m-2">
        <h4>Insumos</h4>
        <table class="naui-table w-100">
          <thead>
            <tr>
              <th>Código Insumo</th>
              <th>Descripción</th>
              <th>Consumo prenda</th>
              <th>Unidad de Medida</th>
              <th>Motivo</th>
              <th>Cantidad faltante</th>
              <th>Observación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {#if parosInsumos.length > 0}
              {#each parosInsumos as insumo}
                <tr style="text-align: center;">
                  <td
                    ><div>
                      {insumo.codigo}
                    </div></td
                  >
                  <td
                    ><div>
                      {insumo.descripcion}
                    </div></td
                  >
                  <td><div>{Number(insumo.cantidad).toFixed(2)}</div></td>
                  <td
                    ><div>
                      {insumo.uni_medida}
                    </div></td
                  >
                  <td>
                    <div>
                      {insumo.motivo}
                    </div>
                  </td>
                  <td>
                    <div>
                      {insumo.cantidad_faltante}
                    </div>
                  </td>
                  <td>
                    <div>
                      {insumo.observacion_insumo}
                    </div>
                  </td>
                  <td>
                    <div>
                      <NauiButton
                        caption="ELIMINAR"
                        disabled={desactivar}
                        mode="outline"
                        color="accent"
                        
                        on:click={eliminarParo(insumo, "I")}
                      />
                    </div>
                  </td>
                </tr>
              {/each}
            {:else}
              <tr>
                <td colspan="7">
                  <p>No hay registros</p>
                </td>
              </tr>
            {/if}
          </tbody>
        </table>
      </div>
      <div class="m-2">
        <h4>Partes</h4>
        <table class="naui-table w-100">
          <thead>
            <tr>
              <th>Código Parte</th>
              <th>Descripción</th>
              <th>Consumo prenda</th>
              <th>Motivo</th>
              <th>Cantidad faltante</th>
              <th>Observación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {#if parosPartes.length > 0}
              {#each parosPartes as parte}
                <tr style="text-align: center;">
                  <td
                    ><div>
                      {parte.codigo}
                    </div></td
                  >
                  <td
                    ><div>
                      {parte.descripcion}
                    </div></td
                  >
                  <td><div>{Number(parte.cantidad).toFixed(2)}</div></td>
                  <td>
                    <div>
                      {parte.motivo}
                    </div>
                  </td>
                  <td>
                    <div>
                      {parte.cantidad_faltante}
                    </div>
                  </td>
                  <td>
                    <div>
                      {parte.observacion_parte}
                    </div>
                  </td>
                  <td>
                    <div>
                      <NauiButton
                        caption="ELIMINAR"
                        disabled={desactivar}
                        mode="outline"
                        color="accent"
                        on:click={eliminarParo(parte, "P")}
                      />
                    </div>
                  </td>
                </tr>
              {/each}
            {:else}
              <tr>
                <td colspan="6">
                  <p>No hay registros</p>
                </td>
              </tr>
            {/if}
          </tbody>
        </table>
      </div>
    </Content>

    <Actions />
  </Dialog>

  <Dialog bind:open={dialogOpenOK}>
    <Title>
      <div class="box sx-ii py-ii">
        <span class="material-icons accent">warning</span>
        <h3>Paros</h3>
      </div>
    </Title>
    <Content>
      <div class="max-w-400px text-c mx-auto px-15">
        <p class="large">
          {mensaje}
        </p>
      </div>
    </Content>
    <Actions>
      <div class="box-r sx-ii px-1 py-ii">
        <NauiButton
          caption="Aceptar"
          color="accent"
          on:click={() => {
            dialogOpenOK = false;
          }}
        />
      </div>
    </Actions>
  </Dialog>
</section>

<style>
  .display-div-table{
    display: contents;
  }
</style>
