<script>
    import Dialog, {Actions, Content, Title} from "@smui/dialog";
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";

    export let open = false;
</script>

<Dialog bind:open>
    <Title>
        <h3 class="text-c">Anular ASN</h3>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
                Al anular el ASN se borrarán todos los criterios de búsqueda y los EAN que se han escaneado dentro del
                borrador actual. Esta acción no se podrá deshacer.
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton caption="CANCELAR" color="accent" on:click={()=> open=false}/>
            <NauiButton caption="ANULAR ASN" color="accent" on:click/>
        </div>
    </Actions>
</Dialog>
