<script lang="ts">
    export let recipiente: string;
    export let inventario: number;
    export let empacadas: number;
    // export let surtido: number = 1;

    // $: empaqueBolsa = empacadas * surtido
    $: restantes = inventario - empacadas;
</script>

<tr style="background: #E4E4E4">
    <td colspan="14">
        <span class="box-l sx-2 py-i">
            <small>RECIPIENTE <strong>{recipiente}</strong></small>
            <small>UNID. INVENTARIO <strong>{inventario}</strong></small>
            <small>EMPACADAS <strong>{empacadas}</strong></small>
            <small>RESTANTES <strong>{restantes}</strong></small>
        </span>
    </td>
</tr>
<style>
    small {
        font-size: .8rem;
    }

    strong{
        padding:.05rem .5rem;
        border-radius:2rem;
    }
</style>
