<script>
    import {createEventDispatcher, onMount} from "svelte";
    import NauiButton from "../atoms/NauiButton.svelte";
    import NauiDateRange from "../atoms/NauiDateRange.svelte";

    export let filters;
    let _filters = [];
    let selection = [];

    const events = new createEventDispatcher()

    onMount(() => {
        _filters = filters.map((x) => {
            return {
                label: x.label,
                slug: x.slug,
                type: x.type,
                options: x.options,
                value: x.selected || null
            }
        });
    });

    export const runFilters = function () {
        selection = _filters.map((x) => {
            if (x.value !== null && x.value !== undefined)
                return {
                    slug: x.slug,
                    value: x.value
                }
            else {
                return null;
            }
        });
        selection = selection.filter((x) => x);
        const filtersJson = {};
        selection.forEach(s => {
            filtersJson[s.slug] = s.value
        });
        events('filtered', filtersJson);
    }

    export function clear() {
        _filters = _filters.map((x) => {
            x.value = null;
            return x;
        });
        selection = [];
        events('filtered', {});
    }
</script>
<div class="box-l sx-ii">
    {#each _filters as filter (filter.slug)}
        {#if filter?.type === 'date-range'}
            <NauiDateRange label="{filter.label}"
                           slug="{filter.slug}"
                           on:change={runFilters}
                           bind:value={filter.value}/>
        {:else }
            {#if filter.options && filter.options.length > 0}
                <select bind:value={filter.value} class="naui-filter max-w-300px" on:change={runFilters}
                        class:selected={filter.value}>
                    <option selected hidden value={null}>{filter.label}</option>
                    {#each filter.options as option}
                        {#if option.options}
                            <optgroup label={option.name}>
                                {#each option.options as sub}
                                    <option value={sub.value}>{sub.label}</option>
                                {/each}
                            </optgroup>
                        {:else}
                            <option value={option.value}>{option.label}</option>
                        {/if}
                    {/each}
                </select>
            {/if}
        {/if}
    {/each}
    <NauiButton icon="refresh" size="small" color="secondary" on:click={clear}/>
</div>

<style>
    .naui-filter.selected {
        color: var(--secondary);
        opacity: 1;
    }
</style>
