import Tooltip from './ToolTip.svelte';
/*
  Función que recibe el HTMLElement al que se le quiere añadir un tooltip.
  - Internamente se crean dos arrowFunctions para los eventos mouseover, mouseleave y mousemove.
  Mouseover:
    - Se guarda el valor del atributo title del elemento.
    - Se elimina el atributo title del elemento.
    - Se crea una instancia de Tooltip y se añade al body.
  Mousemove:
    - Se actualiza la posición del tooltip.
  Mouseleave:
    - Se elimina la instancia de Tooltip.
    - Se añade el atributo title al elemento.
  Se agregan los eventos al elemento y se retorna un objeto con un método destroy que elimina los eventos.
*/
export function tooltip(element) {
    let title;
    let tooltipComponent;
    const mouseOver = (event) => {
        title = element.getAttribute('title');
        element.removeAttribute('title');
        tooltipComponent = new Tooltip({
            props: {
                title: title,
                x: event.pageX,
                y: event.pageY,
            },
            target: document.body,
        });
    };
    const mouseMove = (event) => {
        tooltipComponent.$set({
            x: event.pageX,
            y: event.pageY,
        });
    };
    const mouseLeave = () => {
        tooltipComponent.$destroy();
        element.setAttribute('title', title);
    };
    element.addEventListener('mouseover', mouseOver);
    element.addEventListener('mouseleave', mouseLeave);
    element.addEventListener('mousemove', mouseMove);
    return {
        destroy() {
            element.removeEventListener('mouseover', mouseOver);
            element.removeEventListener('mouseleave', mouseLeave);
            element.removeEventListener('mousemove', mouseMove);
        }
    };
}
