<script>
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import {createEventDispatcher} from "svelte";
    import NauiTag from "../../lib/naui/atoms/NauiTag.svelte";
    import {getColor} from "../../lib/utils";

    export let data = {};
    export let cantidad = 1;
    export let unidades = 1;
    export let surtido = 1;
    export let inventario = 1;

    let maxima = 1;

    const tipoLabels = {
        prepack: 'Prepack',
        conjunto: 'Conjunto',
        individual: 'Individual',
        multiple: 'Múltiple'
    }

    export let checked = false;

    export let empacadas = 0;

    const dispatch = createEventDispatcher();

    function closeBox() {
        dispatch('close', data.caja);
    }

    function openBox() {
        dispatch('open', data.caja)
    }

    function selected(e) {
        dispatch('select', { item: data, checked: e.target.checked });
    }

    function focus(){
        dispatch('click')
    }

</script>

<tr >
    <td>
        <div>
            <NauiCheckbox bind:value={checked} on:input={selected} />
        </div>
    </td>
    <td>
        <div>{data.codigo_referencia_produccion}</div>
    </td>
    <td>
        <div>{data.descripcion_referencia_produccion}</div>
    </td>
    <td>
        <div>{data.talla_produccion}</div>
    </td>
    <td>
        <div>{data.color_produccion}</div>
    </td>
    <td>
        <div>{data.ean_128 ? data.ean_128 : data.ean_prepack}</div>
    </td>
    <td>
        <div>
            <NauiTag borderColor="transparent" label={data.caja} backColor="{getColor(data.caja)}"/>
        </div>
    </td>
    <td>
        <div>
            <NauiTag borderColor="transparent" label={data.estiba} backColor="{getColor(data.caja)}"/>
        </div>
    </td>
    <td>
        <div>
            <input type="number" min="1" style="max-width: 60px; text-align: center" class="naui-input"
                   bind:value={cantidad} disabled/>
        </div>
    </td>
    <td>
        <div>
            <input type="number" min="1" max={data.inventario} style="max-width: 60px; text-align: center" class="naui-input"
                   bind:value={unidades} disabled/>
        </div>
    </td>
    <td>
        <div class="box">
            <select bind:value={data.calidad} class="naui-input text-c" style="min-width: 50px; padding: 0">
                <option value="1">1</option>
                <option value="2">2</option>
            </select>
        </div>
    </td>
    <td>
        <div class="box">{tipoLabels[data.tipo_prenda]}</div>
    </td>
    <td>
        <div class="box">
            {#if data.caja_open}
                <NauiButton caption="CERRAR CAJA" color="accent" size="small" on:click={closeBox}/>
            {:else}
                <NauiButton caption="ABRIR CAJA" color="light-gray" size="small" on:click={openBox}/>
            {/if}
        </div>
    </td>
    <td on:click={focus}>
        <div class="box">
            {#if data.changes}
                <small class="text-c">Se requiere<br>guardar...</small>
            {:else}
                <NauiButton href="/maquila/api/sticker/{data.caja}" fa="far fa-sticky-note" color="accent"/>
            {/if}
        </div>
    </td>
</tr>
