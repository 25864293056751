<script lang="ts">
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import MultitablaItem from "./components//multitablaItem.svelte";
    import MultitablaHead from "./components/multitablaHead.svelte";
    import {onMount} from 'svelte';
    import NauiSlideOver from "../lib/naui/layout/NauiSlideOver.svelte";
    import NauiSelect from "../lib/naui/atoms/NauiSelect.svelte";

    export let data = [];
    let globalCheck = false;
    let cant = data.length; // data[1];
    //let activeFilter = 'enviado';
    let searchKey: string = null;
    let open = false;
    let estadoOptions = [
        {
            label: "Activo",
            value: 'ACTIVO'
        },
        {
            label: "Inactivo",
            value: 'INACTIVO'
        }
    ]
    
    let localData = [];

    onMount(() => {
        loadData();
        searchKey = null;
    });

    function loadData() {
        localData = data.map((x) => {
            return {...x, checked: false}
        });
    }

    function search(key) {
        if (!!key) {
            loadData();
            localData = localData.filter((x) => (x.name.includes(key)))
        } else {
            loadData();
        }
    }

    function clear() {
        globalCheck = false;
        localData.forEach((x) => {
            x.checked = false;
        })
    }

    function globalCheckChange(check) {
        localData.forEach((x) => {
            if (!!filteredData) {
                if (filteredData.includes(x)) {
                    x.checked = check;
                }
            }
        });
        localData = localData;
    }

    $: search(searchKey);
    $: globalCheckChange(globalCheck);
    $: filteredData = localData; //localData.filter( (x) => x.estado === x.estado  );  //(x) => x.estado === activeFilter
    $: checkedItems = filteredData.filter((x) => x.checked).map((y) => {
        return {
            id: y.id//,
            //constante: y.recipiente
        }
    })

    /*
    async function update() {
        if (nuevoEstado !== '') {
            const payload = checkedItems.map((x) => {
                return {...x, estado: nuevoEstado}
            })
            const response = await fetch('/maquila/actualizar-recipiente', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)
            });
            if (response.ok){
                open = false;
                location.reload();
            }

        } else {
            alert('Debe seleccionar un nuevo estado')
        }
    }*/


</script>
<section>
    <!--{searchKey}
    {cant}-->
    <div class="box-l sx-1 mt-1 mb-15">
        <div class="relative">
            <input bind:value={searchKey} type="search" class="naui-input search min-w-400px"
                   placeholder="Buscar nombre">
            <span class="absolute material-icons-outlined" style="color: #888;">search</span>
        </div>
        <NauiButton caption="BUSCAR" mode="outline" color="accent"/>
    </div>
  
    <table class="naui-table w-100">
        <MultitablaHead bind:checked={globalCheck}/>
        <tbody>
        {#each filteredData as item (item.id)}
            <MultitablaItem data="{item}" bind:checked={item.checked}/>
        {:else}
            <tr>
                <td colspan="6">
                    <div><p>No se encontraron registros</p></div>
                </td>
            </tr>
        {/each}
        </tbody>
    </table>
</section>
<style>
    .search {
        border-radius: 2rem;
        padding-left: 2.5rem;
    }

    .search + span {
        position: absolute;
        left: .75rem;
        top: .75rem;
        color: var(--secondary);
    }
/*
    .naui-filter {
        min-width: 150px;
        color: var(--secondary);
        opacity: 1;
    }*/
</style>