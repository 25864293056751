import "svelte/internal";
import "../lib/utils";
export class ScanMaquilaController {
    // constructor(cart: Array<any>, packed?: Array<any>) {
    constructor(packed) {
        this.cart = [];
        this.productionEquivalencies = {};
        this.salesEquivalencies = {};
        this.eanPrepackEquivalences = {};
        this.checkedItems = {};
        this.warningBox = null;
        this.warningPallet = null;
        this.alerts = {
            boxEan: false
        };
        this.packed = packed !== null && packed !== void 0 ? packed : [];
        this.filterPacked(this.packed);
    }
    filterPacked(items) {
        if (this.cart && this.cart.length > 0)
            this.packed = items.filter(x => this.cart.map(c => c.id).includes(x.id));
    }
    updatePacked(packed, equivalencies) {
        this.filterPacked(packed);
        this.createEquivalences(equivalencies);
    }
    createEquivalences(infoEquivalencies) {
        for (let e of infoEquivalencies) {
            const salesKey = this.getSalesKeyOfEquivalence(e);
            const productionKey = this.getProductionKeyOfEquivalence(e);
            this.productionEquivalencies[`${salesKey}-${productionKey}`] = e;
            if (!this.salesEquivalencies[salesKey])
                this.salesEquivalencies[salesKey] = infoEquivalencies.filter(x => this.getSalesKeyOfEquivalence(x) === salesKey);
        }
        for (let e of infoEquivalencies) {
            const k = this.getEanPrepackKeyOfEquivalence(e);
            if (e.ean_prepack && e.temporada && !this.eanPrepackEquivalences[k])
                this.eanPrepackEquivalences[k] = infoEquivalencies.filter(x => x.ean_prepack === e.ean_prepack && x.temporada === e.temporada);
        }
    }
    getSalesKeyOfEquivalence(e) {
        return ScanHelper.getScanAndPackKeyValue(e.codigo_referencia_venta, e.talla_venta, e.color_venta, e.temporada);
    }
    getProductionKeyOfEquivalence(e) {
        return ScanHelper.getScanAndPackKeyValue(e.codigo_referencia, e.talla_produccion, e.color_produccion, e.temporada);
    }
    getProductionEquivalence(item) {
        return this.productionEquivalencies[ScanHelper.getPackedItemKey(item)];
    }
    getSalesEquivalences(item) {
        return this.salesEquivalencies[ScanHelper.getSalesKey(item)];
    }
    getEanPrepackKeyOfEquivalence(e) {
        return `${e.ean_prepack}-${e.temporada}`;
    }
    getEanPrepackEquivalences(item) {
        return this.eanPrepackEquivalences[`${item.ean_prepack}-${item.temporada}`];
    }
    getSurtido(item) {
        let surtido = 1;
        const eq = this.getProductionEquivalence(item);
        if (eq)
            surtido = eq.car_surtido;
        return surtido;
    }
    getTotalBoxes() {
        if (this.packed.length > 0) {
            const boxes = new Set(this.packed.map(x => x.caja));
            return boxes.size;
        }
        return 0;
    }
    selectReferences(ean) {
        const selectedReferences = {};
        let selectedEquivalences;
        let item = this.cart.find(x => x.ean_128 === ean);
        if (item)
            selectedEquivalences = this.getSalesEquivalences(item);
        else {
            item = this.cart.find(x => x.ean_prepack === ean);
            if (item)
                selectedEquivalences = this.getEanPrepackEquivalences(item);
        }
        if (selectedEquivalences) {
            for (let e of Object.values(selectedEquivalences)) {
                const pk = this.getProductionKeyOfEquivalence(e);
                const x = selectedReferences[pk];
                if (!x && x !== null)
                    selectedReferences[pk] = null;
            }
        }
        return Object.keys(selectedReferences);
    }
    validationEanAndTypeGarment(ean, tipo) {
        const result = new OperationResult(true, null);
        if (this.cart.find(x => x.ean_128 === ean) && tipo === ScanMaquilaController.TIPO_LECTURA_PREPACK) {
            result.message = `El EAN 128 no puede ser del tipo: ${tipo}`;
            result.success = false;
        }
        else if (this.cart.find(x => x.ean_prepack === ean) && tipo !== ScanMaquilaController.TIPO_LECTURA_PREPACK) {
            result.message = `El EAN prepack no puede ser del tipo: ${tipo}`;
            result.success = false;
        }
        return result;
    }
    async addItem(ean, tipo, currentBox = null) {
        const result = new OperationResult(true, null);
        if (tipo) {
            const selected = this.selectReferences(ean);
            if (selected && selected.length > 0) {
                if (this.testEan(ean)) {
                    const infoItems = [];
                    for (let kp of selected) {
                        const info = this.getNextPackedItem(kp);
                        infoItems.push(info);
                    }
                    if (infoItems.length > 0) {
                        if (infoItems.every(x => x.item !== null)) {
                            const createBox = currentBox === null && infoItems.some(x => x.mustOpenBox);
                            for (let item of infoItems.filter(x => !x.isNew).map(x => x.item)) {
                                this.updateEmpacados(item, item.empacadas + 1);
                                this.updateChanges(item.caja);
                            }
                            const addResult = await this.insertPackedItems(infoItems.filter(x => x.isNew).map(x => x.item), tipo, createBox, currentBox, ean);
                            result.combine(addResult);
                        }
                        else {
                            result.success = false;
                            result.message = "No hay inventario disponible para el EAN seleccionado";
                        }
                    }
                    else {
                        result.success = false;
                        result.message = "No hay elementos disponibles.";
                    }
                }
                else {
                    result.message = "Solo se permite incluir un EAN por caja";
                }
            }
            else {
                result.message = "El EAN especificado no está incluido en los recipientes seleccionados.";
            }
        }
        else {
            result.message = "No ha especificado el tipo de prenda que va a empacar";
        }
        return result.message;
    }
    mustbeOpenBox() {
        return !this.packed || this.packed.length === 0 || !this.packed.some(x => x.caja_open);
    }
    getNextPackedItem(kp) {
        const infoItem = new InfoItemsDisponibles(null, false, false);
        let item = null;
        if (!this.mustbeOpenBox())
            item = this.checkPackagedAvailability(kp);
        if (!item) {
            item = this.checkCartAvailability(kp);
            if (item) {
                infoItem.isNew = true;
                infoItem.mustOpenBox = this.mustbeOpenBox();
                infoItem.item = item;
            }
        }
        else
            infoItem.item = item;
        return infoItem;
    }
    getTotalPacked(packedItem) {
        let totalPacked = 0;
        if (this.packed && this.packed.length > 0) {
            const pk = ScanHelper.getProductionKey(packedItem);
            const packedByRecipient = this.packed
                .filter(x => x.recipiente === packedItem.recipiente &&
                ScanHelper.getProductionKey(x) === pk).map(x => x.empacadas * this.getSurtido(x));
            if (packedByRecipient && packedByRecipient.length > 0) {
                totalPacked = packedByRecipient.reduce((t, x) => t + x);
            }
        }
        return totalPacked;
    }
    async insertPackedItems(cartItems, tipo, createBox, box = null, ean) {
        const result = new OperationResult(true, null);
        const cartRecipientsId = cartItems.map(x => x.id);
        let items = this.cart.filter((x) => cartRecipientsId.includes(x.id));
        const boxResult = await this.getBoxIndex(createBox, box);
        if (boxResult.boxNumber) {
            for (let item of items) {
                item.empacadas = 1;
                item.unidades = this.getSurtido(item);
                item = Object.assign(Object.assign({}, item), { caja: boxResult.boxNumber, estiba: boxResult.palletNumber, tipo_prenda: tipo, caja_open: true, consecutivo: `${item.id}-${boxResult.boxNumber}`, ean: ean !== null && ean !== void 0 ? ean : null });
                this.checkedItems[item.consecutivo] = false;
                this.packed = [...this.packed, item];
                this.updateChanges(boxResult.boxNumber);
            }
        }
        else
            result.success = false;
        result.message = boxResult.message;
        return result;
    }
    testEan(ean) {
        return this.packed.length === 0 || this.packed.every(x => !x.caja_open || (x.ean_128 === ean || x.ean_prepack === ean));
    }
    checkPackagedAvailability(productionKey) {
        const totalsPackedByRecipient = this.packed
            .filter(x => ScanHelper.getProductionKey(x) === productionKey)
            .map(x => { return { recipiente: x.recipiente, productionKey: ScanHelper.getProductionKey(x), totalPacked: this.getTotalPacked(x) }; });
        const item = this.getOrderedItemsForSelection(this.packed).find((x) => {
            const thisProductionKey = ScanHelper.getProductionKey(x);
            if (x.caja_open && thisProductionKey === productionKey) {
                const totalRecipient = totalsPackedByRecipient.find(t => t.recipiente === x.recipiente && t.productionKey === thisProductionKey);
                const recipiente = this.cart.find(r => r.recipiente === x.recipiente && ScanHelper.getProductionKey(r) === thisProductionKey);
                return totalRecipient && (totalRecipient.totalPacked + this.getSurtido(x)) <= recipiente.inventario;
            }
            return false;
        });
        return item || null;
    }
    checkCartAvailability(productionKey) {
        const orderedCart = this.getOrderedItemsForSelection(this.cart);
        return orderedCart.find(x => {
            if (ScanHelper.getProductionKey(x) === productionKey) {
                const total = this.getTotalPacked(x);
                return x.inventario >= (total + this.getSurtido(x));
            }
        });
    }
    getOrderedItemsForSelection(items) {
        return items.sort((a, b) => a.recipiente < b.recipiente ? -1 : a.recipiente > b.recipiente ? 1 : 0);
    }
    getEmpacadas(recipiente) {
        let count = 0;
        this.packed.forEach((x) => {
            if (x.recipiente === recipiente) {
                count += x.empacadas;
            }
        });
        return count;
    }
    getInventarioDisponible(recipiente) {
        let total = 0;
        for (let c of this.cart) {
            if (c.recipiente === recipiente)
                total += c.inventario;
        }
        return total;
    }
    saveAllChanges() {
        this.packed.forEach((x) => {
            x.changes = false;
        });
    }
    getMaxPalletNumber() {
        let palletNumber = 0;
        if (this.packed && this.packed.length > 0) {
            palletNumber = this.packed.map(x => { var _a; return (_a = x.estiba) !== null && _a !== void 0 ? _a : 0; }).reduce((mx, x) => x > mx ? x : mx);
        }
        return palletNumber;
    }
    async getBoxIndex(createBox, box = null) {
        const result = { boxNumber: null, palletNumber: null, message: null };
        if (createBox) {
            const totalCajas = this.getTotalBoxes();
            const incrementPallet = totalCajas === 0 || (totalCajas % ScanMaquilaController.CANTIDAD_CAJAS_ESTIBA) === 0;
            try {
                const response = await fetch('/maquila/reservar-caja', {
                    method: 'post',
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({ caja: true, estiba: incrementPallet }),
                });
                if (response.ok) {
                    const data = await response.json();
                    if (data.exhausted)
                        result.message = "No hay consecutivos disponibles para nuevas cajas";
                    else {
                        result.boxNumber = data.current;
                        result.palletNumber = incrementPallet ? data.current_pallet : this.getMaxPalletNumber();
                        this.warningBox = data.must_show_alert ? `Los consecutivos de caja están próximos a agotarse, dispone de ${data.available}` : null;
                        this.warningPallet = data.must_show_alert ? `Los consecutivos de estiba están próximos a agotarse, dispone de ${data.available_pallet}` : null;
                    }
                }
                else
                    result.message = "No se pudo obtener el consecutivo de caja o de estiba";
            }
            catch (error) {
                result.message = "Ocurrió un error. Intente nuevamente";
            }
        }
        else {
            if (box === null) {
                const currentBox = this.packed.find(x => x.caja_open);
                if (currentBox) {
                    result.boxNumber = currentBox.caja;
                    result.palletNumber = currentBox.estiba;
                }
                else
                    result.message = "No hay cajas abiertas";
            }
            else {
                result.boxNumber = box.boxNumber;
                result.palletNumber = box.palletNumber;
            }
        }
        return result;
    }
    openBox(box) {
        let tipo = 'prepack';
        this.packed.forEach((x) => {
            if (x.caja === box) {
                x.caja_open = true;
                tipo = x.tipo_prenda;
            }
        });
        return tipo;
    }
    closeBox(box) {
        this.packed.forEach((x) => {
            if (x.caja === box) {
                x.caja_open = false;
            }
        });
    }
    getOpenedBox() {
        let openedBox = null;
        if (this.packed && this.packed.length > 0) {
            const item = this.packed.find(x => x.caja_open);
            if (item)
                openedBox = {
                    boxNumber: item.caja,
                    palletNumber: item.estiba,
                    open: item.caja_open
                };
        }
        return openedBox;
    }
    removeItem(consecutivo) {
        this.packed = this.packed.filter((x) => x.consecutivo !== consecutivo);
    }
    getRecipienteItems(recipiente) {
        const items = this.packed.filter((item) => item.recipiente === recipiente).
            sort((x, y) => {
            let r = 0;
            if (x.caja_open && !y.caja_open)
                r = 1;
            else if (!x.caja_open && y.caja_open)
                r = -1;
            if (r === 0) {
                r = (x.caja || 0) - (y.caja || 0);
                if (r === 0) {
                    r = ScanHelper.compareString(x.codigo_referencia_produccion, y.codigo_referencia_produccion);
                    if (r === 0) {
                        r = ScanHelper.compareString(x.talla_produccion, y.talla_produccion);
                        if (r === 0)
                            r = ScanHelper.compareString(x.color_produccion, y.color_produccion);
                    }
                }
            }
            return r;
        });
        return items;
    }
    updateEmpacados(item, newQ) {
        const itemsByBox = this.packed.filter(x => ScanHelper.getProductionKey(item) === ScanHelper.getProductionKey(x) &&
            x.recipiente === item.recipiente &&
            x.caja === item.caja);
        for (let x of itemsByBox) {
            this.updateChanges(x.caja);
            x.empacadas = newQ;
            x.unidades = newQ * this.getSurtido(item);
        }
    }
    updateChanges(box) {
        this.packed.forEach((x) => {
            if (x.caja === box) {
                x.changes = true;
            }
        });
    }
    getEanesList() {
        const a = (this.cart || []);
        const eanes128 = a.filter(x => x.ean_128).map(x => x.ean_128);
        const eanPrepack = a.filter(x => x.ean_prepack).map(x => x.ean_prepack);
        return [...new Set([...eanes128, ...eanPrepack])];
    }
}
ScanMaquilaController.TIPO_LECTURA_PREPACK = "prepack";
ScanMaquilaController.CANTIDAD_CAJAS_ESTIBA = 25;
export class ScanHelper {
    static checkEANinItems(ean, array) {
        return array.some((x) => (x.ean_128 === ean) || (x.ean_prepack === ean));
    }
    static checkBoxCapacity(box, packed) {
        let sum = 0;
        packed.forEach((x) => {
            if (x.caja === box) {
                sum += x.empacadas;
            }
        });
        return (sum < 25);
    }
    static getEmpacadosById(uuid, packed) {
        let empacados = 0;
        packed.forEach((item) => {
            if (item.id === uuid) {
                empacados += item.empacadas;
            }
        });
        return empacados;
    }
    static getProductionKey(item) {
        return ScanHelper.getScanAndPackKeyValue(item.codigo_referencia_produccion, item.talla_produccion, item.color_produccion, item.temporada);
    }
    static getPackedItemKey(item) {
        return `${ScanHelper.getSalesKey(item)}-${ScanHelper.getProductionKey(item)}`;
    }
    static getSalesKey(item) {
        return ScanHelper.getScanAndPackKeyValue(item.codigo_referencia_venta, item.talla_venta, item.color_venta, item.temporada);
    }
    static getScanAndPackKeyValue(referenciaVenta, tallaVenta, colorVenta, temporada) {
        return `${referenciaVenta}-${tallaVenta}-${colorVenta}-${temporada}`;
    }
    static compareString(x, y) {
        if (x > y)
            return 1;
        else if (y > x)
            return -1;
        return 0;
    }
}
class InfoItemsDisponibles {
    constructor(item, isNew, mustOpenBox) {
        this.item = item;
        this.isNew = isNew;
        this.mustOpenBox = mustOpenBox;
    }
}
class BoxInfo {
}
class OperationResult {
    constructor(success, message) {
        this.success = success;
        this.message = message;
    }
    combine(otherResult) {
        this.success = this.success && otherResult.success;
        if (this.message === null)
            this.message = otherResult.message;
    }
}
