<script>
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
</script>

<thead>
<tr>
   
    <th>
        <div>ASN</div>
    </th>
    <th>
        <div>Fecha</div>
    </th>  
    <th>
        <div>Cajas</div>
    </th>      
    <th>
        <div>Descargar</div>
    </th>  
</tr>
</thead>
