<script>
    import { Title, Actions, Content,  } from "@smui/dialog";
    import Dialog from "@smui/dialog/src/Dialog.svelte";
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import NauiInput from "../lib/naui/atoms/NauiInput.svelte";

    const passwords = {
        current: "",
        new: "",
        confirm: "",
    };

    let carga = false;
    let alert = {
        title: "",
        icon: "",
        message: "",
        open: false,
        action:null
    };

    function validatePassword(value) {
        const regex = /.*/;
        return regex.test(value);
    }

    const changePassword = async () => {
        carga = true;
        if (
            passwords.current.length > 0 &&
            passwords.new &&
            passwords.confirm &&
            passwords.new === passwords.confirm
        ) {
            
            try {
                const res = await fetch('/auth/change_password',
                {
                    method:'POST',
                    headers: {
                        "content-type":"application/json"
                    },
                    body:JSON.stringify({
                        current:passwords.current,
                        new:passwords.new
                    })
                }
            )
            
                
                switch (await res.status) {
                    
                    case 200:
                        alert.title = ''                
                        alert.icon = 'published_with_changes';
                        alert.message = (await res.json()).mensaje;
                        alert.open = true;
                        alert.action = '/'
                        break;
                    case 400:
                        alert.title = '';
                        alert.icon = 'warning'
                        alert.message = (await res.json()).mensaje??'La nueva contraseña debe contener por lo menos 9 caracteres, un caracter en mayúscula o en minúscula.';
                        alert.open = true;
                        break;
                    default:
                        alert.icon = 'priority_high';
                        alert.title = 'ERROR';
                        alert.open = true;
                        break;
                }
            } catch (error) {
                alert.icon = 'priority_high';
                alert.title = 'ERROR';
                alert.message = 'Hubo un error en el servidor';
                alert.open = true;
            }
            
            
            
        } else {
            alert.icon = 'sync_problem';
            alert.title = 'Error';
            alert.message = 'Corrobora las credenciales';
            alert.open = true;
        }
        carga = false;
    };
</script>

<div class="container">
    <div
        class="w-100"
        style="display: grid; justify-content: center; padding-top: 40px;border-radius: 25px; border-width: 5px;"
    >
        <form
            class="min-w-250px w-100"
            on:submit|preventDefault={changePassword}
        >
            <div class="row">
                <NauiInput
                    required
                    type="password"
                    bind:value={passwords.current}
                    label="Contraseña actual"
                    fill={true}
                />
            </div>
            <div class="row w-100" style="padding-top: 20px;">
                <NauiInput
                    required
                    type="password"
                    bind:value={passwords.new}
                    label="Nueva contraseña"
                    fill={true}
                />
            </div>
            <div
                class="row"
                style="padding-top: 20px; padding-bottom: 20px;"
            >
                <NauiInput
                    required
                    type="password"
                    bind:value={passwords.confirm}
                    label="Confirmar contraseña"
                    fill={true}
                />
            </div>
            <div
                class='row' style="display:flex; justify-content: center;"    
            >
                {#if carga}
                    <NauiButton
                        type="submit"
                        icon="key"
                        mode="solid"
                        color="accent"
                        caption=""
                        disabled
                    >
                    </NauiButton>
                {:else}
                    <NauiButton
                        type="submit"
                        icon="key"
                        mode="solid"
                        color="accent"
                        caption="CAMBIAR CONTRASEÑA"
                    />
                {/if}
            </div>
            
        </form>
    </div>
</div>

<Dialog open = {alert.open} >
    <Title>
        <div class="box sx-ii">
            <span class="material-icons accent">{alert.icon}</span>
            <h3 class="large">
                {
                    alert.title
                }
            </h3>
        </div>
    </Title>
        {#if alert.message}
            <Content>
                <div class="max-w-400px text-c mx-auto px-15">
                    <p class="large">
                    {alert.message}
                    </p>
                </div>
            </Content>
        {/if}    
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            {#if alert.action != null}
                <NauiButton caption="Aceptar" color="accent" href={alert.action} on:click={() => {alert.open = false;}}/>
            {:else}
                <NauiButton caption="Aceptar" color="accent" on:click={() => {alert.open = false;}}/>
            {/if}
        </div>
    </Actions>
</Dialog>
