<svelte:options accessors/>
<script lang="ts">
    import NauiTable from "../lib/naui/components/NauiTable.svelte";

    export let po;
    export let columns;
    export let data;
    export let total;

</script>

<div class="mt-1">
    <NauiTable {columns} {data} {total}/>
</div>