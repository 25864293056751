<script>
    import Dialog, {Actions, Content, Title} from "@smui/dialog";
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";

    export let open = false;
</script>

<Dialog bind:open>
    <Title>
        <h3 class="text-c">ASN Generado</h3>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
               ASN Generado con éxito
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton caption="ACEPTAR" color="accent" on:click />
        </div>
    </Actions>
</Dialog>
