<script lang="ts">
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiTag from "../../lib/naui/atoms/NauiTag.svelte";
    import {onMount} from "svelte";
    import { validate_store } from "svelte/internal";

    import Dialog, {Actions, Content, Title} from "@smui/dialog";

    export let data;   
    let id=data.id;
    let nvalor=data.valor;
    export let checked: boolean = false;

    let dialogOpenOK = false;
    let mensaje = '';
    

    onMount(() => {
        console.log(data);
       // date = new Date(data.date);
    })

    async function update() {
        if (nvalor !== '') {
            const payload = {
                id : id,
                valor : nvalor
            }
            
            const response = await fetch('/multitabla/actualizar_constante', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)                
            });
            if (response.ok){   
              //  alert(JSON.stringify(payload))
                mensaje = "Valor actualizado correctamente";
                dialogOpenOK = true; 
                //location.reload();
            }

        } else {
            //alert('Debe ingresar un nuevo valor')
            mensaje = "Debe ingresar un nuevo valor";
            dialogOpenOK = true; 
        }
    }

</script>
<tr>
    <td>
        <div>
            <NauiCheckbox bind:value={checked}/>
        </div>
    </td>
    <td>
        <div>{data.grupo}</div>
    </td>
    <td>
        <div>{data.name}</div>
    </td>
    <td>
        <div><input bind:value={nvalor} type="text" class="naui-input text min-w-400px">
    </div>
    </td>
    <td>
        <div>
            {#if data.estado === 'ACTIVO'}
                <NauiTag label="ACTIVO" backColor="#3cce5d" textColor="white"/>
            {:else if data.estado === 'INACTIVO'}
                <NauiTag label="INACTIVO" backColor="#cf453c" textColor="white"/>
            {/if}
        </div>
    </td>
    <td>
        <div>
            <NauiButton caption="Actualizar" mode="outline" color="accent" on:click={update}/>
        </div>
    </td>   
</tr>

<Dialog bind:open={dialogOpenOK} >
    <Title>
        <h3 class="text-c">Multitabla</h3>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
               {mensaje}
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton caption="Aceptar" color="accent" on:click={() => {dialogOpenOK = false;}}/>
        </div>
    </Actions>
</Dialog>
