<script lang="ts">
    import { onMount } from "svelte";
    import {getFormatter} from "../../utils";

    export let label;
    export let placeholder = '';
    export let type = 'text';
    export let value;
    export let isDisabled = false;
    export let id = null;
    export let step = '1';
    export let required = false;
    export let min = undefined;

    let fill = false;
    let focus = false;
    let input;

    let currencyMask;
    let formatter;

    onMount(() => {
        setStatus();
        if (type === 'currency') {
            formatter = getFormatter();
            currencyMask = formatter(value);
        }
    });

    function setStatus() {
        fill = value !== '' && value !== undefined;
        focus = false;
    }

    function handleCurrency(val) {
        value = +(val.toString().replace(/\D+/g, ''));
        currencyMask = formatter(value);
    }

    $: {
        if(type === 'currency' && formatter) {
            handleCurrency(value);
        }
    }
</script>
<div class="naui-field" class:active={fill}>
    <div class="naui-field-back" class:fill={fill}>
        <label class:focus={focus}>{label}</label>
        {#if type === 'email'}
            <input
                    class="naui-input w-100"
                    readonly={isDisabled}
                    type="email"
                    id={id}
                    required={required}
                    bind:value={value}
                    on:input                   
                    on:change
                    on:focus={()=> fill=true}
                    on:focusin={()=>focus=false}
            />
        {:else if type === 'password'}
            <input
                    class="naui-input w-100"
                    readonly={isDisabled}
                    type="password"
                    required={required}
                    id={id}
                    bind:value={value}
                    on:input
                    on:focus={()=> fill=true}
                    on:focusin={()=>focus=false}
            />
        {:else if type === 'number'}
            <input
                    class="naui-input w-100"
                    readonly={isDisabled}
                    type="number"
                    required={required}
                    step={step}
                    id={id}
                    min={min}
                    bind:value={value}
                    on:input
                    on:change
                    on:focus={()=>fill=true}
                    on:focusin={()=>focus=false}
            />
        {:else if type === 'currency'}
            <input
                    class="naui-input w-100"
                    readonly={isDisabled}
                    type="text"
                    required={required}
                    value={currencyMask}
                    id={id}
                    bind:this={input}
                    on:input={(ev) => {handleCurrency(ev.target.value)}}
                    on:change
                    on:focus={()=> fill=true}
                    on:focusin={()=>focus=false}
            />
        {:else}
            <input
                    class="naui-input w-100"
                    readonly={isDisabled}
                    placeholder={placeholder}
                    type="text"
                    required={required}
                    id={id}
                    bind:value={value}
                    bind:this={input}
                    on:input
                    on:click
                    on:focus={()=> fill=true}
                    on:focusin={()=>focus=false}
                    on:keypress
            />
        {/if}
    </div>
    <slot></slot>
</div>
<style>
    .naui-field-back.fill{
        background: white;
    }
</style>
