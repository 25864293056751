<script lang="ts">
    
    import {onMount} from "svelte";
    
    export let data;   
    

 
 onMount(() => {
        camabiar()      
    })

let MCritica, MAuditada, MMedida,MEmpaque,MConfeccion, MLiquidar
function camabiar()
 {
    if (data.Liquidar)
            MLiquidar = 'Sí'
    else
            MLiquidar = 'No'

    if (data.Critica)
            MCritica = 'Sí'
    else
            MCritica = 'No'

    if (data.Auditada)
            MAuditada = 'Sí'
    else
            MAuditada = 'No'

    if (data.Medida)
            MMedida = 'Sí'
    else
            MMedida = 'No'

    if (data.Empaque)
            MEmpaque = 'Sí'
    else
            MEmpaque = 'No'        
    
    if (data.Confeccion)
        MConfeccion = 'Sí'
    else
        MConfeccion = 'No'             
}
    
</script>
        <tr>           
            <td >
                {data.referencia_sel}
            </td>
            <td >
                {data.TipoAuditoria}
            </td>
            <td >
                {MLiquidar}  
            </td>
            <td >
                {data.TipoEmpaque != "" ? data.TipoEmpaque : "N/A"} 
            </td>
            <td >
                {data.Q != "" ? data.Q : "N/A"} 
            </td>              
            <td >
                {data.Orden} 
            </td>              
            <td >
                {MCritica}  
            </td>
            <td >                    
                {MAuditada} 
            </td>
            <td >
                {data.Cantidad} 
            </td>            
            <td >
                {data.Fecha}
                
            </td>
            <td >
                {data.Hora}
            </td>
            <td >
                {MMedida}
            </td>            
            <td >                    
                {MEmpaque}  
            </td>            
            <td >
                {MConfeccion}  
            </td>                  

</tr>
