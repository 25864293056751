<script>
    import NauiCheckbox from "../../../lib/naui/atoms/NauiCheckbox.svelte";
    export let checked = false;
</script>

<thead>
<tr>
    <th>
        <div>
            <NauiCheckbox bind:value={checked}/>
        </div>
    </th>
    <th>
        <div>Recipiente</div>
    </th>
    <th>
        <div>Estado</div>
    </th>
    <th>
        <div>Remisión</div>
    </th>
    <th>
        <div>Nombre prov.</div>
    </th>
    <th>
        <div>Cantidad</div>
    </th>
    <th>
        <div>Referencia</div>
    </th>
    <th>
        <div>Descripción</div>
    </th>
    <th>
        <div>Talla</div>
    </th>
    <th>
        <div>Color</div>
    </th>
    <th>
        <div>Fecha</div>
    </th>
</tr>
</thead>
