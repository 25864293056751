<script lang="ts">
    import { tooltip } from "../../lib/components/ToolTip/tooltip";    
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
    import NauiSelect from "../../lib/naui/atoms/NauiSelect.svelte";
    import {createEventDispatcher, onMount} from "svelte";
 
    export let checked: boolean = false;
    export let dataInsumo;
    export let dataInicialInsumo;
    export let index;
    export let admin;
    export let estadosModal;
    export let estadosModalVendor;

    const dispatch = createEventDispatcher();
    
    $: {
      if (!dataInsumo.checked) {
        dataInsumo = {...dataInicialInsumo};
        dataInsumo.observacion = dataInicialInsumo.observacion === '' ? '' : dataInicialInsumo.observacion;
        if(admin)
        {
          dataInsumo.bodega = dataInicialInsumo.bodega === '' ? '' : dataInicialInsumo.bodega;
        }
        else{
          dataInsumo.bodega = dataInicialInsumo.bodega;
        }

        dataInsumo.estado = dataInicialInsumo.estado;
        dataInsumo = dataInsumo;
      }
    }
  
  function onKeyPressCTRLEnter(e){
      dispatch('onkeypress', {
        event:e
      });
  }

  function actualizarInformacionParo() {
    if(checked){
        dataInsumo.observacion = dataInicialInsumo.observacion === '' ? '' : dataInicialInsumo.observacion;
        if(admin)
        {
          dataInsumo.bodega = dataInicialInsumo.bodega === '' ? '' : dataInicialInsumo.bodega;
        }
        else{
          dataInsumo.bodega = dataInicialInsumo.bodega;
        }
        dataInsumo.estado = dataInicialInsumo.estado;
    }
  }
</script>

<tr style="text-align: center;">
    <td>  
      <NauiCheckbox bind:value={checked} on:input on:click={actualizarInformacionParo}/>             
    </td>
    <td>{index}</td>
    <td>{dataInsumo.vendor_name}</td>
    <td>{dataInsumo.id}</td>
    <td>{dataInsumo.recipiente_codigo_recipiente}</td>
    <td>{dataInsumo.recipiente_orden_corte === "" ? "Sin información" : dataInsumo.recipiente_orden_corte}</td>
    <td>{dataInsumo.recipiente_codigo_referencia_produccion}</td>
    <td>{dataInsumo.recipiente_talla}</td>
    <td>{dataInsumo.insumo_codigo_insumo}</td>
    <td 
      class="{$$restProps.class || ''}"
      title={dataInsumo.insumo_descripcion} 
      use:tooltip
    >
      {dataInsumo.insumo_descripcion}
    </td>
    <td>{Number(dataInsumo.recipiente_insumo_cantidad).toFixed(2)}</td>
    <td>{dataInsumo.insumo_uni_medida}</td>
    <td>{dataInsumo.motivo}</td>
    <td>{Number(dataInsumo.cantidad).toFixed(2)}</td>
    <td><div class="div-Fechas">{dataInsumo.fecha_sol}</div></td>
    <td>
      <div class="div-Fechas">
        {dataInsumo.fecha_resp == null ? "Sin información" : dataInsumo.fecha_resp}
      </div>
    </td>
    <td>
    {dataInsumo.fecha_actualizacion_estado == null ? "Sin información" : dataInsumo.fecha_actualizacion_estado }
  </td>
    <td>
        {#if dataInsumo.checked }
          {#if admin}
            <NauiSelect
              label=""
              options={estadosModal}
              id="cmbEstadoModal"
              bind:value={dataInsumo.estado}
            />
          {:else}
            <NauiSelect
              label=""
              options={estadosModalVendor}
              id="cmbEstadoModal"
              bind:value={dataInsumo.estado}
            />
          {/if}
        {:else}
          {dataInsumo.estado}
        {/if}  
    </td>
    <td>
        {#if dataInsumo.checked }
          {#if admin}
            <NauiInput label="" id={`txtBodega-${dataInsumo.id}`} bind:value={dataInsumo.bodega} on:keypress={onKeyPressCTRLEnter} />
          {/if}
        {:else}
          {dataInsumo.bodega == "" ? "Sin información" : dataInsumo.bodega}
        {/if}
    </td>
    <td>
        {#if dataInsumo.checked }
          <NauiInput
            label=""
            id={`txtObservaciones-${dataInsumo.id}`}
            bind:value={dataInsumo.observacion}
            on:keypress={onKeyPressCTRLEnter}
          />
        {:else}  
          {dataInsumo.observacion == "" ? "Sin información" : dataInsumo.observacion}
        {/if}
    </td>
  </tr>