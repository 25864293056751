<script lang="ts">
    import NauiInput from "../lib/naui/atoms/NauiInput.svelte";
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import {onMount} from 'svelte';
    import NauiDatePicker from "../lib/naui/atoms/NauiDatePicker.svelte";
    import NauiDateRange from "../lib/naui/atoms/NauiDateRange.svelte";
    import AuditoriaItem from "./components/AuditoriaItemConfirmar.svelte";
    import AuditoriaItemXLS from "./components/AuditoriaItemConfirmarXLS.svelte";
    import NauiSelect from "../lib/naui/atoms/NauiSelect.svelte";
    import Dialog, {Actions, Content, Title} from "@smui/dialog";
    import { vendor } from "../stores/sessionVendor";
    import { TableExport } from "tableexport";
  
    
    export let data = [];
    export let horario;
            
    let AuditoriaItems = [];
    let localData = [];
    let dialogOpen = false; 
    let dialogOpenVolver = false; 
    let mensaje;
    let buscando = false
    let referencia = '';
    let rango = ''
    let deshabilitarBtnConfirmar = false;
    const swAuditar = false;


    function volver()
    {
        window.location.href =  '/auditoria/home'
    }
    onMount(() => {
        setTimeout(() => {
            document.getElementById("referencia").focus();     
            }, 500);
        loadData();
        let currentTime = new Date();

        if (!(currentTime.getHours()*1 >= horario.inicio * 1  && currentTime.getHours()*1 < horario.fin * 1))
        {
            mensaje = "No puedes confirmar auditoría en este momento. El horario establecido es desde " + horario.inicio + ":00 hastas las " + horario.fin + ":00" 
            dialogOpenVolver = true

        }
        buscar()      
    });

    let vendors = []
    let vendors1 = []
    function loadData() {
        referencia = ''
        localData = data.map((x) => {
            return {...x, checked: false}
        });

        vendors.push({label: 'Todos',
            value: '0'}  )

        vendors1 = localData.map((y) => {
        return {
            label: y.name,
            value: y.id
        }})
        vendors = vendors.concat(vendors1)    
    }

    
    let estados = [{
            label: 'Solicitada',
            value: 'Solicitada'
        },
        {
            label: 'Programada',
            value: 'Programada'
        },
        {
            label: 'Lote Fraccionado',
            value: 'Lote Fraccionado'
        },
        {
            label: 'Entrega Certificada',
            value: 'Entrega Certificada'
        }
    ]   

let estado_sel = 'Solicitada'
let vendor_sel = '0'
let facturas = []
let busqueda  = []

async function buscar() {        
            buscando = true
            AuditoriaItems = []
            const payload = {"estado" : estado_sel, "vendor": vendor_sel, "referencia": referencia, "rango": rango, "swAuditar": swAuditar}            
            const response = await fetch('/auditoria/buscar', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)
            });
            facturas = await response.json();
            if (response.ok){            
                buscando = false
                AuditoriaItems = facturas['data']
                AuditoriaItems.forEach((x) => {
                    if(x.estado != 'Solicitada') //para que muestre la hora y fecha programada
                    { 
                        x.fecha_sug  = x.fecha_pro;
                        x.hora_sug  = x.hora_pro;
                    }
                    })
            AuditoriaItems = AuditoriaItems.map((x) => {return {...x, checked: false}})              
            }
    }

function cancelar() {       
    document.location = '/auditoria/home'
}

let resp= []
resp['mensaje'] = ''
async function confirmar() {
            deshabilitarBtnConfirmar = true;
            if(!validar())
            {
                dialogOpen=true;
                deshabilitarBtnConfirmar = false;
                return false
            }
            const payload = AuditoriaItems
            
            const response = await fetch('/auditoria/guardar_confirmar', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)
            });
            resp = await response.json() 
            if (response.ok){                   
                       
                console.log(resp)             
                buscar()
                mensaje = resp['mensaje']
                dialogOpen=true
                ///alert (resp['mensaje'])                
                //window.location.href =  '/auditoria/home'
            }
            deshabilitarBtnConfirmar = false;
    }
    
    function validarFiltros(){
        if(estado_sel !== "Solicitada" && (referencia === "" && rango === "")){
            mensaje = "Debe seleccionar el filtro de referencia o el filtro de rango de fechas.";
            dialogOpen=true
        } else {
            buscar()
        }
    }

    function validar()
    {
        let returno = true;   
        AuditoriaItems.forEach((x) => {        
        if(x.estado.trim() == 'Solicitada' && x.checked ) 
        { 
            mensaje = "Existen auditorias a guardar con estado Solicitada. Por favor seleccione otro estado";                        
            returno = false;
        }                    
        })

        AuditoriaItems.forEach((x) => {
        if(x.auditor.trim() == '' && x.checked) 
        { 
            mensaje = "Existen auditorias a guardar sin nombre de auditor. Por favor ingrese el nombre de auditor";                        
            returno = false;
        }                    
        })

        let enviar = false
        AuditoriaItems.forEach((x) => {
        if(x.checked) 
        { 
            enviar = true          
        }                    
        })


        if(!enviar)
        {
            mensaje = "Debe seleccionar solicitudes"                        
            return false;
        }

        return returno;
    }


  function excel() {

    let tabla = document.querySelector("#tabla");
    let tableExport = new TableExport(tabla, {
        exportButtons: false, // No queremos botones
        filename: "Confirmar", //Nombre del archivo de Excel
        sheetname: "Por Confirmar", //Título de la hoja
    });
    console.log(tableExport)
    let datos = tableExport.getExportData();
    let preferenciasDocumento = datos.tabla.xlsx;
    tableExport.export2file(preferenciasDocumento.data, preferenciasDocumento.mimeType, preferenciasDocumento.filename, preferenciasDocumento.fileExtension, preferenciasDocumento.merges, preferenciasDocumento.RTL, preferenciasDocumento.sheetname);
    }


</script>
<section>

    <table class="naui-table w-70" >
        <tr>
            <td >
                <NauiSelect  bind:value={estado_sel} options={estados}  label="Estado"  />
            </td>
            <td >
                <NauiSelect  bind:value={vendor_sel} options={vendors}  label="Vendor"  />
            </td>
            <td >
                <NauiInput id="referencia" bind:value={referencia} label="Referencia"  />
            </td>
            <td >
                <NauiDateRange label="Buscar registros entre" bind:value={rango}  /> 
            </td>
            <td >
                <NauiButton caption="Buscar Solicitudes"  mode="outline" color="accent" on:click={validarFiltros}/>         
            </td>
            
            <td >
                <NauiButton caption="Confirmar Auditoria"  mode="outline" color="accent" disabled = {deshabilitarBtnConfirmar} on:click={deshabilitarBtnConfirmar === false ? confirmar : ()=> {}}/>                
            </td>
            <td >
                <NauiButton caption="Descargar Excel"  mode="outline" color="accent" on:click={excel}/>                
            </td>
            <td >   
                <div><NauiButton caption="Cancelar"  mode="outline" color="accent" on:click={cancelar} /> </div>                  
            </td>   
        </tr>
    </table>   
 
    <table class="naui-table w-100" id="tabla" style="display:none ;">        
        <thead>
            <tr>   
                <th width="1%">
                    Sol.
                </th>    
                <th >
                    Vendor
                </th>           
                <th >
                    Referencia
                </th>
                <th >
                    Tipo de Auditoría
                </th>
                <th width="1%">
                    Liquidar Referencia
                </th>
                <th width="1%">
                    Tipo de Empaque
                </th>
                <th width="1%">
                    Q
                </th>
                <th  width="100px">
                    Orden de Corte
                </th>            
                <th width="1%" >
                    Crítica   
                </th>            
                <th width="1%" >                               
                    Auditada
                </th>
                <th width="1%" >
                    Cantidad
                </th>    
                <th  width="150px">
                    Estado
                </th>    
                <th width="90px">
                    Fecha Sugerida Programada
                </th>
                <th width="1%">
                    Hora Sugerida Programada
                </th>
                <th  width="110px">
                    Nombre Auditor
                </th>
                <th width="1%">
                    Medida  
                </th>            
                <th width="1%">
                    Empaque
                </th>            
                <th width="1%">
                    Confección
                </th>            
            </tr>
        </thead>
        <tbody>                     
            {#each AuditoriaItems as itemE (itemE.id)}
                <AuditoriaItemXLS data="{itemE}"  /> 
            {/each}
        </tbody>
    </table>   

    <table class="naui-table w-100" >
        
        <thead>
            <tr>   
                <th width="0%">
                    
                </th>    

                <th width="1%">
                    Sol.
                </th>    
                <th >
                    Vendor
                </th>           
                <th >
                    Referencia
                </th>
                <th >
                    Tipo de Auditoría
                </th>
                <!--
                    <th width="1%">
                    Liquidar Referencia
                    </th>
                -->
                <th width="1%">
                    Tipo de Empaque
                </th>
                <th width="1%">
                    Q
                </th>
                <!--
                    <th  width="100px">
                        Orden de Corte
                    </th>            
                    <th width="1%" colspan="2">
                        Crítica
                    
                        Auditada
                    </th>
                -->
                <th width="1%" >
                    Cantidad
                </th>    
                <th  width="150px">
                    Estado
                </th>    
                <th width="90px">
                    Fecha Sugerida Programada
                </th>
                <th width="1%">
                    Hora Sugerida Programada
                </th>
                <th  width="110px">
                    Nombre Auditor
                </th>
                <th width="1%" colspan="3">
                    Medida
                
                    Empaque
                
                    Confección
                </th>            
            </tr>
        </thead>
        <tbody>
                     
            {#each AuditoriaItems as itemE (itemE.id)}
                <AuditoriaItem data="{itemE}" estados={estados} bind:checked={itemE.checked}  />     
            {:else}
                {#if !buscando }
                <tr>
                    <td colspan="14">
                        <div><p>No se encontraron registros</p></div>
                    </td>
                </tr>      
                {:else}           
                <tr>
                    <td colspan="14">
                        <div><p>Buscando...</p></div>
                    </td>
                </tr>                                       
                {/if}                       
            {/each}
        </tbody>
    </table>   

    <Dialog bind:open={dialogOpen} >
        <Title>
            <h3 class="text-c">Confirmar auditoría</h3>
        </Title>
        <Content>
            <div class="max-w-400px text-c mx-auto px-15">
                <p class="large">
                    {mensaje}
                </p>
            </div>
        </Content>
        <Actions>
            <div class="box-r sx-ii px-1 py-ii">
                <NauiButton caption="Aceptar" color="accent" on:click={() => {dialogOpen = false;}}/>
            </div>
        </Actions>
    </Dialog>

    <Dialog bind:open={dialogOpenVolver} scrimClickAction=""
    escapeKeyAction="">
        <Title>
            <h3 class="text-c">Confirmar auditoría</h3>
        </Title>
        <Content>
            <div class="max-w-400px text-c mx-auto px-15">
                <p class="large">
                   {mensaje}
                </p>
            </div>
        </Content>
        <Actions>
            <div class="box-r sx-ii px-1 py-ii">
                <NauiButton caption="VOLVER" color="accent" on:click={volver}/>
            </div>
        </Actions>
    </Dialog>


</section>
<style>
/*    .search {
        border-radius: 2rem;
        padding-left: 2.5rem;
    }

    .search + span {
        position: absolute;
        left: .75rem;
        top: .75rem;
        color: var(--secondary);
    }

    .naui-filter {
        min-width: 150px;
        color: var(--secondary);
        opacity: 1;
    }*/
</style>