export function getFormatter() {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
    });
    return function (value) {
        if (!value) {
            value = 0;
        }
        return formatter.format(value);
    };
}
export function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}
// function h_s_lToHex(h, s, l) {
//     h /= 360;
//     s /= 100;
//     l /= 100;
//     var r, g, b;
//     if (s === 0) {
//       r = g = b = l;
//     } else {
//       const hue2rgb = function(p, q, t) {
//         if (t < 0) t += 1;
//         if (t > 1) t -= 1;
//         if (t < 1 / 6) return p + (q - p) * 6 * t;
//         if (t < 1 / 2) return q;
//         if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
//         return p;
//       };
//       const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
//       const p = 2 * l - q;
//       r = hue2rgb(p, q, h + 1 / 3);
//       g = hue2rgb(p, q, h);
//       b = hue2rgb(p, q, h - 1 / 3);
//     }
//     const toHex = function(x) {
//       const hex = Math.round(x * 255).toString(16);
//       return hex.length === 1 ? '0' + hex : hex;
//     };
//     return '#'+toHex(r)+toHex(g)+toHex(b);
//   }
// function getColors(){
//     let colors:string[] = []
//     for (let i = 0; i < 50; i++) {
//         colors.push(h_s_lToHex(Math.random() * (360 - 3), 54, 70)) 
//     }
//     return colors
// }
const colors = [
    "#75F2A0",
    "#F9EBEA",
    "#EBDEF0",
    "#D4E6F1",
    "#D1F2EB",
    "#F1948A",
    "#F9E79F",
    "#C39BD3",
    "#A3E4D7",
    "#F5BA86",
    "#75F2E9",
    "#F5A3D7",
    "#75AEF2",
    "#F275E1",
    "#F2758A",
    "#F2AC75",
    "#CAF275",
    "#90F275",
    "#BD75F2",
    "#F5F086",
    "#E575F2",
    "#F5A3C0",
    "#F2D075",
    "#CABC99",
    "#F58686",
    "#86F5CB",
    "#86E4F5",
    "#AD86F5",
    "#E0A3F5",
    "#86A8F5",
];
export function getColor(box) {
    return colors[box % 30];
}
