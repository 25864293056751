export let estadosAuditar = [
    {
        label: 'Programada',
        value: 'Programada'
    },
    {
        label: 'Lote Fraccionado',
        value: 'Lote Fraccionado'
    },
    {
        label: 'Entrega Certificada',
        value: 'Entrega Certificada'
    },
    {
        label: 'Aprobado',
        value: 'Aprobado'
    },
    {
        label: 'Rechazado',
        value: 'Rechazado'
    },
    {
        label: 'Cancelado',
        value: 'Cancelado'
    }
];
