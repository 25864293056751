<script lang="ts">
    import Dialog, {Actions, Content, Title} from "@smui/dialog";
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import NauiCheckbox from "../lib/naui/atoms/NauiCheckbox.svelte";

    export let checked = false;    
    export let vVisibleDEO = false;
    export let vVisibleDETO = false;    
    export let vVisibleMsj = false;

    let filteredData = new Array();
    let searchKey: string = null;
    export let data = [];
    let globalCheck = false;    
    let vVisibleCargando = false;
    let vVisibleTablaFiltro = false;
    let vArregloPOs = new Array();
    let vNumeroPOEliminar;
    let vReqNumeroPo = false;
    let vMostrarTodasColumnas = false;
    let vMensaje = "";
    let vDeshabilitarBotones= false;

    //Funcion que se encarga de Marcazr o desmarczar los checks de cada registro
    function globalCheckChange(check) {
        filteredData.forEach((x) => {
           if(check){
            x.Marcado = 1;
           }
           else{
            x.Marcado = 0;
           }
        });  

        //se asigna valor para obligar a refrescar la vista
        filteredData = filteredData;
    }    

    //Funcion que se encarga de Marcazr o desmarczar los checks de cada registro
    function mostrarOcultarColumnas(check) {       
        if(check){
            vMostrarTodasColumnas = true;
        }
        else{
            vMostrarTodasColumnas = false;
        }
    }    

    function adicionarPO(){
        vReqNumeroPo = false;

        if(searchKey == null || searchKey == ""){
            vReqNumeroPo = true;
        }
        else{

            var vJSONNumeroPO = {
                numero: searchKey,
                Existe: true,
                Color: "#FAFAFA"
            }

            vArregloPOs.push(vJSONNumeroPO);
            searchKey = "";
            vVisibleTablaFiltro = true;
            vArregloPOs = vArregloPOs;        
        }        
    }

    function confirmarEliminarPO(pNumero){
        vVisibleDEO = true;
        vNumeroPOEliminar = pNumero;        
    }

    function eliminarPO(){       
        var vJSON = vArregloPOs.find(x => x.numero == vNumeroPOEliminar);
        var vPosicion = vArregloPOs.indexOf(vJSON);        

        vArregloPOs.splice(vPosicion, 1);
        vArregloPOs = vArregloPOs;

        vVisibleDEO = false;

        if(vArregloPOs.length == 0){
            vVisibleTablaFiltro = false;
        }
    }

    async function getDatosPOs(){        
        vVisibleCargando = true;
        vDeshabilitarBotones = true;

        const response = await fetch('/carga_po/get_datos_pos', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify({"filtro":vArregloPOs})
            });

            if (response.ok){
                let vJSONRes = await response.json();
                                            
                filteredData = vJSONRes.data.Detail;

                //Valida que el WS haya respondido un arreglo con un solo elemento, esto signifoca que no hay datos que cumplan con el filtrp
                if(filteredData.length == 1){
                    //Valida que elemento que viene en la posicion 0 no venga con numero de po
                    if(filteredData[0].po_number == ""){
                        vVisibleMsj = true;
                        vMensaje = "No se encontraron PO's que cumplan con los filtros ingresados";
                        vVisibleCargando = false;                              
                        globalCheck = false;
                        vDeshabilitarBotones = false;
                        filteredData = new Array();
                        return;
                    }
                }
                vVisibleCargando = false;                              
                globalCheck = false;
                vDeshabilitarBotones = false;

                validarExistenciaPoItems();
                
            }
            else{
                vVisibleMsj = true;
                vMensaje = "Se presentaron problemas al consultar PO's";
                vVisibleCargando = false;     
                vDeshabilitarBotones = false;
            }

            
        }

    function confirmarEliminarTodosPO(){
        vVisibleDETO = true;        
    }

    async function limpiarFiltros(){        
        vArregloPOs = new Array();
        vVisibleTablaFiltro = false;
        vVisibleDETO = false;
        filteredData = new Array();
    }

    async function validarExistenciaPoItems(){        
        vVisibleCargando = true;
        vDeshabilitarBotones = true;

        //Inicializa el arreglo para alamcenar los datos de consulta de Po item
        var vArregloJSONPoItem = new Array();

        //Recorre todo el arreglo de poitems para armar el JSON para consulta
        filteredData.forEach((vObjPoItem) => {
           vArregloJSONPoItem.push(
            {
                numero_po: vObjPoItem.po_number,
                posicion: vObjPoItem.position,
                cantidadPedida: vObjPoItem.q_ordered,
                existe: false,
                catidadPedidaMenor: false
            }
           );
        });          

        const response = await fetch('/carga_po/validar_existencia_POItem', {
            method: 'post',
            headers: {
                "content-type":"application/json"
            },
            body: JSON.stringify({"po_items":vArregloJSONPoItem})
        });

        if (response.ok){
            let vJSONRes = await response.json();              

            //Recorre los registros de las PO's del WS
            filteredData.forEach(vPo =>{                    
                //Obtiene el registro de Po Item dado el numero de po y la posicion
                var vNumPo = vJSONRes.data.find(x => x.numero_po == vPo.po_number && x.posicion == vPo.position);                
                
                //Inicializa los valores de cada registro para que no se pinte
                vPo.Existe = false;
                vPo.catidadPedidaMenor = false;                
                vPo.Color = "#FAFAFA"; 

                //Si existe la marca pintandola de verde
                if(vNumPo.existe){                        
                    vPo.Existe = true;  
                    vPo.Color = "#D1E7DD";                      
                }

                //Si la cantidad pedida de BD es menor a la cantidad de WS marca el regisatro para mostrar advertencia
                if(vNumPo.catidadPedidaMenor){                        
                    vPo.catidadPedidaMenor = true;
                }                
            })  

            //Recorre los registros de los numeros de PO para marcar las que existen y las que no
            vArregloPOs.forEach(vPo =>{                    
                //Obtiene el registro de Po Item dado el numero de po
                var vNumPo = vJSONRes.data.find(x => x.numero_po == vPo.numero);                                
                
                //Inicializa los valores de cada registro para que no se pinte
                vPo.Existe = false;                
                vPo.Color = "#F8D7DA"; 

                if(vNumPo){
                    //Si existe la marca pintandola de verde
                    if(vNumPo.length != 0){                        
                        vPo.Existe = true;  
                        vPo.Color = "#D1E7DD";                      
                    }          
                }                
            })  

            filteredData = filteredData;
            vArregloPOs = vArregloPOs;
            vVisibleCargando = false;  
            vDeshabilitarBotones = false;    
        }
        else{
            vVisibleMsj = true;
            vMensaje = "Se presentaron problemas al consultar PO's";
            vVisibleCargando = false;
            vDeshabilitarBotones = false;    
        }
    }

    async function guardarDatosPOs(){           
        
        let vSeleccionados = new Array();        

        //Valida que haya seleccionado por lo menos un registro
        vSeleccionados = filteredData.filter(x => x.Marcado)        

        if(!vSeleccionados){
            vVisibleMsj = true;
            vMensaje = "Debe seleccionar por lo menos un registro para importar";
            return;
        }
        
        vVisibleCargando = true;
        vDeshabilitarBotones = true;

        console.log(vSeleccionados)

        const response = await fetch('/carga_po/guardar_datos_pos', {
            method: 'post',
            headers: {
                "content-type":"application/json"
            },
            body: JSON.stringify({"po_items":vSeleccionados})
        });

        

        if (response.ok){
            let vJSONRes = await response.json();                

            vVisibleMsj = true;
            vMensaje = "Se han importado los registros con éxito";
            vVisibleCargando = false;
            filteredData = new Array();            
            getDatosPOs();

        }
        else{
            vVisibleMsj = true;
            vMensaje = "Se presentaron problemas importando los registros";
            vVisibleCargando = false;
        }
        

        vVisibleCargando = false;
        vDeshabilitarBotones = false;
    }
    

    //Se define RN para cuando cambie la valor del checkbox de todos cambie los check de los registros
    $: globalCheckChange(globalCheck);
    $: mostrarOcultarColumnas(vMostrarTodasColumnas);

</script>
<section>
    <table class="naui-table w-100">
        <thead>
            <tr>
                <th style="text-align: center;">
                    <br/>
                    Ingrese el número o números de PO's para consultar
                    <br/>
                    <br/>
                </th>
                <th>
                    <br/>
                    Registros encontrados
                    <br/>
                    <br/>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style="width:10%;vertical-align: top !important;">
                    <div style="overflow-x:auto;max-width:100%; width:100%;max-height:75vh; align-items:unset !important;">
                        <table>
                            <tr>
                                <td class="tdSinBorde"> 
                                    <div class="box-l sx-1 mt-1">
                                        <div class="relative">
                                            <input bind:value={searchKey} type="search" class="naui-input search min-w-400px" placeholder="Ingrese en número de PO">
                                            <span class="absolute material-icons-outlined" style="color: #888;">search</span>
                                        </div>
                                        <NauiButton caption="Adicionar" mode="outline" color="accent" on:click={adicionarPO}/>
                                    </div>
                                    {#if vReqNumeroPo}
                                    <div class="ml-15" style="color:red">Debe ingresar un número de PO</div>                    
                                    {/if}
                                </td>
                            </tr>
                            <tr>
                                <td class="tdSinBorde">
                                    {#if vVisibleTablaFiltro}
                                    <br/>
                                    <br/>
                                    <div style="border:solid 1px black; border-radius: 20px; padding-left: 20px; padding-right: 20px; padding-bottom: 20px;padding-top: 20px;max-width:100%; width:100%;max-height:75vh;">                                        
                                        <table class="naui-table w-100">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <br/>
                                                        Número de PO's a consultar
                                                        <br/>
                                                        <br/>
                                                    </th>
                                                </tr>
                                            </thead> 
                                            <tbody>
                                                {#each vArregloPOs as item}
                                                <tr>
                                                    <td align="center" style="background-color:{item.Color};">                                    
                                                        <table>
                                                            <tr>
                                                                <td class="tdSinBorde" >
                                                                    <div>{item.numero}</div>                                                    
                                                                </td>
                                                                <td class="tdSinBorde" style="cursor: pointer" on:click={confirmarEliminarPO(item.numero)}>
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </td>
                                                            </tr>                                                                                                                      
                                                        </table>                                
                                                    </td>
                                                </tr>
                                                {/each}
                                                <tr>
                                                    <td class="tdSinBorde">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" class="tdSinBorde">
                                                        <table>
                                                            <tr>
                                                                <td class="tdSinBorde">
                                                                    {#if vDeshabilitarBotones}
                                                                        <button disabled class="btn disabled" type="button">                                                                            
                                                                            <span>Buscar</span>
                                                                        </button>
                                                                    {:else}
                                                                        <NauiButton caption="Buscar" mode="outline" color="accent" on:click={getDatosPOs}/>
                                                                    {/if}                                                                    
                                                                </td>
                                                                <td class="tdSinBorde">
                                                                    <NauiButton caption="Limpiar filtros" mode="outline" color="accent" on:click={confirmarEliminarTodosPO}/>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tdSinBorde">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                {#if vVisibleCargando}                                                
                                                <tr>
                                                    <td align="center" class="tdSinBorde">
                                                        Cargando.......
                                                    </td>
                                                </tr>
                                                {/if}
                                                <tr>
                                                    <td class="tdSinBorde" align="center">
                                                        <table>
                                                            <tr>
                                                                <td class="tdSinBorde">
                                                                    <div style="background-color: #D1E7DD; border-radius:8px; width: 30px; min-height:30px; !important;"></div>    
                                                                </td>
                                                                <td class="tdSinBorde">
                                                                    PO existente
                                                                </td>
                                                                <td class="tdSinBorde">
                                                                    <div style="background-color: #F8D7DA; border-radius:8px; width: 30px; min-height:30px; !important;"></div>    
                                                                </td>
                                                                <td class="tdSinBorde">
                                                                    PO no existente
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>                       
                                        </table>
                                        </div>
                                    {/if}
                                </td>
                            </tr>                            
                        </table>                    
                    </div>                    
                </td>
                <td style="max-width:300px;">
                    <br/>
                    <table class="w-100">
                        <tr>
                            <td style="width:50%;" class="tdSinBorde" align="left">
                                <table class="w-100">
                                    <tr>
                                        <td class="tdSinBorde">
                                            <div>
                                                <NauiCheckbox bind:value={vMostrarTodasColumnas}/>
                                                &nbsp;&nbsp;Mostrar todas la columnas
                                            </div>                                
                                        </td>
                                        <td class="tdSinBorde">
                                            <table class="w-100">
                                                <tr>
                                                    <td class="tdSinBorde">
                                                        <div style="background-color: #D1E7DD; border-radius:8px; width: 30px; min-height:30px; !important;"></div>    
                                                    </td>
                                                    <td class="tdSinBorde">
                                                        PO existente
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td style="width:50%;" class="tdSinBorde" align="right">
                                {#if vDeshabilitarBotones}
                                    <button disabled class="btn disabled" type="button">                                                                            
                                        <span>Importar</span>
                                    </button>
                                {:else}
                                    <NauiButton caption="Importar" mode="outline" color="accent" on:click={guardarDatosPOs}/>
                                {/if}                                
                            </td>
                        </tr>   
                    </table>
                    <br/>
                    <br/>
                    <div style="overflow-x:auto;max-width:100%; width:100%;max-height:65vh; align-items:unset !important;">
                        <table>                            
                            <tr>
                                <td>
                                    <table class="naui-table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div>
                                                        <NauiCheckbox bind:value={globalCheck}/>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div>PO</div>
                                                </th>
                                                {#if vMostrarTodasColumnas}
                                                    <th>
                                                        <div style="width:150px;">Proveedor</div>
                                                    </th>
                                                {/if}                                                
                                                <th>
                                                    <div>Posición</div>
                                                </th>
                                                <th>
                                                    <div style="width:170px;">Descripción del Material</div>
                                                </th>
                                                <th>
                                                    <div>Co.Color</div>
                                                </th>
                                                <th>
                                                    <div>Co.Talla</div>
                                                </th>
                                                <th>
                                                    <div>Cant.pedida</div>
                                                </th>
                                                {#if vMostrarTodasColumnas}
                                                    <th>
                                                        <div style="width:140px;">Cant. entregada</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:140px;">Cant. pendiente</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:130px;">Unidad</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:60px;">Precio</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:50px;">EAN</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:100px;">Ean prepack</div>
                                                    </th>
                                                {/if}                                                 
                                                <th>
                                                    <div style="width:60px;">EAN 128</div>
                                                </th>
                                                <th>
                                                    <div style="width:120px;">EAN 128 prepack</div>
                                                </th>
                                                {#if vMostrarTodasColumnas}
                                                    <th>
                                                        <div style="width:200px;">Unidad BOL</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:130px;">Co.Material</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:130px;">Segmento de stock</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:80px;">Ciudad</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:170px;">EAN material componente</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:150px;">Material componente</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:170px;">Codigo Material Prepack</div>
                                                    </th>
                                                {/if}                                                   
                                                <th>
                                                    <div style="width:120px;">Material Generico</div>
                                                </th>
                                                <th>
                                                    <div style="width:120px;">Material Generico</div>
                                                </th>
                                                <th>
                                                    <div style="width:120px;">Talla componente</div>
                                                </th>
                                                {#if vMostrarTodasColumnas}
                                                    <th>
                                                        <div style="width:130px;">Des. talla</div>
                                                    </th>
                                                {/if}                                                    
                                                <th>
                                                    <div style="width:130px;">Color componente</div>
                                                </th>
                                                {#if vMostrarTodasColumnas}
                                                    <th>
                                                        <div style="width:150px;">Des. color</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:150px;">Composición español</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:150px;">Composición ingles</div>
                                                    </th>
                                                    <th>
                                                        <div style="width:50px;">Pais</div>
                                                    </th>
                                                {/if}                                                   
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {#each filteredData as item}   
                                            <tr style="background-color: {item.Color};">
                                                <td>
                                                    <div>
                                                        <NauiCheckbox bind:value={item.Marcado}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <table>
                                                        <tr>
                                                            <td class="tdSinBorde">
                                                                {item.po_number}</td>
                                                            <td class="tdSinBorde">
                                                                {#if item.catidadPedidaMenor}
                                                                <span class="material-icons-outlined" style="color: orange; cursor: pointer;" title="Cantidad pedida es diferente a la cantidad registrada">
                                                                    report_problem
                                                                </span>
                                                                {/if}                        
                                                            </td>
                                                        </tr>
                                                    </table>                                              
                                                </td>
                                                {#if vMostrarTodasColumnas}
                                                    <td>{item.codigo_sap_vendor}</td>
                                                {/if}                                                      
                                                <td>{item.position}</td>
                                                <td>{item.reference}</td>
                                                <td>{item.color}</td>
                                                <td>{item.size}</td>
                                                <td>{item.q_ordered}</td>
                                                {#if vMostrarTodasColumnas}
                                                    <td>{item.q_delivered}</td>
                                                    <td>{item.q_pending}</td>
                                                    <td>{item.unit}</td>
                                                    <td>{item.price}</td>
                                                    <td>{item.ean}</td>
                                                    <td>{item.ean_prepack}</td>
                                                {/if}                                                                          
                                                <td>{item.ean_128}</td>
                                                <td>{item.ean_prepack_128}</td>
                                                {#if vMostrarTodasColumnas}
                                                    <td>{item.und_por_bolsa}</td>
                                                    <td>{item.cod_reference}</td>
                                                    <td>{item.seg_stock}</td>
                                                    <td>{item.city}</td>
                                                    <td>{item.ean_componente}</td>
                                                    <td>{item.material_componente}</td>
                                                    <td>{item.material_prepack}</td>
                                                {/if}  
                                                
                                                <td>{item.material_generico}</td>
                                                <td>{item.material_generico_componente}</td>
                                                <td>{item.talla_componente}</td>
                                                {#if vMostrarTodasColumnas}
                                                    <td>{item.desc_talla_componente}</td>
                                                {/if}                                                      
                                                <td>{item.color_componente}</td>
                                                {#if vMostrarTodasColumnas}
                                                    <td>{item.desc_color_componente}</td>
                                                    <td>{item.composicion_es}</td>
                                                    <td>{item.composicion_en}</td>
                                                    <td>{item.pais_origen}</td>
                                                {/if}                                                      
                                            </tr>                                                                                                                                 
                                        {:else}
                                            <tr>
                                                <td colspan="31">
                                                    <div><p>No se encontraron registros</p></div>
                                                </td>
                                            </tr>
                                        {/each}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>                                                     
                        </table>                        
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <!--{searchKey}
    {cant}-->    
</section>
<Dialog bind:open={vVisibleDEO}>
    <Title>
        <h3 class="text-c">Eliminar número PO</h3>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
                ¿Esta seguro de eliminar la PO ingresada?.
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton caption="Cancelar" color="accent" on:click={()=> vVisibleDEO=false}/>
            <NauiButton caption="Eliminar" color="accent" on:click={eliminarPO}/>
        </div>
    </Actions>
</Dialog>
<Dialog bind:open={vVisibleMsj}>
    <Title>
        <h3 class="text-c">Mensaje</h3>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
                {vMensaje}
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton caption="Cerrar" color="accent" on:click={()=> vVisibleMsj=false}/>            
        </div>
    </Actions>
</Dialog>
<Dialog bind:open={vVisibleDETO}>
    <Title>
        <h3 class="text-c">Limpiar filtros</h3>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
                ¿Esta seguro de eliminar las PO's ingresadas?.
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton caption="Cancelar" color="accent" on:click={()=> vVisibleDETO=false}/>
            <NauiButton caption="Eliminar" color="accent" on:click={limpiarFiltros}/>
        </div>
    </Actions>
</Dialog>
<style>
    .search {
        border-radius: 2rem;
        padding-left: 2.5rem;
    }

    .search + span {
        position: absolute;
        left: .75rem;
        top: .75rem;
        color: var(--secondary);
    }
    .tdSinBorde{
        border-bottom: 0px !important;
    }
</style>