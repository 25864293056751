<script>
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
</script>

<thead>
<tr>
   
    <th>
        <div>Id</div>
    </th>
    <th>
        <div>Fecha</div>
    </th>  
    <th>
        <div>Vendor</div>
    </th>  
    <th>
        <div>Código SAP Vendor</div>
    </th>  
    <th>
        <div>Nro Factura Vendor</div>
    </th>  
    <th width="150px" > 
       <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total</div>        
    </th>  
    <th>
        <div>Ver</div>
    </th>  
    <th>
        <div>Descargar</div>
    </th>  
</tr>
</thead>
