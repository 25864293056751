<script lang="ts">   
    
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import {onMount} from "svelte";
    import WipItem from "./WipItem.svelte";
    import WipItemXLS from "./WipItemXLS.svelte";
    import { TableExport } from "tableexport";

    export let data;        
    export let modulo;     
    export let verGuardar = true;     
    
    let total_dias = 0, total_emp =0,  total_fal= 0;
    let wips = []

    onMount(() => { 
        wips = data    
        sumar()            
    })


    function sumar()
    {

        total_dias = 0
        total_emp =0  
        total_fal= 0

        wips.forEach((x) => {
        total_emp += x.cantidad_confeccionada
        })

        wips.forEach((x) => {
        total_fal += x.cantidad_restante
        })
    
        wips.forEach((x) => {
            //total_dias += (((x.cantidad_restante - x.cantidad_confeccionada)  * x.sam * x.peficiencia) /  (x.personas * x.jornada * 100))
            total_dias += x.dias
        })

        total_dias = total_dias.toFixed(2)
    }

    let resp

    $: sumar();

    async function guardar() {
        
            sumar();           
            const payload = wips
            
            const response = await fetch('/wip/guardar_wips', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)
            });
            resp = await response.json() 
            if (response.ok){               
               
                location.reload();
          
            }        
    }

    function excel() {
    let id = "tabla_" + modulo.codigo;

    let tabla = document.querySelector('#tabla_' + modulo.codigo); //document.getElementById('#tabla_' + modulo);
    let tableExport = new TableExport(tabla, {
        exportButtons: false, // No queremos botones
        filename: "Wip_" + modulo.codigo, //Nombre del archivo de Excel
        sheetname: modulo.codigo, //Título de la hoja
    });
    //console.log(tableExport)
    let datos = tableExport.getExportData();
    //console.log(datos)
    let preferenciasDocumento = datos[id].xlsx;
    tableExport.export2file(preferenciasDocumento.data, preferenciasDocumento.mimeType, preferenciasDocumento.filename, preferenciasDocumento.fileExtension, preferenciasDocumento.merges, preferenciasDocumento.RTL, preferenciasDocumento.sheetname);
}
      
</script>
<table class="naui-table w-100" id="tabla_{modulo.codigo}" >
        
    <thead>
        <tr>        
            <th >
                Módulo
            </th>          
            <th  >
                {`${modulo.codigo} ${modulo.nombreVendor}`}
            </th> 
            {#if verGuardar }    
                <td >   <div><NauiButton caption="Guardar"  mode="outline" color="accent" on:click={guardar} /> </div>                  
                </td>  
            {:else}  
                <td colspan="3">
                    <NauiButton caption="Descargar Excel"  mode="outline" color="accent" on:click={excel}/>                
                </td>
            {/if} 
            
        </tr>
        <tr>
            <th >
                Recipiente
            </th>                
            <th >
                Código Referencia Prod.
            </th>
            <th >
                Referencia Prod.
            </th>            
            <th>
                Talla Prod.
            </th>
            <th >
                Color Prod.
            </th>
            <th >
                Código Referencia Venta
            </th>
            <th >
                Referencia Venta
            </th>            
            <th>
                Talla Ven.
            </th>
            <th >
                Color Venta
            </th>
            <th >
                Cant.
            </th>
            <th >
                Cant. Falt.
            </th>
            <th style="width: 80px">
                SAM
            </th>           
            <th style="width: 70px">
                Pers.
            </th>
            <th >
                % Eficienc.
            </th>
            <th style="width: 80px" >
                Jornada
            </th>
            <th >
                Min. Disp.
            </th>
            <th >
                Min. Nec.
            </th>
            <th >
                Días
            </th>
            <th >
                Módulo
            </th>
            <th >
                Prior.
            </th>
            <th >
                Cantidad Empaque
            </th>
            <th >
                Est. Referencia
            </th>
        </tr>
    </thead>
    <tbody>
    {#each wips as item (item.id)}
        {#if verGuardar }   
            <WipItem bind:data={item}  /> 
        {:else}    
            <WipItemXLS bind:data={item}  /> 
        {/if}    
    {/each}
    <tr>   
        <td colspan="9" >
            
        </td>
        <th >
            Total
        </th>
        <td >
            {total_fal} 
        </td>              
        <td colspan="5" >            
        </td>        
        <th >
            Total
        </th>            
        <td >
            {total_dias} 
        </td>   
        <td >            
        </td>
        <th >
            Total
        </th>       
        <td >            
            {total_emp} 
        </td>                      
    </tr>

   </tbody>
</table>
