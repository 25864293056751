<script lang="ts">
    import Dialog, { Actions, Content, Title } from "@smui/dialog";
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import { fade } from "svelte/transition";

    let warning = {
        open: false,
        title: "Oops!",
        message: "",
        icon: "",
        hideActions: false,
    };

    let showPassword = false;
    let loader = false;

    const data = {
        username: "",
        password: "",
        token: "",
    };

    const login = async () => {
        loader = true;
        await window.resetCaptcha();
        try {
            data.token = sessionStorage.getItem("captchaToken");
            const response = await fetch("/auth/login", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                location.reload();
            } else {
                data.password = '';
                loader = false;
                if (response.status === 500) {
                    warning.title = "ERROR!";
                    warning.open = true;
                    warning.message = "Vuelve a intentarlo más tarde";
                    warning.icon = "";
                } else {
                    warning.open = true;
                    warning.message = "Verifica tu usuario y contraseña";
                    warning.icon = "";
                }
            }
        } catch (e) {
            loader = false;
            warning.title = "ERROR!";
            warning.open = true;
            warning.message = "Vuelve a intentarlo más tarde";
            warning.icon = "";
            console.error(e);
        }
    };

</script>

<div class="login-background edges-0 fixed box">
    <div class="back-white radius p-2  min-w-400px max-w-400px blur-shadow">
        <div class="box mb-15">
            <img src="/static/front/pix/logo.jpg" alt="" />
        </div>
        <form id="loginFormulario" on:submit|preventDefault={() => login()}>
            <div class="mb-1">
                <input
                    class="naui-input w-100 text-c"
                    type="text"
                    placeholder="Usuario"
                    required
                    bind:value={data.username}
                />
            </div>
            <div class="mb-1 passwordInput">
                {#if showPassword}
                    <input
                        type="text"
                        class="naui-input w-100 text-c"
                        placeholder="Contraseña"
                        required
                        bind:value={data.password}
                    />
                    
                {:else}
                    <input
                        type="password"
                        class="naui-input w-100 text-c"
                        placeholder="Contraseña"
                        required
                        bind:value={data.password}
                    />
                {/if}
                <span
                    on:click={() => (showPassword = !showPassword)} 
                    class="material-symbols-outlined position-absolute r-5">
                        {#if showPassword}
                            visibility_off
                        {:else}
                            visibility
                        {/if}
                </span>
            </div>
            <div class="mb-1">
                <button
                    disabled={loader}
                    class="btn solid accent w-100 box sx-ii"
                    type="submit"
                >
                    {#if loader}
                        <div class="loader" transition:fade />
                    {:else}
                        <span transition:fade class="material-icons-outlined accent">login</span
                        >
                        <span transition:fade class="white">INGRESAR</span>
                    {/if}
                </button>
            </div>
        </form>
        <!--<div class="box">
            <div class="btn w-100 mt-1">
                RECUPERAR CONTRASEÑA
            </div>
        </div>-->
    </div>
</div>

<Dialog bind:open={warning.open}>
    <Title>
        <div class="box sx-ii py-ii">
            {#if warning.icon}
                <span class="material-icons accent">{warning.icon}</span>
            {/if}
            <h3>{warning.title}</h3>
        </div>
    </Title>
    <Content>
        <div class="max-w-400px text-c py-ii" tabindex="0">
            <p class="large">{@html warning.message}</p>
        </div>
    </Content>
    <Actions>
        {#if !warning.hideActions}
            <NauiButton
                caption="ACEPTAR"
                color="accent"
                on:click={() => {
                    warning.open = false;
                }}
            />
        {/if}
    </Actions>
</Dialog>

<style>
    .loader {
        color: white;
        font-size: 18px;
        text-indent: -9999em;
        overflow: hidden;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        position: relative;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
        animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    }
    @-webkit-keyframes load6 {
        0% {
            box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
                0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
        }
        5%,
        95% {
            box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
                0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
        }
        10%,
        59% {
            box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
                -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
                -0.297em -0.775em 0 -0.477em;
        }
        20% {
            box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
                -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
                -0.749em -0.34em 0 -0.477em;
        }
        38% {
            box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
                -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
                -0.82em -0.09em 0 -0.477em;
        }
        100% {
            box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
                0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
        }
    }
    @keyframes load6 {
        0% {
            box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
                0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
        }
        5%,
        95% {
            box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
                0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
        }
        10%,
        59% {
            box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
                -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
                -0.297em -0.775em 0 -0.477em;
        }
        20% {
            box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
                -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
                -0.749em -0.34em 0 -0.477em;
        }
        38% {
            box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
                -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
                -0.82em -0.09em 0 -0.477em;
        }
        100% {
            box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
                0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
        }
    }
    @-webkit-keyframes round {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes round {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .passwordInput {
        position: relative;
    }

    .passwordInput span {
        cursor: pointer;
        position: absolute;
        right: 5px;
        top:8px;
        z-index: 2;
    }
</style>
