<script lang="ts">
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";    
    import ASNItem from "./components/ASNItem.svelte";
    import NauiInput from "../lib/naui/atoms/NauiInput.svelte"; 
    import ASNHead from "./components/ASNHead.svelte";    
    import {onMount} from 'svelte';
   
    import NauiDateRange from "../lib/naui/atoms/NauiDateRange.svelte";
    import { vendor } from "../stores/sessionVendor";
    import Dialog from "@smui/dialog/src/Dialog.svelte";
    import { Actions, Content, Title } from "@smui/dialog";

    
    


    let recipiente = ''
    let referencia = ''

    onMount(() => {
        loadData();  
        setTimeout(() => {
            document.getElementById("recipiente").focus();
            document.getElementById("referencia").focus();     
            }, 500);
        
    });
    
    function loadData() {
        recipiente = ''
        referencia = ''
        
    }

   let asns = []
   let resp
   let buscando= false

   let alert = {
    title:'',
    icon :"",
    message:'',
    open:false
   }

    async function fetchFacturas() {
        if (rango  != '' ) { //&& vendor_sel != '0'
            buscando= true
            asns = []

            //asns.push({id: 'Buscando...', date: '.'  , cajas: '.' })
            const payload = {"rango" : rango, "recipiente": recipiente.trim(), "referencia": referencia.trim()}            
            
            const response = await fetch('/maquila/buscar_asn', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)
                
            });
            resp = await response.json();
            if (response.ok){   
                buscando = false         
               asns = resp['data']
            }                             

        } else {
            alert.open = true;
            alert.icon = 'warning'    
            alert.title='Debe Ingresar un rango de fechas'
            setTimeout(() => {
                alert.open = false
            }, 6000)
        }
    }

    let rango = ''  
    
    

</script>
<section>
    <table class="naui-table w-70" >
        <tr>
            <td >
             <NauiDateRange label="Buscar ASN(s) entre"  bind:value={rango}  /> 
            </td>
            <td width="150px" >
                <NauiInput id="recipiente" bind:value={recipiente} 
                           label="RECIPIENTE"/>
            </td>
            <td width="150px" >
                <NauiInput id="referencia" bind:value={referencia} 
                           label="REFERENCIA"/>
            </td>         
            <td >
                <NauiButton caption="Buscar ASN(s)"  mode="outline" color="accent" on:click={fetchFacturas}/>                          
            </td>
        </tr>
    </table>   
        <table class="naui-table w-100"  id="asn">
            <ASNHead />
            <tbody>
                {#each asns as item (item.id)}
                    <ASNItem data="{item}"  />   
                {:else}

                    {#if !buscando }
                    <tr>
                        <td colspan="4">
                            <div><p>No se encontraron registros</p></div>
                        </td>
                    </tr>      
                    {:else}           
                    <tr>
                        <td colspan="4">
                            <div><p>Buscando...</p></div>
                        </td>
                    </tr>                                       
                    {/if}
                {/each}
            </tbody>    
        </table>
</section>

<Dialog bind:open={alert.open}>
    <Title>
        <div class="box sx-ii">
            <span class="material-icons accent">{alert.icon}</span>
            <h3 class="large">
                {
                    alert.title
                }
            </h3>
        </div>
    </Title>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton caption="Aceptar" color="accent" on:click={() => {alert.open = false;}}/>
        </div>
    </Actions>
</Dialog>

<style>
/*    .search {
        border-radius: 2rem;
        padding-left: 2.5rem;
    }

    .search + span {
        position: absolute;
        left: .75rem;
        top: .75rem;
        color: var(--secondary);
    }

    .naui-filter {
        min-width: 150px;
        color: var(--secondary);
        opacity: 1;
    }*/

</style>