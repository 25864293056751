<script>
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";

    export let warningBox = null;
    export let warningPallet = null;
    export let checked = false;
</script>

<thead>
<tr>
    <th>
        <div>
            <NauiCheckbox bind:value={checked} on:input/>
        </div>
    </th>
    <th>
        <div>Referencia</div>
    </th>
    <th>
        <div>Descripción</div>
    </th>
    <th>
        <div>Talla</div>
    </th>
    <th>
        <div>Color</div>
    </th>
    <th>
        <div>EAN</div>
    </th>
    <th>
        <div>
            {#if warningBox}
                <span class="material-icons accent" title={warningBox}>warning</span>
            {/if}
            Caja No.
        </div>
    </th>
    <th>
        <div>
            {#if warningPallet}
                <span class="material-icons accent" title={warningPallet}>warning</span>
            {/if}
            Estiba
        </div>
    </th>
    <th>
        <div>Cant x Bolsa</div>
    </th>
    <th>
        <div >Uni.<br>
             Empacadas</div>
    </th>
    <th>
        <div class="box">QA</div>
    </th>

    <th>
        <div class="box">Tipo prenda</div>
    </th>
    <th>
        <div class="box">Estado Caja</div>
    </th>
    <th>
        <div class="box">Adhesivo</div>
    </th>
</tr>
</thead>
