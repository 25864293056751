<script lang="ts">
    type stepType = 'select' | 'scan';
    export let step: stepType = 'select';
</script>

<div class="back-gray p-1 box-l w-max-content">
    <div style="width: 150px" class="text-c first-step">
        <div class="box relative">
            {#if step === 'select'}
                <span class="material-icons-outlined secondary">radio_button_checked</span>
            {:else if step === 'scan'}
                <a href="/maquila/seleccion">
                    <span class="material-icons-outlined secondary">check_circle</span>
                </a>
            {/if}
            <span class="line-through"></span>
        </div>

        {#if step === 'scan'}
            <small><a style="display:block" href="/maquila/seleccion">Seleccionar registros</a></small>
        {:else}
            <small>Seleccionar registros</small>
        {/if}

    </div>

    <div style="width: 150px" class="text-c">
        <div class="box">
            {#if step === 'select'}
                <span class="material-icons-outlined" style="color: #ccc">radio_button_unchecked</span>
            {:else if step === 'scan'}
                <span class="material-icons-outlined secondary">radio_button_checked</span>
            {/if}
        </div>
        <small>Escanear</small>
    </div>
</div>
<style>
    .first-step .line-through {
        height: 2px;
        background: #ccc;
        width: 130px;
        position: absolute;
        right: -65px;
    }
</style>

