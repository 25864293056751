<script lang="ts">
    import {onMount} from "svelte";

    export let label;
    export let value;
    export let isDisabled = false;
    export let id = null;
    export let step = '1';
    export let required = false;
    export let focus = false;
    export let autofocus = false;

    $: listId = 'list' + id;
    export let list: Array<string> = [];

    let fill = false;
    let input;

    onMount(() => {
        setStatus();
    });

    function setStatus() {
        fill = value !== '' && value !== undefined;
        focus = false;
    }
</script>
<div class="naui-field" class:active={fill}>
    <div class="naui-field-back" class:fill={fill}>
        <label class:{focus}>{label}</label>
        <input autofocus={autofocus} disabled={isDisabled} required={required} type="text" id="{id}" bind:value={value} list="{listId}" class="naui-input min-w-200px"
               on:input
               on:change
               on:keypress
               on:focus={()=> fill=true}
               on:focusin={()=>focus=false}
        />
    </div>
</div>
<datalist id="{listId}">
    {#each list as item}
        <option value="{item}">
    {/each}
</datalist>
<style>
    .naui-field-back.fill {
        background: white;
    }
</style>


