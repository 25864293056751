<script>
  export let paros;
  export let id;
  export let display = "block";
</script>

<table class="naui-table w-100" {id} style="display: {display};">
  <thead>
    <tr>
      <th>Proveedor</th>
      <th>Solicitud</th>
      <th>Recipiente</th>
      <th>Orden de Corte</th>
      <th
        >Referencia<br />
        Prod</th
      >
      <th>Talla</th>
      <th>Color</th>
      <th>Ref Venta</th>
      <th>Código Insumo</th>
      <th>Descripción Insumo</th>
      <th>Consumo prenda</th>
      <th>Unidad Medida</th>
      <th>Motivo</th>
      <th>Cantidad faltante</th>
      <th>Fecha Solicitud</th>
      <th>Hora Solicitud</th>
      <th>Fecha Actualizacion Estado</th>
      <th>Estado</th>
      <th>Fecha respuesta</th>
      <th>Salida Bodega #</th>
      <th>Observaciones</th>
    </tr>
  </thead>
  <tbody>
    {#each paros as paro}
      <tr style="text-align: center;">
        <td><div>{paro.vendor_name}</div></td>
        <td><div>{paro.id}</div></td>
        <td><div>{paro.recipiente_codigo_recipiente}</div></td>
        <td><div>{paro.recipiente_orden_corte === "" ? "Sin información" : paro.recipiente_orden_corte}</div></td>
        <td
          ><div>
            {paro.recipiente_codigo_referencia_produccion}
          </div></td
        >
        <td><div>{paro.recipiente_talla}</div></td>
        <td><div>{paro.recipiente_color}</div></td>
        <td><div>{paro.recipiente_codigo_referencia_venta}</div></td>
        <td><div>{paro.insumo_codigo_insumo}</div></td>
        <td><div>{paro.insumo_descripcion}</div></td>
        <td><div>{paro.recipiente_insumo_cantidad}</div></td>
        <td><div>{paro.insumo_uni_medida}</div></td>
        <td><div>{paro.motivo}</div></td>
        <td><div>{paro.cantidad == "" ? "" : paro.cantidad}</div></td>
        <td><div>{paro.fecha_sol}</div></td>
        <td><div>{paro.hora_sol}</div></td>
        <td><div>{paro.fecha_actualizacion_estado == null ? "Sin información" : paro.fecha_actualizacion_estado }</div></td>
        <td><div>{paro.estado}</div></td>
        <td
          ><div>
            {paro.fecha_resp == null ? "" : paro.fecha_resp}
          </div></td
        >
        <td
          ><div>
            {paro.bodega == "" ? "" : paro.bodega}
          </div></td
        >
        <td
          ><div>
            {paro.observacion == "" ? "" : paro.observacion}
          </div></td
        >
      </tr>
    {/each}
  </tbody>
</table>
