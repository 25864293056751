<script>
  import NauiInput from "../lib/naui/atoms/NauiInput.svelte";
  import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
  import { onMount } from "svelte";
  import Dialog, { Header, Title, Content, Actions } from "@smui/dialog";
  import NauiSelect from "../lib/naui/atoms/NauiSelect.svelte";
  import InputAutocomplete from "./components/InputAutocomplete.svelte";
  import { TableExport } from "tableexport";
  import TableParoInsumoXls from "./components/TableParoInsumoXLS.svelte";
  import TableParoParteXls from "./components/TableParoParteXLS.svelte";
  import InsumoItem from "./components/InsumoItem.svelte";
  import ParteItem from "./components/ParteItem.svelte";
  import NauiCheckbox from "../lib/naui/atoms/NauiCheckbox.svelte";

  export let vendors;
  export let grupos;
  export let nombreVendor;

  let globalCheck = false;

  let admin = true;
  let bloquear = false;

  let proveedor;
  let estado = "-1";
  let tipo = "-1";
  let codigo_insumo = "";
  let ordenCorte = "";
  let respuesta;

  let mensaje = "";
  let dialogOpenOK = false;
  let desactivar = false;
  let desactivarGuardar = true;

  let paros = [];

  let asignarValoresChecked = [];

  let estados = [
    { value: "*", label: "Todos" },
    { value: "Solicitado", label: "Solicitado" },
    { value: "En proceso", label: "En proceso" },
    { value: "Despachado", label: "Despachado" },
    { value: "Solicitud muestra", label: "Solicitud de muestra" },
    { value: "Envio muestra", label: "Envío de muestra" },
  ];

  let estadosModal = [
    { value: "Solicitado", label: "Solicitado" },
    { value: "En proceso", label: "En proceso" },
    { value: "Despachado", label: "Despachado" },
    { value: "Solicitud muestra", label: "Solicitud muestra" },
    { value: "Envio muestra", label: "Envío de muestra" },
  ];

  let estadosModalVendor = [
    { value: "Solicitado", label: "Solicitado" },
    { value: "En proceso", label: "En proceso" },
    { value: "Despachado", label: "Despachado" },
    { value: "Recibido", label: "Recibido" },
    { value: "Solicitud muestra", label: "Solicitud muestra" },
    { value: "Envio muestra", label: "Envío de muestra" },
  ];

  let tipos = [
    { value: "I", label: "Insumos" },
    { value: "P", label: "Partes" },
  ];

  let cambios = []

  let vendorArray = ["Todos"];
  let tipoTabla;

  let modalParoInsumo = false;
  let modalParoParte = false;

  let vendor = [];


  let paroInformacion = [];
  let informacionItemParo = [];
  let dataInsumosItems = [];
  let dataPartesItems = [];

  function loadData() {
          informacionItemParo  = paroInformacion.map((x) => {
            return {...x, checked: false}
        });
  }

  onMount(() => {
    loadData()
    if (vendors) {
      console.log(vendors);
      vendors.forEach((item) => {
        vendorArray.push(item.name);
      });
    }
    vendorArray = vendorArray;

    admin =
      grupos.toUpperCase().includes("SOURCING") ||
      grupos.toUpperCase().includes("ADMINISTRADOR");

    if (!admin) {
      proveedor = nombreVendor;
      bloquear = true;
    }
  });

  function obtenerParo(id){
      const paro = paros.find((x) => x.id == id);
      return paro;
  }

  function actualizarInformacionParo() {
    if(globalCheck){
      informacionItemParo.forEach((x) =>{
        if(x.checked){
          let paro = obtenerParo(x.id);
          x.observacion = paro.observacion === '' ? '' : paro.observacion;
          x.bodega = paro.bodega === '' ? '' : paro.bodega;
          x.estado = paro.estado
        }
      })
    }
  }

  function globalCheckChange(check, item) {
      informacionItemParo.forEach((x) => {
          x.checked = check;
      });
      informacionItemParo = informacionItemParo;
  }

  function cambioFiltros(pVendor, pEstado,pCod_insumo)
  {
    globalCheck = false;
  }

  $: globalCheckChange(globalCheck);
  $: cambioFiltros(vendor.id, estado,codigo_insumo);

  function buscarParos() {

    if (proveedor == null) {
      mensaje = "Debe diligenciar el campo PROVEEDOR en la busqueda";
      dialogOpenOK = true;
      return;
    }
    if (estado == "-1") {
      mensaje = "Debe diligenciar el campo ESTADO en la busqueda";
      dialogOpenOK = true;
      return;
    }
    if (tipo == "-1") {
      mensaje = "Debe diligenciar el campo TIPO en la busqueda";
      dialogOpenOK = true;
      return;
    }

    vendor = vendors.find((x) => x.name == proveedor);

    if (!vendor) {
      vendor = {
        id: "*",
      };
    }

    informacionItemParo = []

    if (tipo == "I") {
      getParosInsumos(vendor.id, estado,codigo_insumo,ordenCorte);
    } else {
      getParosPartes(vendor.id, estado,codigo_insumo,ordenCorte);
    }

    tipoTabla = tipo;
  }

  async function getParosInsumos(pVendor, pEstado,pCod_insumo,pordenCorte) {
    desactivarGuardar = true;
    desactivar = true;
    const payload = {
      proveedor: pVendor,
      estado: pEstado,
      codigo: pCod_insumo,
      ordenCorte: pordenCorte
    };
    const response = await fetch("/paro/paros-insumos-proveedor-estado", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    respuesta = await response.json();
    if (response.ok) {
      paros = respuesta.data;
      paros = paros;
      dataInsumosItems = paros;
      paroInformacion = paros.map((x) => {
            return {...x, checked: false }
        });;
      paroInformacion = paroInformacion;
      informacionItemParo = paroInformacion;
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
    desactivar = false;
    desactivarGuardar = false;
    globalCheck = false;  
  }

  function obtenerID(target){
    let id ="";
    if(target)
    {
      let paro = target.id
      id = paro.split('-')[1]; 
    }
    return id
  }

  //Funcion cuando le da control + enter
  const onKeyPress = async ({ detail }) => {
    const e = detail.event;
      if(e && e.charCode === 10 )
      {
        let id = obtenerID(e.target);
        let valorAsignar = informacionItemParo.find((p) => p.checked === true && p.id === parseInt(id));
        if(valorAsignar)
        {
          if(e.ctrlKey)
          {
            informacionItemParo.map(x =>{
                                      if(x.checked && x.id !== id)
                                      {
                                        x.observacion = valorAsignar.observacion;
                                        x.bodega = valorAsignar.bodega;
                                        x.estado = valorAsignar.estado;
                                      }
                                  });
            informacionItemParo = informacionItemParo;
            informacionItemParo = informacionItemParo;
            }
                     
        }
      }
    };

  async function getParosPartes(pVendor, pEstado,pCod_insumo,pordenCorte) {
    desactivarGuardar = true;
    desactivar = true;
    const payload = {
      proveedor: pVendor,
      estado: pEstado,
      codigo: pCod_insumo,
      ordenCorte: pordenCorte
    };
    const response = await fetch("/paro/paros-partes-proveedor-estado", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    respuesta = await response.json();
    if (response.ok) {
      paros = respuesta.data;
      paros = paros;
      dataPartesItems = paros;
      paroInformacion = paros.map((x) => {
            return {...x, checked: false }
        });;
      paroInformacion = paroInformacion;
      informacionItemParo = paroInformacion;
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
    desactivar = false;
    desactivarGuardar = false;
    globalCheck = false;
  }

  function obtenerParosActualizar(informacionParos)
  {
    const parosActulizar = informacionParos.filter((p) => p.checked === true);
    return parosActulizar;
  }

  function ValidarEstadoParos(listaParos, rol){
    const parosNoValidos = listaParos.filter(
      (p) => p.estado === "Despachado" || 
      p.estado === "Solicitud muestra" ||
      p.estado == "Solicitado");
    
    if(rol)
    {
      return false;
    }
    else
    {
      if(parosNoValidos.length > 0 ){
        return true;  
      }
      else
      {
        return false;
      }
    }
  }

  function validarInformacionParos(listaParos){
    const parosIncompletos = listaParos.filter((p) => p.estado === "Despachado" && p.bodega === "");
    if(parosIncompletos.length > 0){
      return true;
    }
    else{
      return false;      
    }
  }

  function guardarParos(){
    const listaParosActualizar = obtenerParosActualizar(informacionItemParo);
    if(listaParosActualizar.length > 0 )
    {
      if(ValidarEstadoParos(listaParosActualizar,admin) === false)
      {
        if(validarInformacionParos(listaParosActualizar) === false)
        {
          if (tipo == "I") {
            guardarParoInsumos(listaParosActualizar);
          } else {
            guardarParoPartes(listaParosActualizar);
          }
        }
        else {
          mensaje = "El campo 'Salida de Bodega #' es obligatorio para los items seleccionados que tienen el estado 'Despachado'. ";
          dialogOpenOK = true;
        }
      }
      else{
        mensaje = "El estado seleccionado no esta permitido para usuario tipo proveedor.";
        dialogOpenOK = true;       
      }
      
    }
    else
    {
      mensaje = " Debe seleccionar algun item a modificar ";
      dialogOpenOK = true;
    }
  }

  async function guardarParoInsumos(listaParoInsumos) {
    desactivar = true;
    desactivarGuardar = true;
    const vObj = listaParoInsumos;

    const response = await fetch("/paro/actualizar-paro-insumos", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(vObj),
    });
    respuesta = await response.json();
    desactivar = false;
    desactivarGuardar = false;
    if (response.ok) {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
      paros = [];
      getParosInsumos(vendor.id, estado,codigo_insumo,ordenCorte);  
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
  }

  async function guardarParoPartes(listaParoPartes) {
    desactivar = true;
    desactivarGuardar = true;
    const vObj = listaParoPartes;

    const response = await fetch("/paro/actualizar-paro-partes", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(vObj),
    });
    respuesta = await response.json();
    desactivar = false;
    desactivarGuardar = false;
    if (response.ok) {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
      paros = [];
      getParosPartes(vendor.id, estado,codigo_insumo,ordenCorte);
    } else {
      mensaje = respuesta.mensaje;
      dialogOpenOK = true;
    }
  }

  function excel() {
    let tabla = document.querySelector("#tabla");
    let tableExport = new TableExport(tabla, {
      exportButtons: false, // No queremos botones
      filename: "Reporte Paros", //Nombre del archivo de Excel
      sheetname: "Paros", //Título de la hoja
    });
    let datos = tableExport.getExportData();
    let preferenciasDocumento = datos.tabla.xlsx;
    tableExport.export2file(
      preferenciasDocumento.data,
      preferenciasDocumento.mimeType,
      preferenciasDocumento.filename,
      preferenciasDocumento.fileExtension,
      preferenciasDocumento.merges,
      preferenciasDocumento.RTL,
      preferenciasDocumento.sheetname
    );
  }



  function obtenerDataInicialInsumo(itemInsumo){
      const indexItemInsumo = dataInsumosItems.indexOf(itemInsumo);
      const prevStateInsumo = dataInsumosItems.find(i => i.id === itemInsumo.id);
      if (indexItemInsumo !== -1) {
        informacionItemParo[indexItemInsumo] = {
            ...prevStateInsumo,
            checked: false
        };
    }
    return prevStateInsumo
  }

  function obtenerDataInicialParte(itemParte){
      const indexItemParte = dataPartesItems.indexOf(itemParte);
      const prevStateParte = dataPartesItems.find(i => i.id === itemParte.id);
      if (indexItemParte !== -1) {
        informacionItemParo[indexItemParte] = {
            ...prevStateParte,
            checked: false
        };
    }
    return prevStateParte
  }

</script>

<section>
  <div class="box-b my-15">
    <div class="box-l w-100 sx-iii">
      {#if !bloquear}
        <div class="w-100">
          <InputAutocomplete
            id="cmbProveedor"
            bind:value={proveedor}
            list={vendorArray}
            label="PROVEEDOR"
            isDisabled={bloquear}
            focus={bloquear}
          />
        </div>
      {/if}
      <div class="w-50">
        <NauiSelect
          label="ESTADO"
          options={estados}
          id="cmbEstado"
          bind:value={estado}
        />
      </div>
      <div class="w-50">
        <NauiSelect
          label="TIPO"
          options={tipos}
          id="cmbTipos"
          bind:value={tipo}
        />
      </div>
      <div class="w-50" >
        <NauiInput
        id = "filtro_cod_insumo"
        label = "CODIGO SKU"
        type="text"
        bind:value={codigo_insumo}
        />
      </div>
      <div class="w-50" style="overflow: hidden;">
          <NauiInput
            id = "filtro_orden_corte"
            label = "ORDEN DE CORTE"
            type="text"
            bind:value={ordenCorte}
          />
      </div>
      <div class="w-100">
        <NauiButton
          caption="BUSCAR"
          disabled={desactivar}
          mode="solid"
          color="accent"
          className="mt-ii"
          on:click={buscarParos}
        />
      </div>
    </div>
    <div class="box-r sx-iii">
      <div class="w-100">
        <NauiButton
          caption="Guardar Paros"
          mode="solid"
          color="accent"
          className="mt-ii"
          disabled={desactivarGuardar}
          on:click={guardarParos}
        />
      </div>
      <div class="w-100">
        <NauiButton
          caption="DESCARGAR EXCEL"
          mode="solid"
          color="secondary"
          on:click={excel}
        />
      </div>
      <NauiButton
        caption="SALIR"
        mode="outline"
        color="accent"
        on:click={() => {
          history.back();
        }}
      />
    </div>
  </div>
  {#if paros.length > 0}
    <div class="m-1" style="overflow-y:scroll; height:75vh;">
      {#if tipoTabla == "I"}
        <h4>Insumos</h4>
        <div style="overflow-x: scroll; overflow-y:hidden;">
          <table class="naui-table w-100">
            <thead>
              <tr>
                <th><NauiCheckbox bind:value={globalCheck} on:click={actualizarInformacionParo}/></th>
                <th>#</th>
                <th>Proveedor</th>
                <th>Solicitud</th>
                <th>Recipiente</th>
                <th>Orden de Corte</th>
                <th>
                  Referencia <br />
                  Prod
                </th>
                <th>Talla</th>
                <th>Código Insumo</th>
                <th>Descripción Insumo</th>
                <th>Consumo prenda</th>
                <th>Unidad Medida</th>
                <th>Motivo</th>
                <th>Cantidad faltante</th>
                <th>Fecha Solicitud</th>
                <th>Fecha respuesta</th>
                <th>Fecha actualización estado</th>
                <th>Estado</th>
                <th>Salida Bodega #</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              {#each informacionItemParo as paroI, i (paroI.id)}
                <InsumoItem 
                  class="item"
                  dataInsumo={paroI}
                  index ="{i+1}"
                  estadosModal = "{estadosModal}" estadosModalVendor = "{estadosModalVendor}"
                  admin = "{admin}"
                  bind:checked={paroI.checked}
                  on:onkeypress={onKeyPress}
                  dataInicialInsumo = {obtenerDataInicialInsumo(paroI)}
                />
              {/each}
            </tbody>
          </table>
          <TableParoInsumoXls {paros} id="tabla" display="none" />
        </div>
      {:else}
        <h4>Partes</h4>
        <div style="overflow-x: scroll; overflow-y:hidden;">
          <table class="naui-table w-100">
            <thead>
              <tr>
                <th><NauiCheckbox bind:value={globalCheck} on:click={actualizarInformacionParo}/></th>
                <th>#</th>
                <th>Proveedor</th>
                <th>Solicitud</th>
                <th>Recipiente</th>
                <th>Orden de Corte</th>
                <th>Referencia<br /> Prod</th>
                <th>Talla</th>
                <th>Código Parte</th>
                <th>Descripción Parte</th>
                <th>Consumo prenda</th>
                <th>Motivo</th>
                <th>Cantidad faltante</th>
                <th>Fecha Solicitud</th>
                <th>Fecha respuesta</th>
                <th>Fecha actualización estado</th>
                <th>Estado</th>
                <th>Salida Bodega #</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              {#each informacionItemParo as paroP, i (paroP.id)}
                  <ParteItem
                    class="item"
                    dataParte={paroP} 
                    indice ="{i+1}"
                    estadosModal = "{estadosModal}"
                    estadosModalVendor = "{estadosModalVendor}"
                    admin = "{admin}"  
                    bind:checked={paroP.checked}
                    on:onkeypress={onKeyPress}
                    dataInicialParte = {obtenerDataInicialParte(paroP)}
                />
              {/each}
            </tbody>
          </table>
          <TableParoParteXls {paros} id="tabla" display="none" />
        </div>
      {/if}
    </div>
  {/if}

  <Dialog bind:open={dialogOpenOK}>
    <Title>
      <div class="box sx-ii py-ii">
        <span class="material-icons accent">warning</span>
        <h3>Paros</h3>
      </div>
    </Title>
    <Content>
      <div class="max-w-400px text-c mx-auto px-15">
        <p class="large">
          {mensaje}
        </p>
      </div>
    </Content>
    <Actions>
      <div class="box-r sx-ii px-1 py-ii">
        <NauiButton
          caption="Aceptar"
          color="accent"
          on:click={() => {
            dialogOpenOK = false;
          }}
        />
      </div>
    </Actions>
  </Dialog>
</section>

<!-- <svelte:window on:keypress={onKeyPress}/> -->

<style>
  .div-Fechas{
    width: max-content;
  }
  tbody :global(.item){
    text-wrap:nowrap;
    overflow-x:hidden;
    max-width:250px;
    text-overflow:ellipsis;
  }
</style>