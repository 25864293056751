<script lang="ts">
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import {onMount} from "svelte";
    import { validate_store } from "svelte/internal";

    import { formats } from "flatpickr/dist/utils/formatting";


    export let data;   
   
    let warning = {
        mensaje:"",
        dialogOpen:false
    }

    async function descargar(){
        const asnText = await fetch(`/maquila/descargar_asn_file_txt/${data.id}`)
        const info = await asnText.text();    
        if(info){
            const text = new Blob([info], {type: "text/plain:charset=UTF-8"});
            const url = window.URL.createObjectURL(text)
            let a = document.createElement('a')
            a.href = url;
            a.download = `ASN ${data.id} ${data.date}.txt`
            document.body.appendChild(a);
            a.click()
            a.remove()
        }else{
            warning.mensaje = "No se pudo descargar el archivo";
            warning.dialogOpen = true;
        }
    }
    
    let viendo = false

    onMount(() => {         
     
       
    })
   
    
</script>
<tr>    
    <form method="get" target="_blank">
        <input type="hidden" name="id" value="{data.id}">
        <input type="hidden" name="fecha" value="{data.date}">
     </form>
    <td>
        <div>{data.id}</div>
    </td>
    <td>
        <div>{data.date}</div>
    </td>   
    <td>
        <div>{data.cajas}</div>
    </td>      
    <td>
        <NauiButton icon="file_download" mode="outline"   color="accent" iconStyle="outlined" on:click={descargar} />        
    </td>       
</tr>
