<script lang="ts">
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";    
    import FacItem from "./components/FactItem.svelte";
    import FacHead from "./components/FactHead.svelte";    
    import {onMount} from 'svelte';
    import NauiSelect from "../lib/naui/atoms/NauiSelect.svelte";
    import NauiDateRange from "../lib/naui/atoms/NauiDateRange.svelte";
    import { vendor } from "../stores/sessionVendor";

    
    
    export let data = [];
    export let grupos;
    let buscando = false
    let puede_ver_vendors = true
    let localData = [];

    let vendor_sel = '0'

    onMount(() => {
        puede_ver_vendors = grupos.toUpperCase().includes('SOURCING') || grupos.toUpperCase().includes('ADMINISTRADOR')
        if(!puede_ver_vendors)
            vendor_sel = '-1'
        loadData();        
    });

    let vendors = []
    let vendors1 = []
    function loadData() {
        localData = data.map((x) => {
            return {...x, checked: false}
        });

        vendors.push({label: 'Todos',
            value: '0'}  )

        
        vendors1 = localData.map((y) => {
        return {
            label: y.name,
            value: y.id
        }})
        vendors = vendors.concat(vendors1)       
    }

   // $: facturas = facturasR
   let facturas = []
   let facturasFull = []
   
   

    async function fetchFacturas() {
        if (rango  != '' ) { //&& vendor_sel != '0'
            buscando = true
            facturasFull = []
            const payload = {"rango" : rango, "vendor": vendor_sel}            
            
            const response = await fetch('/facturacion/buscar_facturas', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)
                
            });
            facturas = await response.json();
            if (response.ok){            
                buscando = false
                facturasFull = facturas['data'].map((x) => {
                        return {...x, total:totalXfac(x.empaques['data'])}
                });                
              
                facturasFull = facturasFull.map((x) => {
                        return {...x, emp: x.empaques['data']}
                });    
            }                             

        } else {
            alert('Debe Ingresar un rango de fechas y seleccionar un Vendor')
        }
    }

    function totalXfac(empaques)
    {
        let Totalf  = 0
            empaques.forEach((x) => {
                Totalf += x.cantidad * x.valor
            }       
           )
        return Totalf   

    }

    let rango = ''
    


</script>
<section>
    <!--{vendor_sel}
    {rango} -->    

    <table class="naui-table w-70" >
        <tr>
            <td >
             <NauiDateRange label="Buscar Facturas entre"  bind:value={rango}  /> 
            </td>
            {#if puede_ver_vendors }
                <td width="250px" >
                    
                        <NauiSelect  bind:value={vendor_sel} options={vendors}  label="Vendor"  />
                    
            
                </td>         
            {/if}  

            <td >
                <NauiButton caption="Buscar Facturas"  mode="outline" color="accent" on:click={fetchFacturas}/>                          
            </td>
        </tr>
    </table>   

  
        <table class="naui-table w-100"  id="fact">
            <FacHead />
            <tbody>
            {#each facturasFull as item (item.id)}
                <FacItem data="{item}"  />  
            {:else}

                {#if !buscando }
                <tr>
                    <td colspan="8">
                        <div><p>No se encontraron registros</p></div>
                    </td>
                </tr>      
                {:else}           
                <tr>
                    <td colspan="8">
                        <div><p>Buscando...</p></div>
                    </td>
                </tr>                                       
                {/if}                          
            {/each}
        </tbody>    
        </table>
  

  
    
</section>
<style>
/*    .search {
        border-radius: 2rem;
        padding-left: 2.5rem;
    }

    .search + span {
        position: absolute;
        left: .75rem;
        top: .75rem;
        color: var(--secondary);
    }

    .naui-filter {
        min-width: 150px;
        color: var(--secondary);
        opacity: 1;
    }*/
</style>