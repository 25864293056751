<script lang="ts">
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import RecipienteItem from "./components/recepcion/RecipienteItem.svelte";
    import RecipienteTableHead from "./components/recepcion/RecipienteTableHead.svelte";
    import {onMount} from 'svelte';
    import NauiSlideOver from "../lib/naui/layout/NauiSlideOver.svelte";
    import NauiSelect from "../lib/naui/atoms/NauiSelect.svelte";
    import Dialog, {Actions, Content, Title} from "@smui/dialog";

    export let data = [];
    let globalCheck = false;
    let activeFilter = 'enviado';
    let searchKey: string = null;
    let open = false;
    let estadoOptions = [
        {
            label: "Enviado",
            value: 'enviado'
        },
        {
            label: "Recibido",
            value: 'recibido'
        },
        {
            label: "Rechazado",
            value: "rechazado"
        }
    ]
    let nuevoEstado = '';

    let localData = [];

    let dialogOpenOK = false;
    let mensaje = "";

    onMount(() => {
        loadData();
        searchKey = null;
    });

    function loadData() {
        localData = data.map((x) => {
            return {...x, checked: false}
        });
    }

    function search(key) {
        if (!!key) {
            loadData();
            localData = localData.filter((x) => (x.recipiente.includes(key)) || (x.remision.includes(key)))
        } else {
            loadData();
        }
    }

    function clear() {
        globalCheck = false;
        localData.forEach((x) => {
            x.checked = false;
        })
    }

    function globalCheckChange(check) {
        localData.forEach((x) => {
            if (!!filteredData) {
                if (filteredData.includes(x)) {
                    x.checked = check;
                }
            }
        });
        localData = localData;
    }

    $: search(searchKey);
    $: globalCheckChange(globalCheck);
    $: filteredData = localData.filter((x) => x.estado === activeFilter);
    $: checkedItems = filteredData.filter((x) => x.checked).map((y) => {
        return {
            id: y.id,
            recipiente: y.recipiente
        }
    })

    async function update() {
        if (nuevoEstado !== '') {
            const payload = checkedItems.map((x) => {
                return {...x, estado: nuevoEstado}
            })
            const response = await fetch('/maquila/actualizar-recipiente', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)
            });
            if (response.ok){
                open = false;
                location.reload();
            }

        } else {
            mensaje = "Debe seleccionar un nuevo estado";
            dialogOpenOK = true;
        }
    }


</script>
<section>
    <div class="box-l sx-1 mt-1 mb-15">
        <div class="relative">
            <input bind:value={searchKey} type="search" class="naui-input search min-w-400px"
                   placeholder="Buscar por No. de recipiente o remisión">
            <span class="absolute material-icons-outlined" style="color: #888;">search</span>
        </div>
        <NauiButton caption="BUSCAR" mode="outline" color="accent"/>
    </div>
    <div class="box-l sx-1 my-15">
        <NauiButton caption="CAMBIAR ESTADO" size="small" color="accent" mode="solid" on:click={()=> open = !open}/>
        <select class="naui-filter" on:change={clear} bind:value={activeFilter}>
            <option value="enviado">Enviado</option>
            <option value="recibido">Recibido</option>
        </select>
    </div>
    <table class="naui-table w-100">
        <RecipienteTableHead bind:checked={globalCheck}/>
        <tbody>
        {#each filteredData as item (item.id)}
            <RecipienteItem data="{item}" bind:checked={item.checked}/>
        {:else}
            <tr>
                <td colspan="11">
                    <div><p>No se encontraron registros</p></div>
                </td>
            </tr>
        {/each}
        </tbody>
    </table>
    <NauiSlideOver bind:open title="Cambiar estado">
        <div class="mb-1">
            <NauiSelect label="Nuevo estado" bind:value={nuevoEstado} options="{estadoOptions}"/>
        </div>
        <NauiButton caption="GUARDAR" mode="outline" color="accent" on:click={update}/>

        <div class="max-w-300px mt-15">
            <table class="naui-table w-100">
                <thead>
                <tr>
                    <th>
                        <div>Recipientes a actualizar</div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {#each checkedItems as item}
                    <tr>
                        <td><span class="block py-ii">{item.recipiente}</span></td>
                    </tr>
                {/each}
                </tbody>
            </table>
        </div>

    </NauiSlideOver>

    <Dialog bind:open={dialogOpenOK} >
        <Title>
            <h3 class="text-c">Producción</h3>
        </Title>
        <Content>
            <div class="max-w-400px text-c mx-auto px-15">
                <p class="large">
                   {mensaje}
                </p>
            </div>
        </Content>
        <Actions>
            <div class="box-r sx-ii px-1 py-ii">
                <NauiButton caption="Aceptar" color="accent" on:click={() => {dialogOpenOK = false;}}/>
            </div>
        </Actions>
    </Dialog>

</section>
<style>
    .search {
        border-radius: 2rem;
        padding-left: 2.5rem;
    }

    .search + span {
        position: absolute;
        left: .75rem;
        top: .75rem;
        color: var(--secondary);
    }

    .naui-filter {
        min-width: 150px;
        color: var(--secondary);
        opacity: 1;
    }
</style>