<script>
  export let data;
  export let defectos_auditables;

  console.log("informacion:",data)
  console.log("informacion2:",defectos_auditables)
</script>



<tr id='detalle_auditoria{data.id}' >      
  <td colspan="6">
      <table class="naui-table w-200" id='Defectos'>
          <thead>
              <tr>
                  <th>
                      <div>Tipo Aud.</div>
                  </th>
                  <th>
                      <div>Tipo Def.</div>
                  </th>
                  <th>
                      <div>Desc. Def.</div>
                  </th>  
                  <th>
                      <div>Unidades</div>
                  </th>        
              </tr>
          </thead>
          <tbody>
              {#each defectos_auditables as itemD}
                  <tr>
                    <td>
                        <div>{data.tipo_auditoria}</div>
                    </td>
                    <td>
                        <div>{itemD.defecto.tipo}</div>
                    </td>
                    
                    <td>
                        <div>{itemD.defecto.descripcion}</div>
                    </td>
                    <td>
                        <div>{itemD.cantidad}</div>
                    </td>    
                  </tr>
              {:else}
                  <tr>
                      <td colspan="6">
                          <div><p>No se encontraron registros</p></div>
                      </td>
                  </tr>
              {/each}
          </tbody>
      </table>
  </td>      
</tr>