<script lang="ts">
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiTag from "../../lib/naui/atoms/NauiTag.svelte";
    import {onMount} from "svelte";
    import { validate_store } from "svelte/internal";

    export let data;   
    let id=data.id;

    export let checked: boolean = false;
    

    onMount(() => {
        console.log(data);  

    })

    
</script>
<tr>
    <td>
        <div>
            <NauiCheckbox bind:value={checked}/>
        </div>
    </td>
    <td>
        <div>{data.id}</div>
    </td>
    <td>
        <div>{data.date}</div>
    </td>   
</tr>
