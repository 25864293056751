<script lang="ts">
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiTag from "../../lib/naui/atoms/NauiTag.svelte";
    import {onMount} from "svelte";
    import { validate_store } from "svelte/internal";

    export let data;   

       
    $: total = data.cantidad *  data.valoru //(isNaN(valoru)?0:valoru)
   
        

    onMount(() => {
        console.log(data);      
    })
    
    const formatterPeso = new Intl.NumberFormat('es-CO', {
       style: 'currency',
       currency: 'COP',
       minimumFractionDigits: 0
     })
    
</script>
<tr>
        <td>
        
    </td>
    <td>
        <div>{data.id}</div>
    </td>
    <td>
        <div>{data.codigo_referencia_venta}</div>
    </td>
    
    <td>
        <div>{data.descripcion_referencia_venta}</div>
    </td>
    <td>
        <div>{data.talla}</div>
    </td>
    <td>
        <div>{data.color}</div>
    </td>
    <td>
        <div>{data.cantidad}</div>
    </td>     
    <td>
        <div>{formatterPeso.format(data.valoru)}  </div>
    </td>  
    <td>
        <div>{formatterPeso.format(total)} </div>
    </td>   
</tr>
