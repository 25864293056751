<script lang="ts">    
    import NauiSelect from "../../lib/naui/atoms/NauiSelect.svelte";
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
    import {onMount} from "svelte";

    let estados_referencia = [{
            label: 'Programación    ',
            value: 'Programación'
        },
        {
            label: 'Confección',
            value: 'Confección'
        },
        {
            label: 'Empaque',
            value: 'Empaque'
        },
    ]
 
    export let data;        

    $: refrescar()

    function refrescar()
    {
        data = data
    }
    onMount(() => {
 
    })

    
</script>
        <tr>             
           
            <td >
                {data.recipiente} 
            </td>
            <td >
                {data.codigo_pro} 
            </td>
            <td >
                {data.desc_pro} 
            </td>              
            <td >
                {data.talla_pro} 
            </td>              
            <td >
                {data.color_pro} 
            </td>              
            <td >
                {data.codigo_ven} 
            </td>              
            <td >
                {data.desc_ven} 
            </td>              
            <td >
                {data.talla_ven} 
            </td>              
            <td >
                {data.color_ven} 
            </td>              
            <td >
                {data.cantidad} 
            </td>      
            <td >
                {data.cantidad_restante} 
            </td>     
            <td >
                <div >
                    <NauiInput bind:value={data.sam} type="number" label=""  />       
                </div>
            </td>                
            <td>
                <div>
                    <NauiInput bind:value={data.personas} type="number" label=""  />       
                </div>
            </td>          
            <td>
                <div>
                    <NauiInput bind:value={data.peficiencia} type="number" label=""  />       
                </div>
            </td>     
            <td>
                <div>
                    <NauiInput bind:value={data.jornada} type="number" label=""  />       
                </div>
            </td>
            <td >
                {data.min_dispo} 
            </td>       
            <td >
                {data.min_nece} 
            </td>       
            <td >
                {data.dias.toFixed(2)}
            </td>       
            <td>
                <div>
                    <NauiInput bind:value={data.modulo} type="text" label=""  />       
                </div>
            </td> 
            <td>
                <div>
                    <NauiInput bind:value={data.prioridad} type="number" label=""  />       
                </div>
            </td>    
            <td>
                <div>
                    <NauiInput bind:value={data.cantidad_confeccionada} type="number" label=""  />       
                </div>
            </td> 
            <td>
                <div>
                    <NauiSelect  bind:value={data.estado_referencia} options={estados_referencia}  label=""  />         
                </div>
            </td>                       
</tr>
