import Login from "./vendor/Login.svelte";
import Scanpack from "./scanpack/Scanpack.svelte";
import EnvioRecepcion from "./maquila/EnvioRecepcion.svelte";
import MaquilaSeleccion from "./maquila/MaquilaSeleccion.svelte";
import MaquilaScanpack from "./maquila/MaquilaScanpack.svelte";
import ASNDetail from "./scanpack/ASNDetail.svelte";
import PODetail from "./scanpack/PODetail.svelte";
import multitabla from "./multitabla/multitabla.svelte";
import facturacion from "./facturacion/facturacion_crear.svelte";
import facturacion_consultar from "./facturacion/facturacion_consultar.svelte";
import auditoria_pedir from "./auditoria/auditoria_pedir.svelte";
import auditoria_confirmar from "./auditoria/auditoria_confirmar.svelte";
import auditoria_auditar from "./auditoria/auditoria_auditar.svelte";
import ConsultarASN from "./maquila/ConsultarASN.svelte";
import carga_po from "./carga_po/carga_po.svelte";
import wip_ver from "./wip/wip_ver.svelte";
import wip_reportar from "./wip/wip_reportar.svelte";
import ParoConsultar from "./paro/ParoConsultar.svelte";
import ParoReportar from "./paro/ParoReportar.svelte";
import ParoSolucionar from "./paro/ParoSolucionar.svelte";
import CambiarContrasena from "./settings/CambiarContrasena.svelte";
export default {
    Login,
    Scanpack,
    ASNDetail,
    PODetail,
    EnvioRecepcion,
    MaquilaSeleccion,
    MaquilaScanpack,
    multitabla,
    facturacion,
    facturacion_consultar,
    auditoria_pedir,
    auditoria_confirmar,
    auditoria_auditar,
    wip_ver,
    wip_reportar,
    carga_po,
    ConsultarASN,
    ParoConsultar,
    ParoReportar,
    ParoSolucionar,
    CambiarContrasena
};
