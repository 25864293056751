<svelte:options accessors/>
<script lang="ts">
    import moment from "moment";
    import Flatpickr from 'svelte-flatpickr'

    import 'flatpickr/dist/flatpickr.css'
    import 'flatpickr/dist/themes/light.css'
    import {createEventDispatcher} from "svelte";

    const events = new createEventDispatcher()

    export let label = 'Fecha';
    export let value = null;
    export let slug = 'null';

    let fromDate;
    let toDate;
    let fill = false;

    const flatpickrOptions = {
        element: '#my-picker',
        mode: 'range',
        onChange: (selectedDates, dateStr, instance) => {
            fill = true;
            if (selectedDates instanceof Array) {
                if (selectedDates?.length === 2) {
                    fromDate = selectedDates[0];
                    toDate = selectedDates[1];
                    value = strDates();
                    events('change', value);
                } else {
                    value = '';
                }
            }
        }
    }

    function strDates(): string {
        const items = [];
        if (fromDate) {
            items.push(moment(fromDate).format('YYYY/MM/DD'))
        }
        if (toDate) {
            items.push(moment(toDate).format('YYYY/MM/DD'))
        }
        return items.join(' - ');
    }
</script>
<Flatpickr options="{ flatpickrOptions }" element="#{slug}">
    <div class="flatpickr" id="{slug}">
        <div class="box-l sx-i">
            <div data-input class="date-range-icon">
                <i class="fal fa-calendar-alt"></i>
            </div>
            <div class="naui-field" class:active={fill && !!value}>
                <div class="naui-field-back" class:fill={fill && !!value}>
                    <label>{label}</label>
                    <div class=" date-range">
                        {value || ''}
                    </div>
                </div>
            </div>
        </div>
    </div>
</Flatpickr>

<style>
    .date-range {
        min-height: 34px;
        width: 11.5rem;
        line-height: 1.8;
        border: 1px solid #ebebeb;
        padding-left: 8px;
        border-radius: 3px;
        padding-top: 4px;
        font-size: 14px;
    }

    .naui-field-back {
        background-color: #fff;
    }

    .naui-field label {
        top: calc((34px / 2) - .1rem);
    }

    .naui-field.active label {
        top: 0;
    }

    .date-range-icon {
        min-height: 30px;
        margin-top: .4rem;
        display: flex;
        align-items: center;
    }
</style>