<script lang="ts">
    import NauiInput from "../lib/naui/atoms/NauiInput.svelte";
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import NauiDateRange from "../lib/naui/atoms/NauiDateRange.svelte";
    import {onMount} from 'svelte';
    import NauiDatePicker from "../lib/naui/atoms/NauiDatePicker.svelte";
    import AuditoriaItem from "./components/AuditoriaItemAuditarVer.svelte";
    import AuditoriaItemXLS from "./components/AuditoriaItemAuditarVerXLS.svelte";
    import NauiSelect from "../lib/naui/atoms/NauiSelect.svelte";
    import Dialog, {Actions, Content, Title} from "@smui/dialog";
    import { TableExport } from "tableexport";
    import AuditoriaTable from "./components/AuditoriaTable.svelte";
    
  
    export let data = [];

    let AuditoriaItems = [];
    let localData = [];
    let defectos_auditables = [];
    let defectos = [];
    let defectosDescargar = [];

    let dialogOpen=false;
    let mensaje;
    let res;
    let buscando = false;
    let referencia = '';
    let rango = '';
    const swAuditar = true;

    onMount(() => {
        setTimeout(() => {
            document.getElementById("referencia").focus();     
        }, 500);
        loadData();
        buscarDefectos();
        buscar();
        
    });
    
    let vendors = []
    let vendors1 = []
    function loadData() {
        localData = data.map((x) => {
            return {...x, checked: false}
        });

        vendors.push({label: 'Todos',
            value: '0'}  )

        
        vendors1 = localData.map((y) => {
        return {
            label: y.name,
            value: y.id
        }})
        vendors = vendors.concat(vendors1)    
    }

    
    let estados = [
        {
            label: 'Programada',
            value: 'Programada'
        },
        {
            label: 'Lote Fraccionado',
            value: 'Lote Fraccionado'
        },
        {
            label: 'Entrega Certificada',
            value: 'Entrega Certificada'
        },
        {
            label: 'Aprobado',
            value: 'Aprobado'
        },
        {
            label: 'Rechazado',
            value: 'Rechazado'
        }
        ,
        {
            label: 'Cancelado',
            value: 'Cancelado'
        }
    ]   

let estado_sel = 'Programada'
let vendor_sel = '0'
let facturas = []
let lista_auditables = []
let busqueda  = []
let archivo = ''

    // Función para buscar auditables
    async function buscar() {   
                buscando    = true
                AuditoriaItems = []
                const payload = {"estado" : estado_sel, "vendor": vendor_sel, "referencia": referencia, "rango": rango, "swAuditar": swAuditar}            
                
                const response = await fetch('/auditoria/buscar', {
                    method: 'post',
                    headers: {
                        "content-type":"application/json"
                    },
                    body: JSON.stringify(payload)
                    
                });
                facturas = await response.json();           
                if (response.ok){            
                    buscando = false       
                    AuditoriaItems =  facturas['data'].map((x) => 
                        { return {...x,
                            checked: false, 
                            lista_defectos: defectos.map((x) => {return x})
                        }
                    });
                }
    }   

    async function buscarDefectos() {
        let res = [];
        const response = await fetch("/auditoria/buscar_defectos", {
        method: "get",
        headers: {
            "content-type": "application/json",
        },
        });
        if (response.ok) {
        res = await response.json();
        defectos = res["data"];
        defectos = defectos;
        }
    }

    function cancelar() {       
        document.location = '/auditoria/home'
    }

    function calcularTotalPaginas(totalRegistros,cantidadItemsPagina)
    {
        let totalPaginas = Math.round(totalRegistros/cantidadItemsPagina);
        return totalPaginas == 0?totalPaginas + 1 : totalPaginas;
    }

    function validarFiltros(){
        if(estado_sel !== "Programada" && (referencia === "" && rango === "")){
            mensaje = "Debe seleccionar el filtro de referencia o el filtro de rango de fechas.";
            dialogOpen=true
        } else {
            buscar()
        }
    }

    async function excel() {
        buscando    = true
        const payload = {"estado" : estado_sel, "vendor": vendor_sel, "referencia": referencia, "rango": rango, "swAuditar": swAuditar}            
        
        const response = await fetch('/auditoria/descargar_archivo_auditables', {
            method: 'post',
            headers: {
                "content-type":"application/json"
            },
            body: JSON.stringify(payload)
            
        });        
        if (response.ok){            
            buscando = false
            const data = await response.arrayBuffer();
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `Auditables_${new Date().toLocaleDateString()}.xlsx`;
            document.body.appendChild(a);
            a.click();  
            a.remove();
        }
    }
    $:AuditoriaItems = AuditoriaItems;
</script>
<section>

    <table class="naui-table w-70" >
        <tr>
            <td >
                <NauiSelect  bind:value={estado_sel} options={estados}  label="Estado"  />
            </td>
            <td >
                <NauiSelect  bind:value={vendor_sel} options={vendors}  label="Vendor"  />
            </td>
            <td >
                <NauiInput id="referencia" bind:value={referencia} label="Referencia"  />
            </td>
            <td >
                <NauiDateRange label="Buscar registros entre" bind:value={rango}  /> 
            </td>

            <td >
                <NauiButton caption="Buscar Solicitudes" mode="outline" color="accent" on:click={validarFiltros}/>         
            </td>
            <td >
                <NauiButton caption="Descargar Excel"  mode="outline" color="accent" on:click={excel}/>                
            </td>
           
            <td >   
                <div><NauiButton caption="Cancelar"  mode="outline" color="accent" on:click={cancelar} /> </div>                  
            </td>             
        </tr>
    </table>   
    <AuditoriaTable
        bind:auditoriaItems={AuditoriaItems}
        bind:buscando={buscando}
        itemsPerPage={20}
        totalPages={calcularTotalPaginas(AuditoriaItems.length,20)}
        on:buscar={buscar}
    /> 
    <Dialog bind:open={dialogOpen} >
        <Title>
            <h3 class="text-c">Auditar</h3>
        </Title>
        <Content>
            <div class="max-w-400px text-c mx-auto px-15">
                <p class="large">
                   {mensaje}
                </p>
            </div>
        </Content>
        <Actions>
            <div class="box-r sx-ii px-1 py-ii">
                <NauiButton caption="Aceptar" color="accent" on:click={() => {dialogOpen = false;}}/>
            </div>
        </Actions>
    </Dialog>
</section>
<style>
.custom-input-file {    
  margin: 15px auto 0;
  min-height: 15px;
  overflow: hidden;
  padding: 5px;
  position: relative;
  text-align: center;
  width: 300px; 
  height: 40px;
}

.custom-input-file .input-file {
 border: 10000px solid transparent;
 cursor: pointer;
 font-size: 10000px;
 margin: 0;
 opacity: 0;
 outline: 0 none;
 padding: 0;
 position: absolute;
 right: -1000px;
 top: -1000px;
}
</style>