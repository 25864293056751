<script lang="ts">
    import type {IPallet} from "../../models/interfaces";
    import {onMount, createEventDispatcher} from "svelte";
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import NauiSelect from "../../lib/naui/atoms/NauiSelect.svelte";
    import NauiSlideOver from "../../lib/naui/layout/NauiSlideOver.svelte";

    export let pallets: Array<IPallet> = [];
    export let open: boolean = false;

    export let totalBoxes = 40
    let dispatch = createEventDispatcher();
    let formElement;

    $: count = pallets.reduce((total, palletItem) => total + palletItem.quantity, 0);
    $: enabled = count === totalBoxes;

    onMount(() => {
        if (pallets.length === 0) {
            addItem();
        }
    });

    function addItem() {
        pallets = [...pallets, {number: null, quantity: 0}];
    }

    function deleteItem(index) {
        pallets.splice(index, 1);
        pallets = pallets;
    }

    function save() {
        open = false;
        dispatch('save');
    }
</script>

<NauiSlideOver title="Generar ASN" bind:open>
    <form bind:this={formElement}>
        {#each pallets as item, i}
            <div class="box-b sx-ii py-i">
                <div class="grid-2 gap-ii">
                    <NauiInput bind:value={item.number} type="number" label="No. Estiba" required={true} step=1 min={1}/>
                    <NauiInput bind:value={item.quantity} type="number" label="No. Cajas" required={true} step="1" min={0}/>
                </div>
                {#if i > 0}
                    <NauiButton size="small" on:click={() => {deleteItem(i)}} fa="far fa-trash-alt" color="accent"/>
                {/if}
            </div>
        {/each}

        <div class="box-l mt-1">
            <NauiButton icon="add" size="small" caption="AÑADIR ESTIBA" color="accent" on:click={addItem}/>
        </div>

        <div class="box-r py-ii mt-3" style="border-top: 2px solid #eeeeee">
            <div class="pallet pallet-count">
                <small>En estiba</small>{count}
            </div>
            <div class="pallet">
                <small>Total cajas</small>{totalBoxes}
            </div>
        </div>
    </form>

    <div slot="actions" class="box py-1">
        <NauiButton color="accent" className="min-w-300px" mode="solid" caption="GENERAR ASN"
                    disabled={!enabled}
                    on:click={() => {enabled && formElement.reportValidity() && save();}}/>
    </div>
</NauiSlideOver>
<style>
    .pallet {
        font-size: 20px;
        padding: 0 1rem;
        color: var(--text-color);
    }

    .pallet-count {
        text-align: right;
        border-right: 1px solid #eeeeee;
    }

    .pallet small {
        display: block;
        font-size: 14px;
        color: var(--text-color);
        margin-bottom: 4px;
    }
</style>
