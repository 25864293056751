<script>
  import { onMount } from "svelte";

  export let value = "";
  export let label;
  export let options;
  export let required = false;
  export let disabled = false;
  export let id = "";

  let fill = false;
  let focus = false;

  onMount(() => {
    setStatus();
  });

  function setStatus() {
    focus = false;
  }
</script>

<div class="naui-field active w-100">
  <div class="naui-field-back" class:fill>
    <label class:focus>{label}</label>
    <select
      id={id}
      class="naui-input w-100"
      disabled={disabled}
      {required}
      bind:value
      on:focusin={() => (focus = false)}
      on:change
    >
      <option value="" hidden>{label}</option>
      {#each options as option, i}
        {#if option.options}
          <optgroup label={option.name}>
            {#each option.options as sub}
              <option value={sub.value}>{sub.label}</option>
            {/each}
          </optgroup>
        {:else}
          <option value={option.value}>{option.label}</option>
        {/if}
      {/each}
    </select>
  </div>
</div>

<style>
  select {
    font-size: 0.95rem;
  }
  .naui-field-back.fill {
    background: white;
  }
</style>
