<script lang="ts">
    import Steps from "./components/Steps.svelte";
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import { onMount } from "svelte";
    import ScanTableHead from "./components/ScanTableHead.svelte";
    import ScanTableRecipiente from "./components/ScanTableRecipiente.svelte";
    import ScanTableItem from "./components/ScanTableItem.svelte";
    import InputAutocomplete from "./components/InputAutocomplete.svelte";
    import { removeDuplicates } from "../lib/utils";
    import { ScanMaquilaController, ScanHelper } from "./MaquilaScan";
    import NauiFloatingAction from "../lib/naui/components/NauiFloatingAction.svelte";
    import NauiSlideOver from "../lib/naui/layout/NauiSlideOver.svelte";
    import AnularASN from "./dialogs/AnularASN.svelte";
    import BorradorGuardado from "./dialogs/BorradorGuardado.svelte";
    import ASNGenerado from "./dialogs/ASNGenerado.svelte";
    import Dialog, { Actions, Content, Title } from "@smui/dialog";
    import AsnInput from "./components/ASNInput.svelte";
    import type { IPallet } from "../models/interfaces";
    import type { IPackedItem } from "./MaquilaScan";
    import { dataset_dev } from "svelte/internal";
    
    let breadcrumbs = [
        {
            label: "Scan and Pack Maquila",
        }
    ];
    export let loadItems;

    let ean: string = "";
    let tipoPrendaSelect = loadItems && loadItems.length > 0 ? loadItems[0].tipo_prenda : "";
    let globalCheck = false;
    let respuesta;
    let cantidadBloque;

    let loader = false;
    let generatingASN = false;

    let dialogs = {
        anular: false,
        generar: false,
        borrador: false,
        asn: false,
    };

    let dialogOpenOK = false;
    let dialogOpen = false;
    let dialogOpenConfirmation = false;
    let dialogOpenConfirmationCtrl = false;
    let dialogReadInBlockOpen = false;
    let mensaje = "";
    let swTeclaEnter = false;

    let autofocusInput = true;

    let x = 0;

    // let cart = JSON.parse(localStorage.getItem("selectedItems")) ?? [];
    let cart = null;
    const scan = new ScanMaquilaController(loadItems ?? []);

    let equivalencias = [];

    let pallets: Array<IPallet> = [];
    let formElement;

    onMount(() => {
        if (loadItems) {
            getRecipientes().then(() => {
                scan.cart = cart;
                scan.filterPacked(scan.packed);
                getSurtido(cart).then(() => {
                    scan.updatePacked(loadItems, equivalencias)
                });
                update();
            });
        }
        focusInput()
    });

    let tipoPrendaLabels = {
        prepack: "Prepack",
        conjunto: "Conjunto",
        individual: "Individual",
        multiple: "Múltiple",
    };

    $: openBoxCurrently = scan.packed.some(x => x.caja_open)

    $: eanes = scan.getEanesList()

    $: recipientes = removeDuplicates(
        (cart || []).map((x) => {
            return x.recipiente;
        })
    );

    $: scanpack = recipientes.map((x) => {
        return {
            recipiente: x,
            inventario: scan.getInventarioDisponible(x),
            empacadas: scan.getEmpacadas(x),
            items: scan.getRecipienteItems(x)
        };
    });

    $: getTotal = () => {
        const groups = scan.packed.reduce((a:number, n) => {
            const k = `${ScanHelper.getProductionKey(n)}-${n.caja}`;
            if (a === null)
                return { [k]: {caja: n.caja, empacadas: n.empacadas} };
            else {
                const x = a[k];
                if (x)
                    x.empacadas = x.empacadas + n.empacadas;
                else
                    a[k] = {caja: n.caja, empacadas: n.empacadas};
            }
            return a;
        }, null);

        
        if (groups) {
            const control = {};
            Object.values(groups).forEach((x) => {
                const k = `${x.caja}`;
                let max = 0;
                if (!control[k]) {
                    control[k] = max = x.empacadas;
                } else
                    max = control[k];
                if (max < x.empacadas)
                    control[k] = x.empacadas;
            });
            return (control && Object.values(control).reduce((x: number, t: number) => x + t)) || 0
        }
        return 0;
    };

    function getTotalRecipiente(recipiente) {
        let t = 0;
        for(var x of recipiente.items)
            t += x.unidades;
        return t;
    };
    // TODO: Implementar reglas de negocio
    async function addEanes() {
        if (ean && cantidadBloque) {
            loader = true;
            let ok: boolean = true;
            const currentBox = scan.getOpenedBox();
            const backup: Array<IPackedItem> = Array.from(scan.packed);
            scan.packed = scan.packed.filter(x => !x.caja_open);
            for(let i = 1; i <= cantidadBloque && ok; i++) {
                let resultado = await scan.addItem(ean, tipoPrendaSelect, currentBox);
                if (resultado !== null) {
                    ok = false;
                    mensaje = resultado;
                    dialogOpenOK = true;
                    scan.packed = Array.from(backup);
                }

            }
            ean = "";
            cantidadBloque = "";
            update();
            focusInput();
            loader = false;
        }
    }

    function validateGarmentType() {
        const validation = scan.validationEanAndTypeGarment(ean, tipoPrendaSelect);
            if (!validation.success) {
                mensaje = validation.message;
                dialogOpenConfirmation = true;
            }
        return validation.success;
    }
    
    // Añadir reglas de negocio
    async function addEan() {
        loader = true;
        if (ean) {
            let vAdd: string = await scan.addItem(ean, tipoPrendaSelect);
            ean = "";
            if (vAdd !== null) {
                mensaje = vAdd;
                dialogOpenOK = true;
            }
        }
        update();
        focusInput();
        loader = false;
    }

    //Funcion cuando le da enter
    const onKeyPress = async (e) => {
        if (e.charCode === 13 || e.charCode === 10) {
            if (validarTipoPrenda()) {
                swTeclaEnter = !e.ctrlKey;
                if (!validateGarmentType()){
                        dialogOpenConfirmation = false;
                        dialogOpenConfirmationCtrl = true;
				} else {
                    (e.ctrlKey) ? await toggleReadInBlock() : await addEan(); 
				}	
            }
        }
    };

    const validarTipoPrenda = () => {
        if (!tipoPrendaSelect) {
            mensaje = "No ha especificado el tipo de prenda que va a empacar";
            dialogOpenOK = true;
            return false;
        }
        return true;
    }

    function itemSelected(event) {
        const { item, checked } = event.detail;
        const selected = scan.packed.filter(x => x.caja === item.caja);
        if (selected && selected.length > 0) {
            for(let x of selected) {
                scan.checkedItems[`${x.id}-${x.caja}`] = checked;
            }
        }
        globalCheck = Object.values(scan.checkedItems).every(x => x);
    }

    function closeBox(event) {
        const box = event.detail;
        scan.closeBox(box);
        update();
        focusInput()
    }

    function openBox(event) {
        if (openBoxCurrently) {
            mensaje = "Debe cerrar la caja actual";
            dialogOpenOK = true;
        } else {
            const box = event.detail;
            tipoPrendaSelect = scan.openBox(box);
            update();
        }
        focusInput()
    }

    function update() {
        scan.packed = scan.packed;
    }

    async function save() {
        const response = await fetch("/maquila/guardar-items", {
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({ empaque: scan.packed, guardarASN: false } ),
        });
        if (response.ok) {
            scan.saveAllChanges();
            dialogs.borrador = true;
            update();
        } else {
            mensaje = "No se han podido guardar los items";
            dialogOpenOK = true;
        }
        focusInput();
    }

    async function anular() {
        const response = await fetch("/maquila/anular-asn", {
            method: "post",
        });
        if (response.ok) {
            dialogs.anular = false;
            mensaje = "Los registros del borrador de ASN fueron anulados";
            dialogOpenOK = true;
            location.reload();
        } else {
            mensaje =
                "No se han podido eliminar los registros del borrados de ASN";
            dialogOpenOK = true;
        }
        focusInput();
    }

    function globalCheckChange(globalCheck: boolean) {
        Object.keys(scan.checkedItems).forEach((key) => {
            scan.checkedItems[key] = globalCheck;
        });
    }

    function deleteChecked() {
        const toDelete = scan.checkedItems;
        let vArray = [];
        Object.keys(toDelete).forEach((key) => {
            if (toDelete[key]) {
                const elem = scan.packed.find((e) => e.consecutivo === key);
                const vObj = {
                    codigo_recipiente: elem.codigo_combinado,
                    referencia_venta: elem.codigo_referencia_venta,
                    talla: elem.talla_venta,
                    color: elem.color_venta,
                    ean: elem.ean,
                    caja: elem.caja,
                    consecutivo: key,
                };
                vArray.push(vObj);
                //scan.removeItem(key);
                delete scan.checkedItems[key];
                scan.removeItem(key);
            }
        });
        if (vArray != null) {
            deleteBoxBD(vArray);
        }
        globalCheck = false;
        update();
        focusInput();
        dialogOpen = false;
    }

    async function generarASN() {
        generatingASN = true;
        const response = await fetch("/maquila/guardar-items", {
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({ empaque: scan.packed, guardarASN: true } ),
        });
        respuesta = await response.json();
        if (response.ok) {
            scan.saveAllChanges();
            await downloadTxt(respuesta.id);
            location.reload();
        } else {
            mensaje = "No se han podido guardar los items y generar el ASN";
            dialogOpenOK = true;
        }
        focusInput();
        generatingASN = false;
    }

    function showPallets() {
        pallets = [];
        if (scan.packed && scan.packed.length > 0) {
            const palletsCodes = new Set(scan.packed.map(x => x.estiba));
            for(let p of palletsCodes) {
                const packedByPallet = new Set(scan.packed.filter(x => x.estiba === p).map(p => p.caja));
                pallets.push({ number: p.toString(), quantity: packedByPallet.size });
            }
            dialogs.generar = true;
        }
    }

    function openDialog(pMensaje) {
        mensaje = pMensaje;
        dialogOpen = true;
    }

    function openDialogOK(pMensaje) {
        mensaje = pMensaje;
        dialogOpenOK = true;
        focusInput()
    }

    function focusInput() {
        const elem = document.getElementById("ean-input");
        elem.focus();
    }

    async function downloadTxt(pId) {
        const asnText = await fetch(`/maquila/descargar_asn_file_txt/${pId}`);
        const info = await asnText.text();   
        if(info){
            const text = new Blob([info], {type: "text/plain:charset=UTF-8"});
            const url = window.URL.createObjectURL(text)
            let a = document.createElement('a')
            a.href = url;
            a.download = `ASN_${pId}_${(new Date().toLocaleDateString())}.txt`
            document.body.appendChild(a);
            a.click()
            a.remove()
        }else{
            mensaje = "No se pudo descargar el archivo";
            dialogOpenOK = true;
        }
        dialogs.asn = true;        
    }

    async function getRecipientes() {
        const recipientesSeleccionados = JSON.parse(localStorage.getItem("selectedItems")) ?? [];
        if (recipientesSeleccionados) {
            const ids = recipientesSeleccionados.map(x => x.id);
            const response = await fetch("/maquila/recipientes", {
                method: "post",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({ recipientes: ids }),
            });
            if (response.ok) {
                const bodyResponse = await response.json();
                cart = bodyResponse.data;
            } else {
                mensaje = "Ocurrió un error cargando los recipientes";
                dialogOpenOK = true;
            }
        }
    }

    async function getSurtido(items) {
        const response = await fetch("/maquila/obtener-equivalencias", {
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(items.map(x => ({
                codigo_referencia_venta: x.codigo_referencia_venta,
                talla_venta: x.talla_venta,
                color_venta: x.color_venta,
                calidad: x.calidad,
                temporada: x.temporada,
                ean_prepack: x.ean_prepack
            }))),
        });
        respuesta = await response.json();
        if (response.ok) {
            equivalencias = respuesta.data;
        } else {
            mensaje = respuesta.mensaje;
            dialogOpenOK = true;
            history.back();
        }
    }

    async function deleteBoxBD(pArray) {
        const payload = pArray;
        const response = await fetch("/maquila/delete-items", {
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        if (response.ok) {
            update();
        } else {
            mensaje = "No se han podido eliminar los items";
            dialogOpenOK = true;
            location.reload();
        }
        focusInput();
    }

    function validateValue(event) {
        mensaje =
            "Las unidades empacadas no pueden superar una cantidad de: " +
            event.detail;
        dialogOpenOK = true;
    }

    async function toggleReadInBlock(confirm = false) {
        if ( dialogReadInBlockOpen) {
            dialogReadInBlockOpen = false;
            if (confirm)
                await addEanes();

        } else {
            if (ean) {
                dialogReadInBlockOpen = true;
                cantidadBloque = "";
            }
        }
    }

    
    async function savePallets() {
        const response = await fetch("/maquila/guardar-estibas", {
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(pallets),
        });
        respuesta = await response.json();
        if (response.ok) {
            await downloadTxt(respuesta.id);
            location.reload();
        } else {
            mensaje = "No se han podido guardar las estibas y generar el ASN";
            dialogOpenOK = true;
        }
        focusInput();
    }

    //$: globalCheckChange(globalCheck);
    $: isChecked = Object.values(scan.checkedItems).some((x) => x === true);
    $: generatedASNDisabled = scan.packed.some(
        (x) => x.caja_open === true || x.changes === true
    );

    $: discajas = [...new Set(scan.packed.map(x=> x.caja))]
    $: totalBoxes = discajas.length;
    $: count = pallets.reduce(
        (total, palletItem) => total + palletItem.quantity,
        0
    );
    $: enabled = count === totalBoxes && pallets.length !== 0;
</script>

<div class="my-1 box-b">
    <Steps step="scan" />
    <div class="box-r sx-iii self-end">
        <NauiButton
            caption="ANULAR ASN"
            mode="outline"
            color="accent"
            on:click={() => (dialogs.anular = true)}
        />
        <NauiButton
            caption="GUARDAR"
            on:click={save}
            mode="solid"
            color="accent"
        />
    </div>
</div>
<div class="box-l sx-1 my-15">
    <NauiButton
        caption={"AÑADIR EAN"}
        color="accent"
        mode="solid"
        fa={"barcode-read"}
        type="submit"
        className="mt-ii"
        on:click={loader? null:()=>{ (!tipoPrendaSelect) ? addEan() : 
            (validateGarmentType()) ? addEan() : dialogOpenConfirmation = true}}
        disabled={loader}
    />

    <InputAutocomplete
        id="ean-input"
        on:keypress={!loader?onKeyPress:null}
        bind:value={ean}
        focus={true}
        list={eanes}
        label="EAN"
    />
    <div class="relative">
        <select
            class="naui-filter mt-ii"
            bind:value={tipoPrendaSelect}
            disabled={openBoxCurrently}
        >
            <option value="">(Seleccione)</option>
            <option value="prepack">Prepack</option>
            <option value="conjunto">Conjunto</option>
            <option value="individual">Individual</option>
            <option value="multiple">Múltiple</option>
        </select>
        {#if openBoxCurrently}
            <div
                class="absolute edges-0"
                on:click={() =>
                    openDialogOK(
                        "Se requiere cerrar caja para cambiar tipo de prenda"
                    )}
            />
        {/if}
    </div>
    {#if isChecked}
        <NauiButton
            caption="LIMPIAR"
            color="accent"
            className="mt-ii"
            mode="solid"
            on:click={() =>
                openDialog("¿Está seguro de eliminar la caja seleccionada?")}
        />
    {/if}
</div>

{#if equivalencias != null}
    <table class="naui-table w-100" style="margin-bottom:100px;">
        <ScanTableHead bind:checked={globalCheck} on:input={() => globalCheckChange(!globalCheck) } warningBox={scan.warningBox} warningPallet={scan.warningPallet}/>
        <tbody>
            {#if cart === null}
                <tr>
                    <td colspan="13">
                        <p>
                            Cargando registros
                        </p>
                    </td>
                </tr>
            {:else}
                {#if cart.length > 0}
                    {#each [...scanpack].reverse() as recipiente}
                        {#if recipiente.items.length > 0}
                            <ScanTableRecipiente
                                inventario={recipiente.inventario}
                                empacadas={getTotalRecipiente(recipiente)}
                                recipiente={recipiente.recipiente}
                            />
                            {#each [...recipiente.items].reverse() as item}
                                <ScanTableItem
                                    bind:checked={scan.checkedItems[
                                        item.consecutivo
                                    ]}
                                    cantidad={item.empacadas}
                                    unidades={item.unidades}
                                    data={item}
                                    inventario={recipiente.inventario}
                                    empacadas={recipiente.empacadas}
                                    on:close={closeBox}
                                    on:noAdd={validateValue}
                                    on:open={openBox}
                                    on:input={update}
                                    on:select={itemSelected}
                                    on:click={focusInput}
                                />
                            {/each}
                        {/if}
                    {/each}
                {:else}
                    <tr>
                        <td colspan="13">
                            <p>
                                No hay registros seleccionados. Para continuar <a
                                    href="/maquila/seleccion"
                                    >seleccione más registros</a
                                >
                            </p>
                        </td>
                    </tr>
                {/if}
            {/if}
        </tbody>
    </table>
{/if}
<NauiFloatingAction>
    <div class="box-r sx-1">
        <div
            class="grid-2 py-1"
            style="border-top: 1px solid var(--border-color)"
        >
            <div class="text-l"><strong>Empacados total</strong></div>
            <div class="text-c">{getTotal()}</div>
        </div>
        <div>
            {#if generatedASNDisabled}
                <button disabled class="btn solid secondary" type="button">
                    <i class="fal fa-far fa-save" />
                    <span>GENERAR ASN</span>
                </button>
            {:else}
                <NauiButton
                    fa="far fa-save"
                    disabled={generatingASN}
                    on:click={showPallets}
                    caption="GENERAR ASN"
                    color="secondary"
                    mode="solid"
                />
            {/if}
        </div>
    </div>
</NauiFloatingAction>
<NauiSlideOver bind:open={dialogs.generar} title="Generar ASN">
    {#each pallets as item, i}
        <div class="box-b sx-ii py-i">
            <div class="grid-2 gap-ii">
                <AsnInput
                    bind:value={item.number}
                    min="1"
                    type="number"
                    label="No. Estiba"
                    required={true}
                    step="1"
                    isDisabled={true}
                />
            </div>
            <div class="grid-2 gap-ii">
                <AsnInput
                    bind:value={item.quantity}
                    min="1"
                    max="25"
                    type="number"
                    label="No. Cajas"
                    required={true}
                    step="1"
                    isDisabled={true}
                />
            </div>
        </div>
    {/each}
    <div class="box-r py-ii mt-3" style="border-top: 2px solid #eeeeee">
        <div class="pallet pallet-count">
            <small>En estiba</small>{count}
        </div>
        <div class="pallet">
            <small>Total cajas</small>{totalBoxes}
        </div>
    </div>

    <div class="max-w-300px p-15">
        <p class="large">
            Recuerde que una vez genere el ASN las unidades serán descontadas de
            los registros y no se podría modificar información del ASN dentro de
            la plataforma.
        </p>
    </div>
    <div class="box">
        <NauiButton
            disabled={!enabled || generatingASN}
            caption="GENERAR ASN"
            on:click={() => {
                enabled && !generatingASN && generarASN();
            }}
            color="accent"
            mode="solid"
        />
    </div>
</NauiSlideOver>

<Dialog bind:open={dialogReadInBlockOpen}>
    <Title>
        <div class="box sx-ii py-ii">
            <p><h3>Lectura en bloque</h3>
        </div>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large"><i class="fal fa-barcode-read" />&nbsp;{ean}</p>
            <p>
                <input
                    type="number"
                    min="1"
                    style="max-width: 120px; text-align: center"
                    class="naui-input"
                    bind:value={cantidadBloque}
                />
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                icon="cancel"
                caption="Cancelar"
                color="accent"
                on:click={() => toggleReadInBlock(false)}
            />
        </div>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                icon="check_circle"
                caption="Aceptar"
                color="accent"
                on:click={() => toggleReadInBlock(true)}
            />
        </div>
    </Actions>
</Dialog>


<Dialog bind:open={dialogOpenOK}>
    <Title>
        <div class="box sx-ii py-ii">
            <span class="material-icons accent">warning</span>
            <h3>Maquila</h3>
        </div>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
                {mensaje}
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                caption="Aceptar"
                color="accent"
                on:click={() => {
                    dialogOpenOK = false;
                }}
            />
        </div>
    </Actions>
</Dialog>

<Dialog bind:open={dialogOpen} scrimClickAction="" escapeKeyAction="">
    <Title>
        <h3 class="text-c">Limpiar</h3>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
                {mensaje}
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                caption="CANCELAR"
                mode="outline"
                color="accent"
                on:click={() => {
                    dialogOpen = false;
                }}
            />
        </div>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                caption="ACEPTAR"
                mode="solid"
                color="accent"
                on:click={deleteChecked}
            />
        </div>
    </Actions>
</Dialog>

<!-- Modal de advertencia-->
<Dialog bind:open={dialogOpenConfirmation} scrimClickAction="" escapeKeyAction="">
    <Title>
        <h3 class="text-c">{mensaje}</h3>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
                ¿Quieres continuar con el proceso?
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                caption="CANCELAR"
                mode="outline"
                color="accent"
                on:click={() => {
                    dialogOpenConfirmation = false;
                }}
            />
        </div>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                caption="CONTINUAR"
                mode="solid"
                color="accent"
                on:click={
                () => { dialogOpenConfirmation = false; addEan() }}
            />
        </div>
    </Actions>
</Dialog>
<!--Modal de advertencia CTRL-->
<Dialog bind:open={dialogOpenConfirmationCtrl} scrimClickAction="" escapeKeyAction="">
    <Title>
        <h3 class="text-c">{mensaje}</h3>
    </Title>
    <Content>
        <div class="max-w-400px text-c mx-auto px-15">
            <p class="large">
                ¿Quieres continuar con el proceso?
            </p>
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                caption="CANCELAR"
                mode="outline"
                color="accent"
                on:click={() => {
                    dialogOpenConfirmationCtrl = false;
                }}
            />
        </div>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                caption="CONTINUAR"
                mode="solid"
                color="accent"
                on:click={() => { dialogOpenConfirmation = false; dialogOpenConfirmationCtrl = false; (swTeclaEnter) ? addEan() : toggleReadInBlock(); }}
            />
        </div>
    </Actions>
</Dialog>

<AnularASN bind:open={dialogs.anular} on:click={anular} />
<BorradorGuardado bind:open={dialogs.borrador} />
<ASNGenerado bind:open={dialogs.asn} on:click={() => (dialogs.asn = false)} />

<style>
    .naui-filter {
        min-width: 150px;
        color: var(--secondary);
        opacity: 1;
    }

    :host {
        display: block;
    }

    .btn:disabled {
        background: #ddd;
        color: #888;
        cursor: not-allowed;
    }

    .pallet {
        font-size: 20px;
        padding: 0 1rem;
        color: var(--text-color);
    }

    .pallet-count {
        text-align: right;
        border-right: 1px solid #eeeeee;
    }

    .pallet small {
        display: block;
        font-size: 14px;
        color: var(--text-color);
        margin-bottom: 4px;
    }
</style>
