<script lang="ts">
  import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
  import { onMount } from "svelte";
  import TablaModulo from "./components/TablaModulo.svelte";
  import NauiSelect from "../lib/naui/atoms/NauiSelect.svelte";
  import { buscarWips } from "./wip_util";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import { vendor } from "../stores/sessionVendor";
  import NauiInput from "../lib/naui/atoms/NauiInput.svelte";

  let dialogOpenOK = false;
  let dialogOpenERROR = false;

  export let data = [];
  export let grupos;

  let localData = [];
  let buscando = false;
  let puede_ver_vendors = true;
  let swButton = false;
  let swBuscar = false;

  onMount(() => {
    puede_ver_vendors =
      grupos.toUpperCase().includes("SOURCING") ||
      grupos.toUpperCase().includes("ADMINISTRADOR");
    if (!puede_ver_vendors) {
      buscarProveedor();
    }
    loadData();
    setTimeout(() => {
      document.getElementById("recipiente").focus();
      document.getElementById("referencia").focus();
    }, 500);
  });

  let vendors = [];
  let vendors1 = [];
  function loadData() {
    localData = data;
    vendors.push({ label: "", value: "-1" });
    vendors.push({ label: "Todos", value: "0" });

    vendors1 = localData.map((y) => {
      return {
        label: y.name,
        value: y.id,
      };
    });
    vendors = vendors.concat(vendors1);
    // console.log(vendors)
  }

  let vendor_sel = "-1";
  let referencia_sel = "";
  let recipiente_sel = "";
  let modulos = new Map();
  let mensaje = "";
  async function buscar() {
    swButton = true;
    if (ValidarCamposFiltros()) {
      modulos = new Map();
      buscando = true;
      modulos = await buscarWips(recipiente_sel.trim(), referencia_sel.trim(), vendor_sel);
      if (modulos === null){
        mensaje = "Error al realizar la consulta de los módulos WIPS."
        dialogOpenERROR = true;
      }
      swBuscar = true;
    }
    swButton = false;
    buscando = false;
  }

  function ValidarCamposFiltros(){
    let exito = true;
    if(puede_ver_vendors){
      if ( recipiente_sel === "" && referencia_sel === "" && vendor_sel === "-1") {
        exito = false;
        mensaje = "Seleccionar un filtro para buscar.";
        dialogOpenOK = true;
      }
    } else {
      if ( recipiente_sel === "" && referencia_sel === "") {
        exito = false;  
        mensaje = "Seleccionar un filtro para buscar.";
        dialogOpenOK = true;
      }
    }
    return exito;
  }

  async function buscarProveedor() {        
        buscando = true
        modulos = await buscarWips("", "", "-1");
    }

  function cancelar() {
    document.location = "/wip/home";
  }
</script>

<section>
  <table class="naui-table w-70">
    <tr>
      <td>
        <NauiInput
          bind:value={recipiente_sel}
          label="Recipiente"
          id="recipiente"
        />
      </td>
      <td>
        <NauiInput
          bind:value={referencia_sel}
          label="Referencia"
          id="referencia"
        />
      </td>
      {#if puede_ver_vendors}
        <td width="250px">
          <NauiSelect
            bind:value={vendor_sel}
            options={vendors}
            label="Vendor"
          />
        </td>
      {/if}
      <td>
        {#if swButton}
          <button disabled class="btn disabled" type="button">                                                                            
            <span>Buscar Wips</span>
          </button>
        {:else}
          <NauiButton caption="Buscar Wips" mode="outline" color="accent" on:click={buscar}/>
        {/if}  
      </td>

      <td>
        <div>
          <NauiButton
            caption="Cancelar"
            mode="outline"
            color="accent"
            on:click={cancelar}
          />
        </div>
      </td>
    </tr>
  </table>

  {#if modulos !== null}
    {#each [...modulos.values()] as v}
      <br />
      <TablaModulo modulo={v} data={v.recipientes} verGuardar={false} />
    {:else}
      {#if puede_ver_vendors}
        {#if !buscando}
          {#if swBuscar && modulos.size === 0}
            <div><p>No se encontraron módulos..</p></div>
          {:else}
            <div><p>Ingresar valores en los filtros..</p></div>
          {/if}
        {:else}
          <div><p>Cargando búsqueda de modulos..</p></div>
        {/if}
      {/if}
    {/each}
  {:else}
    <div><p>Error al realizar la consulta..</p></div>
  {/if}


  <Dialog bind:open={dialogOpenERROR}>
    <Title>
      <h3 class="text-c">Ver Wip</h3>
    </Title>
    <Content>
      <div class="max-w-400px text-c mx-auto px-15">
        <p class="large">
          {mensaje}
        </p>
      </div>
    </Content>
    <Actions>
      <div class="box-r sx-ii px-1 py-ii">
        <NauiButton
          caption="Aceptar"
          color="accent"
          on:click={() => {
            dialogOpenERROR = false;
          }}
        />
      </div>
    </Actions>
  </Dialog>

  <Dialog bind:open={dialogOpenOK}>
    <Title>
      <h3 class="text-c">Ver Wip</h3>
    </Title>
    <Content>
      <div class="max-w-400px text-c mx-auto px-15">
        <p class="large">
          {mensaje}
        </p>
      </div>
    </Content>
    <Actions>
      <div class="box-r sx-ii px-1 py-ii">
        <NauiButton
          caption="Aceptar"
          color="accent"
          on:click={() => {
            dialogOpenOK = false;
          }}
        />
      </div>
    </Actions>
  </Dialog>
</section>

<style>
  /*    .search {
        border-radius: 2rem;
        padding-left: 2.5rem;
    }

    .search + span {
        position: absolute;
        left: .75rem;
        top: .75rem;
        color: var(--secondary);
    }

    .naui-filter {
        min-width: 150px;
        color: var(--secondary);
        opacity: 1;
    }*/
</style>
