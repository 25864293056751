<script lang="ts">
    import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
    import NauiCheckbox from "../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiInput from "../lib/naui/atoms/NauiInput.svelte";
    import {onMount} from 'svelte';        
    import Dialog, {Actions, Content, Title} from "@smui/dialog";    
    import { buscarWips, consultaDeFiltros as consultaPorFiltros} from './wip_util';
    import { TableExport } from "tableexport";
    import RecipienteItem from "./components/RecipienteItem.svelte";
    import TablaModulo from "./components/TablaModulo.svelte";

    let dialogOpenOK = false;
    let swButton = false;
    let ocultarBtnGuardar = false;  

    let modulo = '';
    let buscando;
    let codigo_recipiente = '';
    let referencia_venta = '';

    let wips = [];
    let disMod = [];
    let modulos = new Map();

    export let data = [];
    let globalCheck = false;
    let storage = [];
    let cart = JSON.parse(localStorage.getItem('selectedItemsWip')) ?? [];
    
    let Recipientes = []
    function loadData() {
        Recipientes = data.map((x,index) => {
            return {...x, checked: false, prioridad: (index + 1) }
        });
    }

    onMount(() => {
        loadData()
        setTimeout(() => {
            document.getElementById("codigo_recipiente").focus();     
            document.getElementById("referencia_venta").focus();     
        }, 500);
        
        storage = JSON.parse(localStorage.getItem('selectedItemsWip')) ?? [];
        if (storage.length > 0) {
            storage.forEach((x) => {
                const index = Recipientes.findIndex((y) => y.id === x.id);
                if (index >= 0) {
                    x.cantidad = Recipientes[index].cantidad
                    Recipientes[index].checked = true;
                }
            });
        }
        // Compare Store with data
        cart.forEach((x) => {
            const toDelete = data.some((y) => y.id === x.id);
            if (!toDelete) {
                deleteItemFromStore(x.id);
            }
        });
        buscar()
    });

    function deleteItemFromStore(id) {
        const index = Recipientes.findIndex((x) => x.id === id);
        if (index >= 0) {
            Recipientes[index].checked = false;
        }
    }


    function globalCheckChange(check) {
        Recipientes.forEach((x) => {
            x.checked = check; 
        });
        Recipientes = Recipientes;
    }

    function saveToLocal(items) {
        if (items) {
            localStorage.setItem('selectedItemsWip', JSON.stringify(items));
        }
    }

    $: globalCheckChange(globalCheck);
    $: checkedItems = Recipientes.filter((x) => x.checked).map((y) => {
        return y
    });
    $: saveToLocal(checkedItems);
        
    function cancelar() {       
        document.location = '/wip/home'
    }

    async function recipienteFiltros() {
        swButton = true;
        const data = await consultaPorFiltros(codigo_recipiente.trim(), referencia_venta.trim());
        Recipientes = data.map((x,index) => {
            return {...x, checked: false, prioridad: (index + 1) }
        });;
        swButton = false;
    }

    function limpiarFiltros() {       
        codigo_recipiente = "";
        referencia_venta = "";
        loadData();
    }

    async function buscar() {        
        buscando = true
        modulos = await buscarWips("", "", "-1");
    }

 
let resp   
let mensaje = ''
async function guardar() {
        ocultarBtnGuardar = true;
        let disRefChk = [...new Set(Recipientes.filter( (x) => x.checked == true ).map(x=> x.id))]         
        if(disRefChk.length == 0 )        {  
            mensaje = "Seleccionar Recipientes reportar"
            dialogOpenOK =true         
        }else if (modulo != '') {
            Recipientes = Recipientes.map((x,index) => {
                return {...x, modulo: modulo}
            });
            const payload = Recipientes
            
            const response = await fetch('/wip/crear_wip', {
                method: 'post',
                headers: {
                    "content-type":"application/json"
                },
                body: JSON.stringify(payload)
            });
            resp = await response.json() 
            if (response.ok){                
                mensaje = resp['mensaje'] 
                dialogOpenOK = true;
                location.reload();
            } else {
                mensaje = resp['mensaje'] 
                dialogOpenOK = true;
            }
        } else {            
            mensaje = 'Debes ingresar el nombre del módulo.';
            dialogOpenOK = true;
        }
        ocultarBtnGuardar = false;
    }

</script>
<section>
   
    <table class="naui-table w-70" >
        
    <thead>
        <tr>        
            <th >
                Módulo
            </th>          
            <th  colspan="2">
                
            </th> 
            <th>
                Filtros
            </th>
            <th  colspan="2">
                
            </th>              
        </tr>
    </thead>
    <tbody>
        <tr>            
            <td>
                <NauiInput bind:value={modulo} type="text" label=""  />   
            </td>        
            
            <td >   
                <div>
                    <NauiButton caption="Asignar"  mode="outline" color="accent" disabled={ocultarBtnGuardar} on:click={ocultarBtnGuardar === false ? guardar : () => {}} /> 
                </div>                  
            </td>
            <td >  
                <div>
                    <NauiButton caption="Cancelar"  mode="outline" color="accent" on:click={cancelar} /> 
                </div>                  
            </td>
            <td>
                <NauiInput id="codigo_recipiente" bind:value={codigo_recipiente} label="Recipiente"  />
            </td>          
            <td>
                <NauiInput id="referencia_venta" bind:value={referencia_venta} label="Referencia Venta"  />
            </td>
            <td>  
                <div>
                    <NauiButton caption="Consultar"  mode="outline" color="accent" on:click={recipienteFiltros } disabled={swButton} /> 
                </div>                  
            </td>
            <td>  
                <div>
                    <NauiButton caption="Limpiar"  mode="outline" color="accent" on:click={limpiarFiltros} /> 
                </div>                  
            </td>        
        </tr>
    </tbody>
    </table>   
    <br>   
    
    <table class="naui-table w-100" >
        
        <thead>
            <tr>
                <th>
                    <NauiCheckbox bind:value={globalCheck}/>
                </th>
                <th width="30px">
                    Prioridad
                </th>
                <th >
                    Recipiente
                </th>                
                <th >
                    Código Referencia Prod.
                </th>
                <th >
                    Referencia Prod.
                </th>            
                <th>
                    Talla Prod.
                </th>
                <th >
                    Color Prod.
                </th>
                <th >
                    Código Referencia Venta
                </th>
                <th >
                    Referencia Venta
                </th>            
                <th>
                    Talla Venta
                </th>
                <th >
                    Color Venta
                </th>
                <th >
                    Cantidad
                </th>
            </tr>
        </thead>              
        <tbody>                     
            {#each Recipientes as itemE (itemE.id)}
                <RecipienteItem data="{itemE}" bind:checked={itemE.checked}/> 
            {/each}
        </tbody>        
    </table>   

    

    {#each [...modulos.values()] as v }
            <br>
            <TablaModulo  modulo={v}  data={v.recipientes} />
    {:else}
    {#if !buscando }    
            <div><p>No se encontraron módulos activos</p></div>
     
    {:else}           
    
            <div><p>Buscando...</p></div>
    
    {/if} 

    {/each}

    


    <Dialog bind:open={dialogOpenOK} >
        <Title>
            <h3 class="text-c">Reportar Wip</h3>
        </Title>
        <Content>
            <div class="max-w-400px text-c mx-auto px-15">
                <p class="large">
                   {mensaje}
                </p>
            </div>
        </Content>
        <Actions>
            <div class="box-r sx-ii px-1 py-ii">
                <NauiButton caption="Aceptar" color="accent" on:click={() => {dialogOpenOK = false;}}/>
            </div>
        </Actions>
    </Dialog>


    
</section>
<style>
</style>