<script lang="ts">
    
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
    import {onMount} from "svelte";
 
    export let data;        
    export let checked: boolean = false;


    onMount(() => {
 
    })

            
</script>
        <tr> 
            <td>
                <div>
                    <NauiCheckbox bind:value={checked} on:input/>             
                </div>
            </td>       
            <td>
                <div>
                    <NauiInput bind:value={data.prioridad} type="number" label=""  />       
                </div>
            </td>       

            <td >
                {data.recipiente} 
            </td>
            <td >
                {data.codigo_referencia_produccion} 
            </td>
            <td >
                {data.descripcion_referencia_produccion} 
            </td>              
            <td >
                {data.talla_produccion} 
            </td>              
            <td >
                {data.color_produccion} 
            </td>              
            <td >
                {data.codigo_referencia_venta} 
            </td>              
            <td >
                {data.descripcion_referencia_venta} 
            </td>              
            <td >
                {data.talla_venta} 
            </td>              
            <td >
                {data.color_venta} 
            </td>              
            <td >
                {data.cantidad} 
            </td> 
</tr>
