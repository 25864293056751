<script lang="ts">
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";

    export let checked: boolean = false;
    export let data = {};

</script>

<tr>
    <td>
        <div>
            <NauiCheckbox bind:value={checked} on:input/>
        </div>
    </td>
    <td>
        <div>{data.recipiente}</div>
    </td>
    <td>
        <div>{data.codigo_referencia_venta}</div>
    </td>
    <td>
        <div>{data.descripcion_referencia_venta}</div>
    </td>
    <td>
        <div>{data.talla_venta}</div>
    </td>
    <td>
        <div>{data.color_venta}</div>
    </td>
    <td>
        <div>{data.cantidad}</div>
    </td>
    <td>
        <div>{data.inventario}</div>
    </td>
    <td>
        <div>{data.ean_prepack}</div>
    </td>
    <td>
        <div>{data.ean_128}</div>
    </td>
    <td>
        <div>{data.cantidad_segundas == null ? 0 : data.cantidad_segundas}</div>
    </td>
    <td>
        <div>{data.acumulados}</div>
    </td>
    <td>
        <div>{data.empacadas}</div>
    </td>
    <td>
        <div>{data.restantes}</div>
    </td>
</tr>
