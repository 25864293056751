<script lang="ts">
   
   
    import {onMount} from "svelte";
    
   
    export let data;
   

    onMount(() => {
        camabiar()    
    })

let data_critica
let data_auditada
let data_medida
let data_empaque
let data_confeccion
let data_liquidar

function camabiar()
 {
    if (data.liquidar)
        data_liquidar = 'Sí'
    else
        data_liquidar = 'No'

    if (data.critica)
        data_critica = 'Sí'
    else
        data_critica = 'No'

    if (data.auditada)
            data_auditada = 'Sí'
    else
        data_auditada = 'No'

    if (data.medida)
            data_medida = 'Sí'
    else
        data_medida = 'No'

    if (data.empaque)
        data_empaque = 'Sí'
    else
        data_empaque = 'No'        
    
    if (data.confeccion)
        data_confeccion = 'Sí'
    else
        data_confeccion = 'No'             
}

    
</script>   
 <tr> 
            <td >
                {data.solicitud} 
            </td>  
            <td >
                {data.vendor} 
            </td>    
            <td >
                {data.referencia} 
            </td>
            <td >
                {data.tipo_auditoria} 
            </td>
            <td >
                {data_liquidar} 
            </td>        
            <td >
                {data.tipo_empaque} 
            </td>                
            <td >
                {data.Q} 
            </td>
            <td >
                {data.orden} 
            </td>
                       
            <td >
                {data_critica}  
            </td>
            <td >                    
                {data_auditada} 
            </td>
            <td >
                {data.cantidad} 
            </td>   
            <td >
                {data.estado} 
            </td>         
            <td >
                {data.fecha_sug}
                
            </td>
            <td >
                {data.hora_sug} 
                
            </td>
            <td >
                {data.auditor} 
                
            </td>            
            <td >
                {data_medida}  
            </td>            
            <td >                    
                {data_empaque}  
            </td>            
            <td >
                {data_confeccion}
            </td>                
</tr>