<script>
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    export let checked = false;
</script>

<thead>
<tr>
    <th>
        <div>
            <NauiCheckbox bind:value={checked}/>
        </div>
    </th>
    <th>
        <div>ASN</div>
    </th>
    <th>
        <div>Código referencia venta</div>
    </th>
    <th>
        <div>Descripcion referencia venta</div>
    </th>  
    <th>
        <div>Talla</div>
    </th>  
    <th>
        <div>Color</div>
    </th>  
    <th>
        <div>Cantidad</div>
    </th>  
    <th>
        <div>Precio Unitario</div>
    </th>  
    <th width="200px">
        <div>Valor</div>
    </th>      
</tr>
</thead>
