<script>
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    export let checked = false;
</script>

<thead>
<tr>
    <th>
        <div>
            <NauiCheckbox bind:value={checked}/>
        </div>
    </th>
    <th>
        <div>Recipiente</div>
    </th>
    <th>
        <div>Referencia</div>
    </th>
    <th>
        <div>Descripción</div>
    </th>
    <th>
        <div>Talla</div>
    </th>
    <th>
        <div>Color</div>
    </th>
    <th>
        <div>Cantidad Pedida</div>
    </th>
    <th>
        <div>Unid. Inventario</div>
    </th>
    <th>
        <div>EAN Prepack</div>
    </th>
    <th>
        <div>EAN 128</div>
    </th>
    <th>
        <div>Segundas</div>
    </th>
    <th>
        <div>Acumuladas</div>
    </th>
    <th>
        <div>Empacadas</div>
    </th>
    <th>
        <div>Restantes</div>
    </th>
</tr>
</thead>
