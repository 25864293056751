<script lang="ts">
  import {onMount} from "svelte";
  import NauiButton from "../lib/naui/atoms/NauiButton.svelte";
  import type {IEanData, IPallet} from "../models/interfaces";
  import NauiInput from "../lib/naui/atoms/NauiInput.svelte";
  import Dialog, {Actions, Content, Title} from "@smui/dialog";
  import EanTable from "./components/EanTable.svelte";
  import AsnInput from "./components/AsnInput.svelte";


  onMount(() => {
    po = localStorage.getItem('currentPo');
    if (po) {
      searchPO();
    }
  });

  export let currentBox;


  let po = "";
  let data: Array<IEanData> = [];
  let pallets: Array<IPallet> = [];
  let boxes = [];
  let warningBox = null;

  let dialogOpen = false;
  let asnOpen = false;

  let alert = {
    open: false,
    title: "Alerta",
    message: "",
    icon: '',
    hideActions: false
  };

  let isValid = false;

  let poUrl;
  let ean = "";
  let eanTable;

  let eanItems: Array<IEanData> = [];
  let checked: Array<number> = [];
  let minBox;
  let desactivarBotonBuscar = false;

  $: asnEnabled = data && data.length > 0 && !data.some(d => d.box.open);
  
  $: discajas = [...new Set(data.map(x=> x.box_number ? x.box_number : x.box.number))]

  $: cantCajas = discajas.length

  function searchPO() {
    if(!po || desactivarBotonBuscar) {
      return;
    }
    desactivarBotonBuscar = true;
    fetch("/scanpack/api/" + po)
      .then(async (res: any) => {
        const resJson = await res.json();
        if (res.status >= 404) {
          isValid = false;
          dialogOpen = true;
        } else {
            localStorage.setItem('currentPo', po)
          isValid = true;
          minBox = resJson.min_box;
          currentBox = resJson.min_box;
          poUrl = resJson.url;
          eanItems = resJson.items;
          pallets = resJson.pallets;
          focusEan();
          loadPackedItems(resJson.data);
        }
      })
      .finally(() => {
        desactivarBotonBuscar = false;
      });
  }

  function loadPackedItems(items: Array<IEanData>) {
    data = [];
    for (const item of items) {
      item.original = eanItems.find(e => e.id === item.id);
      data = [...data, item];
    }
    data = data.sort((a, b) => a.box_number > b.box_number ? -1 : a.box_number == a.box_number ? 0 : 1);
    eanTable.loadPackedItems(data, currentBox);
  }

  function focusEan() {
    setTimeout(() => {
      document.getElementById("ean-input").focus();
    }, 50);
  }

  function clear() {
    localStorage.setItem('currentPo', '');
    po = "";
    isValid = false;
    boxes = [];
    data = [];
    pallets = [];
    checked = [];
    eanTable.updateQuantity();
  }

  async function getBoxIndex() {                       
           const response = await fetch('/scanpack/getBoxIndex', {
        
               method: 'get',
               headers: {
                   "content-type":"application/json"
               }
           });
           let resp = await response.json() 
           if (response.ok){                   
               let id = resp['id']        
               let alerta = resp['alerta']               
               let tope = resp['tope']
               let disponibles = resp['disponibles']
               
               if(tope == 0)
               {
                  const items: Array<IEanData> = eanItems.filter(e => e.ean_128 === ean || e.ean_prepack_128 == ean);
                  const errors: Array<string> = [];
                  ean = "";
                  focusEan();
                    for (const item of items) {                      
                        const result = eanTable.addItem(item, id);
                        if ( !result  ) 
                            errors.push(item.reference);
                    }
                    if (errors.length > 0) {
                          alert.title = 'EAN no valido - requiere cerrar caja';
                          alert.message = `No se pueden añadir elementos con diferentes EAN 128 o EAN Prepack 128 en la misma caja. Por favor cierre la caja actual para añadir el nuevo elemento.
                  <br>Los siguientes EAN no se pudieron agregar: ${errors.join(', ')}`;
                          alert.icon = 'warning';
                          alert.open = true;
                    }
                    else
                      if(alerta == 1 )
                        warningBox = `Estan por agotarse los consecutivos de caja, dispone de ${disponibles}`;
               }
               else
               {
                
                   alert.title = 'Consecutivos de Caja';
                   alert.message = `Ya no tienes consecutivos de Caja para empacar`;
                   alert.icon = 'error';
                   alert.open = true;
               }         
           }  
   }



  function scan() {
    if (!ean) {
      alert.title = 'EAN no valido';
      alert.message = 'Debe especificar un EAN para agregar a la caja.';
      alert.icon = 'warning';
      alert.open = true;
      return;
    }

    const items: Array<IEanData> = eanItems.filter(e => e.ean_128 === ean || e.ean_prepack_128 == ean);
    const errors: Array<string> = [];
     
      
    let currentBox = eanTable.getBox(eanTable.box);         
    if (items.length > 0) {      
        if (currentBox.open)
        {
          for (const item of items) {
            const result = eanTable.addItem(item, 0);
            if (!result  ) {
              errors.push(item.reference);
           }
          }          
          ean = "";
          focusEan();
        }         
        else
        {
          getBoxIndex();
        }                 
      
      if (errors.length > 0) {
        alert.title = 'EAN no valido - requiere cerrar caja';
        alert.message = `No se pueden añadir elementos con diferentes EAN 128 o EAN Prepack 128 en la misma caja. Por favor cierre la caja actual para añadir el nuevo elemento.
<br>Los siguientes EAN no se pudieron agregar: ${errors.join(', ')}`;
        alert.icon = 'warning';
        alert.open = true;
      }
    } else {
      alert.title = 'EAN no valido';
      alert.message = `El EAN ingresado no se encuentra asociado a la PO No ${po}. Por favor valide la información`;
      alert.icon = 'warning';
      alert.open = true;
    }
  }

  async function save(ev, triggerModal = false) { 
    const objList = []; 
    for (const item of ev.detail) {
      item.url_sticker = `/scanpack/api/sticker/${item.box.number}`;
      const clone: any = {...item};
      clone.box = clone.box.number;
      objList.push(clone);
    }
    data = data;
    alert.title = 'Guardando datos';
    alert.message = 'Espere por favor...';
    alert.hideActions = true;
    alert.open = true;
    fetch("/scanpack/api/" + po, {
        method: "POST",
        headers: {
            "content-type":"application/json"
        },
        body: JSON.stringify(objList)
    })
      .then(res => res.json())
      .then((res) => {
        alert.open = false;
        alert.hideActions = false;
        if (triggerModal) {
          alert.icon = '';
          alert.title = 'Alerta';
          alert.message = res.message;
          alert.open = true;
          checked = [];
        }
      })
      .catch((e) => {
        alert.open = false;
        alert.hideActions = false;
        throw e;
      });
  }

  function validateNumberPallet(pallets:Array<IPallet>){
    let validNumbers = true
    pallets.every(pallet => {
      if (pallets.filter(palletContrast => palletContrast.number == pallet.number).length > 1)
        validNumbers = false
    })
    return validNumbers
  }

  async function generateASN() {
    
    if(!validateNumberPallet(pallets)){
      alert.icon = 'warning'
      alert.title = 'Verifica los números ingresados para las estibas'
      alert.message = 'Ningún número de estiba puede estar repetido'
      alert.hideActions = false
      alert.open = true
    }
    else if(pallets.find(pallet => pallet.number == 0 || pallet.number < 0)){
      alert.icon = 'warning'
      alert.title = 'Verifica los números ingresados para las estibas'
      alert.message = 'Ningún número de estiba puede ser menor o igual que 0'
      alert.hideActions = false
      alert.open = true
    }
    else{
      await save({detail: data});
      await fetch("/scanpack/api/" + po + "/guardar-estibas", {
          body: JSON.stringify(pallets),
          headers: {
              "content-type":"application/json"
          },
          method: "post"
      })
        .then(res => {
          if (res.ok) {
            return res.json()
          } else {
            res.json().then((err) => {
              alert.icon = 'error';
              alert.title = 'Ocurrió un error, vuelve a intentarlo';
              alert.message = '';
              alert.hideActions = false;
              alert.open = true;
            });
          }
        })
        .then(async (res: any) => {
          if (res.id) {
            const asnText = await fetch(`/scanpack/api/asn/${res.id}`,
                      {    
                          method: 'GET',
                          headers: {
                              "content-type":"application/json"
                          }
                      }
                  )
            const info = await asnText.blob();   
              if(info){
                const url = window.URL.createObjectURL(info)
                let a = document.createElement('a')
                  a.href = url;
                  a.download = `ASN_${res.id}_PO_${po}.xlsx`
                  document.body.appendChild(a);
                  a.click()
                  a.remove()
                  alert.title = 'Descarga realizada';
                  alert.message = ""
                  alert.icon = "check_circle"
                  alert.hideActions = false;
                  alert.open = true;
              }else{
                  alert.title = 'Alerta';
                  alert.message = "No se pudo descargar el archivo";
                  alert.hideActions = false;
                  alert.open = true;
              }
              boxes = [];
              data = [];
              pallets = [];
          }
        });
      }  
    }

  function setChecked(event) {
    checked = event.detail;
  }

  function deleteChecked() {    
    const noDelete = []
    for (let i = 0; i < data.length; i++) {
      if (checked.every(x => x != i))
      noDelete.push(data[i]);            
    }
    eanTable.updateDeleted(minBox);
    data = noDelete;
    save({detail: data}, true);
}

</script>
<div class="mt-1" style="position: relative;">
    <div class="sticky" style="top: 50px; background-color: white; z-index: 10; padding-top: 5px;">
        <form on:submit|preventDefault={searchPO}>
            <div class="box-b">
                <div class="box-l sx-1">
                    <div class="search-wrap relative">
                        <input class="naui-input search-input"
                               type="text"
                               readonly={isValid}
                               bind:value={po}
                               placeholder="Buscar PO" style="background: #fafafa"/>

                        <span class="material-icons-outlined search-icon" style="color:#888">search</span>
                        {#if isValid}
                        <span class="material-icons small primary delete-icon"
                              on:click={clear}>remove_circle_outline</span>
                        {/if}
                    </div>
                    {#if !isValid}
                        <NauiButton caption="BUSCAR" color="accent" mode="stroked" type="submit" disabled={desactivarBotonBuscar} /> 
                    {:else}
                        <NauiButton caption="VER PO" color="accent" mode="stroked" href={'/scanpack/po/' + po}/>
                    {/if}
                </div>
                <NauiButton on:click={() => { asnOpen = !!asnEnabled }} caption="GENERAR ASN"
                            mode="solid" color="accent"
                            disabled={!asnEnabled}/>
            </div>
        </form>
        <form on:submit|preventDefault={scan} class:hidden={!isValid}>
            <div class="box-l sx-1 pt-1">
                {#if checked.length > 0 && data.length > 0}
                    <NauiButton caption="AÑADIR EAN" color="secondary" mode="solid" disabled className="mt-ii"/>
                    <NauiButton caption="LIMPIAR" color="secondary" mode="solid" className="mt-ii"
                                on:click={deleteChecked}/>
                {:else}
                    <NauiButton caption="AÑADIR EAN" color="accent" mode="solid" fa="barcode-read" type="submit"
                                className="mt-ii"/>
                    <NauiInput id="ean-input" label="EAN" bind:value={ean}/>
                {/if}
            </div>
        </form>
    </div>
    <EanTable {checked} bind:box={currentBox} bind:boxes bind:this={eanTable} bind:data={data}
              on:save={(ev) => save(ev, true)}
              on:checked={setChecked} warningBox={warningBox}/>
</div>

<AsnInput bind:pallets bind:open={asnOpen} totalBoxes="{cantCajas}" on:save={generateASN}/>

<Dialog bind:open={dialogOpen}>
    <Title>
        <div class="box-c sx-ii py-ii">
            <span class="material-icons" style="color:#FF7043">warning</span>
            <h3>PO Inválido</h3>
        </div>
    </Title>
    <Content>
        <div class="max-w-400px text-c py-ii">
            <p class="large">
                El Número de PO ingresado no es válido o no ha sido asignado al proveedor. Por favor verificar.
            </p>
        </div>
    </Content>
    <Actions>
        <div class="px-1">
            <NauiButton caption="VOLVER" color="accent" on:click={() => {dialogOpen = false;}}/>
        </div>
    </Actions>
</Dialog>

<Dialog bind:open={alert.open}
        scrimClickAction={alert.hideActions ? '' : true}
        escapeKeyAction={alert.hideActions ? '' : true}>
    <Title>
        <div class="box sx-ii py-ii">
            {#if alert.icon}
                <span class="material-icons accent">{alert.icon}</span>
            {/if}
            <h3>{alert.title}</h3>
        </div>
    </Title>
    <Content>
        <div class="max-w-400px text-c py-ii" tabindex="0">
            <p class="large">{@html alert.message}</p>
        </div>
    </Content>
    <Actions>
        {#if !alert.hideActions}
        <NauiButton caption="ACEPTAR" color="accent" on:click={() => {alert.open = false;}}/>
        {/if}
    </Actions>
</Dialog>

<style>
    .search-input {
        min-width: 400px;
        border-radius: 3rem;
        padding-left: 2.5rem;
    }

    .search-wrap .search-icon {
        position: absolute;
        left: .75rem;
        top: 12px;
        z-index: 2;
    }

    .search-wrap .delete-icon {
        position: absolute;
        right: .75rem;
        top: 14px;
        font-size: 1rem;
        z-index: 2;
        cursor: pointer;
    }
</style>
