<svelte:options accessors/>
<script lang="ts">
    import Dialog, {Actions, Content} from "@smui/dialog";
    import type {IEanData} from "../../models/interfaces";
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";

    let dialogOpen = false;
    let item: IEanData = null;

    let fields = [
        {key: 'material_prepack', label: 'Material prepack'},
        {key: 'material_generico', label: 'Material genérico'},
        {key: 'material_componente', label: 'Material componente'},
        {key: 'material_generico_componente', label: 'Material componente genérico'},
    ];

    export function showInfo(_item: IEanData) {
        dialogOpen = true;
        item = _item
    }
</script>

<Dialog bind:open={dialogOpen}>
    <Content>
        {#if item}
        <div class="max-w-400px text-c py-ii">
            {#each fields as field}
                <div class="box-b sx-2">
                    <div class="text-l">
                        <strong>{field.label}</strong>
                    </div>
                    <div class="text-r">{item[field.key] || ' - ' }</div>
                </div>
            {/each}
        </div>
        {/if}
    </Content>
    <Actions>
        <div class="px-1">
            <NauiButton caption="VOLVER" color="accent" on:click={() => {dialogOpen = false;}}/>
        </div>
    </Actions>
</Dialog>
