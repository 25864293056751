<script lang="ts">
    
    import NauiCheckbox from "../../lib/naui/atoms/NauiCheckbox.svelte";
    import {onMount} from "svelte";
    import NauiSelect from "../../lib/naui/atoms/NauiSelect.svelte";
    import NauiDatePicker from "../../lib/naui/atoms/NauiDatePicker.svelte";
    import NauiInput from "../../lib/naui/atoms/NauiInput.svelte";
    import Textarea from "../../lib/naui/atoms/NauiTextarea.svelte";
    import NauiButton from "../../lib/naui/atoms/NauiButton.svelte";
    import Dialog, {Actions, Content, Title} from "@smui/dialog";
    import NauiTextarea from "../../lib/naui/atoms/NauiTextarea.svelte";
    import { createEventDispatcher } from 'svelte';
    import AuditoriaItemAuditarDefecto from "./AuditoriaItemAuditarDefecto.svelte";
    import AuditoriaItemAuditarTipoSegunda from "./AuditoriaItemAuditarTipoSegunda.svelte";

    const dispatch = createEventDispatcher()
    
    export let data;
    export let estados;

    let defectos_cantidad_cero = []
    let Evidencias = []
    let EvidenciasEliminar = []
    
    let tmpObs
    let tmpEst
    let tmpTam
    let tmpCant
    let tmpTipoAud;
    let viendo = true;
    let res;
    let defectos = [];
    let id_auditables = [];
    let defectos_auditables = []
    let auditable_defecto_data = [];
    let cargaVer = false;
    
    onMount(() => {
        // console.log('Datos detalle:', data.id, data);
         
        Evidencias = data.evidencia  
        Evidencias = data.evidencia.map((x) => {
            return {archivo:x, nombre: x.replace(data.id + '_', ""), nuevo: false}
        }); 
        
        Evidencias = Evidencias.filter( (x) =>  x.nombre != "" )
        tmpObs = data.observacion
        tmpEst = data.estado
        tmpTam = data.muestra
        tmpTipoAud = data.tipo_auditoria
        tmpCant = data.cantidad_aud
                        
    })

    function ver(){
        cargaVer = !cargaVer;
        //  = moduloAdutoria.default;
        // var link = document.getElementById('detalle_auditoria' + data.id);

        // console.log("Lista auditables:", data.lista_defectos_auditables);
        // console.log(data.id);
        // if(link)
        // {
            if (viendo){
                // if(link)
                // {
                    buscar_defecto_auditables()
                // }
             
            }        
            // else {
            //     if(link)
            //     {
            //         link.style.visibility ='collapse';
            //     }
                
            // }
            // viendo = !viendo
        // }

 
    }
    
    async function buscar_defecto_auditables(){
        console.log("paso:",data.lista_defectos_auditables);
        defectos_auditables = data.lista_defectos_auditables;
        defectos_auditables = defectos_auditables;
        // id_auditables.push(data.id);
        // id_auditables = id_auditables;
        // const res_defecto = await fetch('/auditoria/buscar_defectos_auditable', {
        //   headers: {
        //   "content-type": "application/json",
        //   },
        //   method: 'post',               
        //   body: JSON.stringify(id_auditables)
        // });
        // res = await res_defecto.json();
        // defectos_auditables = res["data"];
        // defectos_auditables = defectos_auditables;
    }

    async function buscarDefectos() {
    let res = [];
    const response = await fetch("/auditoria/buscar_defectos", {
      method: "get",
      headers: {
        "content-type": "application/json",
      },
    });
    if (response.ok) {
      res = await response.json();
      defectos = res["data"];
      defectos = defectos;
    }
  }

    function onClick()
    {   
        // TODO: Agregar consulta de defectos para los auditables tipo segundas
        // if(data.tipo_auditoria === "Segundas" && data.estado != "Programada"){
        //     buscarDefectos()
        //     buscar_defecto_auditables()
        // }
        tmpObs = data.observacion
        dialogOpenObs=true
    }


    function quitar(arc)
    {        
        console.log(Evidencias)
        let E = Evidencias.filter( (x) =>  x.archivo == arc && x.nuevo == false )
        //si el archivo no es nuevo si se adicionar para eliminar del repositorio
        if (E.length > 0 )
        {
            const item =  {
            'archivo': arc
            }
            EvidenciasEliminar.push(item)
        }    
        Evidencias = Evidencias.filter( (x) =>  x.archivo != arc )     

    }
  
    let dialogOpen=false
    let dialogOpenObs=false
    let mensaje =''
    let resp;
    let resD;
   

    function validar()
    {   
        let retorno = true;         
        if(tmpEst.trim() == 'Cancelado' ) 
        { 
            return true;
        }   
        
        if(tmpEst.trim() == 'Programada' ) 
        { 
            mensaje = "No puede seleccionar el estado Programada. Por favor seleccione otro estado";                        
            return false;
        }                    
        
        if(tmpTipoAud === "Primeras"){
            if(tmpTam == 0  || tmpTam == null ) 
            { 
                mensaje = "El tamaño de la muestra no puede ser 0";                        
                return false;
            }                

            if(tmpCant == 0   || tmpCant == null ) 
            { 
                mensaje = "La cantidad auditada no puede ser 0";                        
                return false;
            } 
        }    

        if(tmpObs.trim() == "" ) 
        { 
            mensaje = "Debe ingresar observaciones";                        
            return false;
        }     

        if(Evidencias.length == 0 ) 
        { 
            mensaje = "Debe cargar al menos un archivo para la evidencia";                        
            return false;
        }

        if(tmpTipoAud === "Segundas"){
            data.cantidad_aud = data.cantidad_primeras + data.cantidad_segundas;
            
            const uniqueValues = new Set(auditable_defecto_data.map(v => v.defecto_id));
            if(uniqueValues.size < auditable_defecto_data.length){
                mensaje = "No puedes seleccionar dos veces el mismo defecto.";                        
                return false;
            }

            if(auditable_defecto_data.filter(x => x.defecto_id === 0 || x.defecto_id === null).map(x => {return x}).length > 0){
                mensaje = "El defecto ingresado no existe.";                        
                return false;
            }
            
            defectos_cantidad_cero = auditable_defecto_data.filter(x => x.cantidad === 0 || x.cantidad === null).map(x => {return x});
            if(defectos_cantidad_cero.length > 0){
                mensaje = "Debe asignar cantidad a los defectos.";                        
                return false;
            }
        }
        return retorno;
    }

    let recarga = 0
    var fdata = new FormData()

    async function auditar() {
   
            if(!validar())
            {
                dialogOpen=true
                return false
            }

            if (tmpTam == null)
                tmpTam = 0
                
            if (tmpCant == null)
                tmpCant = 0

            data.estado = tmpEst
            data.observacion = tmpObs
            data.muestra = tmpTam
            if(tmpTipoAud === "Segundas"){
                data.cantidad_aud = data.cantidad_primeras + data.cantidad_segundas;
            } else {
                data.cantidad_aud = tmpCant;
            }
            data.evidencia = Evidencias.map((x) => {return x.archivo}); 
            dialogOpenObs=false
       

            fdata.append('data', JSON.stringify(data) );
            fdata.append('id', data.id);
            fdata.append('EvidenciasEliminar',JSON.stringify(EvidenciasEliminar));
            fdata.append('auditable_defecto_data',JSON.stringify(auditable_defecto_data));

            const response = await fetch('/auditoria/guardar_auditar_uno', {
                method: 'post',               
                body: fdata
            });
            resp = await response.json() 

            if (response.ok){                    
                mensaje = resp['mensaje']
                dialogOpen = true;
                recarga = 1
            }           
    }

    async function archivoSeleccionado(e) {
        var data = new FormData()
        for(let f of e.target.files) {
            data.append('archivo_subir', f);
        }
        data.append('id', e.target.getAttribute('data-id'));
        resp = await fetch('subir', {
            method: 'POST',
            body: data
        });
        let respuesta = await resp.json()
        if (resp.ok) {
            console.log(respuesta) 
            mensaje = respuesta['mensaje']  
            Evidencias = Evidencias.concat(respuesta['files'] )         
            dialogOpen = true
            ///console.log(await respuesta.json());
        }
        else
        {
            console.log(respuesta) 
            mensaje = respuesta['mensaje']           
            dialogOpen = true
        }
    }

    async function archivoSeleccionado_temporal(e) {
        
        for(let f of e.target.files) {
            fdata.append('archivo_subir', f);
            Evidencias.push({
            nombre: f.name,
            archivo: data.id + '_' +  f.name,
            nuevo: true  }  )         
        }    
        Evidencias=Evidencias
    }

</script>
        <tr> 
            <td >
                {data.vendor} 
            </td>    
            <td >
                {data.referencia} 
            </td>
            <td >
                {data.tipo_auditoria} 
            </td>
            <td >
                <NauiCheckbox bind:value={data.liquidar}  disabled = {true}/>  
            </td>
            <td >
                {data.muestra} 
            </td>   

            <td >
                {data.tipo_empaque} 
            </td>                
           
            <td >
                {data.Q} 
            </td>
            <td >
                {data.orden} 
            </td>
                       
            <td >
                <NauiCheckbox bind:value={data.critica}  disabled = {true}/>  
            </td>
            <td >                    
                <NauiCheckbox bind:value={data.auditada} disabled = {true}/>  
            </td>
            <td >
                {data.cantidad} 
            </td>   
            <td >
                {data.cantidad_aud} 
            </td>
            <td >
                {data.cantidad_segundas} 
            </td> 
            <td >
                {data.estado}  
            </td>         
            <td >
                {data.fecha_pro}  
                
            </td>
            <td >
                {data.hora_pro}  
                
            </td>
            <td >
                
                {data.auditor}  
                
            </td>        
            <td >
                {data.observacion}  
                              
            </td>            
           <td >
                <NauiCheckbox bind:value={data.medida}  disabled={true}/>                       
            </td>            
            <td >                    
                <NauiCheckbox bind:value={data.empaque}  disabled={true}/>                    
            </td>            
            <td >
                <NauiCheckbox bind:value={data.confeccion}  disabled={true}/>                    
            </td>  
            <td >
                <NauiButton  caption="Auditar"  mode="outline" color="accent" on:click={onClick}/>
                {#if tmpTipoAud === "Segundas" && tmpEst.trim() !== 'Programada'}
                    <NauiButton caption="Ver"  mode="outline" color="accent" on:click={ver}/>
                {/if}  
            </td>                 
        </tr>
            {#if cargaVer && tmpTipoAud === "Segundas"} 
                <AuditoriaItemAuditarDefecto data={data} defectos_auditables={defectos_auditables}/>
            {/if}        
<!-- {#if dialogOpenObs}    -->
<!-- {#if dialogOpenObs} -->
    <Dialog bind:open={dialogOpenObs} scrimClickAction=""
    escapeKeyAction="" >
    <Title>
        <h3 class="text-c">Auditar</h3>
    </Title>
    <Content>
        <div class="max-w-600px text-c mx-auto px-15">            
            <table class="naui-table w-600" >
                    <tr>  
                        <th >
                            Estado
                        </th>     
                        <td >
                            <NauiSelect  bind:value={tmpEst} options={estados}  label=""   />
                        </td>         
                    </tr>
                    {#if tmpTipoAud === "Primeras"}
                        <tr>  
                            <th >
                                Tamaño de muestra
                            </th>     
                            <td >
                                <NauiInput bind:value={tmpTam} type="number" label=""  />
                            </td>         
                        </tr>
                    {/if}
                    
                    {#if tmpTipoAud === "Primeras"}
                        <tr id="tr-cant-aud">  
                            <th >
                                Cantidad auditada
                            </th>     
                            <td >
                                <NauiInput bind:value={tmpCant} type="number" label=""/>
                            </td>         
                        </tr>
                    {:else}
                        <AuditoriaItemAuditarTipoSegunda bind:auditable_defecto_data data="{data}" auditables_defectos={defectos_auditables} defectos_lista={defectos}/>
                    {/if}      

                    <tr >  
                        <th colspan="1" >
                            Observaciones
                        </th>    
                        <td style='text-align:left' >
                            <NauiTextarea bind:value={tmpObs} label="" cols=38 rows=3 /> 
                        </td>    
                    </tr>
                    <tr>  
                        <th >
                            Evidencias
                        </th>     
                        <td style='text-align:left' >
                            <div>
                                <label class="material-icons accent" for={`image_src_${data.id}`}>file_present</label>
                                <input type="file" accept=".pdf,.xls,.xlsx,.doc,.docx,.png,.jpg,.jpeg,.bmp,.rar,.zip" name={`image_src_${data.id}`} id={`image_src_${data.id}`} data-id={data.id} on:change={archivoSeleccionado_temporal} class="custom-input-file" multiple/>
                            </div>
                            <table>
                            {#each Evidencias as itemE}
                                {#if itemE.nuevo}
                                    <tr>
                                        <td style='text-align:left' >
                                            <NauiButton icon="disabled_by_default" mode="outline"   color="accent" iconStyle="outlined" on:click={quitar(itemE.archivo)} />    
                                        </td>
                                        <td style='text-align:left'>
                                            
                                            {itemE.nombre}  <br>
                                        </td>         
                                    </tr>
                                {:else}
                                    <tr>
                                        <td style='text-align:left' >
                                            <NauiButton icon="disabled_by_default" mode="outline"   color="accent" iconStyle="outlined" on:click={quitar(itemE.archivo)} />    
                                        </td>
                                        <td style='text-align:left'>
                                            
                                            <a href="/auditoria/descargar?file={itemE.archivo}&nombre={itemE.nombre}" target="blank">{itemE.nombre} </a><br>
                                        </td>         
                                    </tr>
                                {/if}
                            {:else}
                                Debe seleccionar Archivos de evidencia
                            {/each}
                        </td>         
                    </tr>
            </table>
            
            
        
        </div>
    </Content>
    <Actions>
        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton
                caption="CANCELAR"
                mode="outline"
                color="accent"
                on:click={() => {
                    tmpObs = data.observacion
                    tmpEst = data.estado
                    tmpTam = data.muestra
                    tmpCant = data.cantidad_aud

                    Evidencias = data.evidencia  
                    Evidencias = data.evidencia.map((x) => {
                        return {archivo:x, nombre: x.replace(data.id + '_', ""),nuevo:false}
                    }); 
                    Evidencias = Evidencias.filter( (x) =>  x.nombre != "" )
                    
                    EvidenciasEliminar = []
                    fdata = new FormData()
                    document.getElementById('image_src_'+data.id).value = ""
                    dialogOpenObs = false;                    
                }}
            />
        </div>

        <div class="box-r sx-ii px-1 py-ii">
            <NauiButton on:change
                caption="AUDITAR"
                mode="solid"
                color="accent"
                on:click={() =>
                    {
                        auditar()
                    }
                }
            />
        </div>
    </Actions>
</Dialog>
<!-- {/if} -->
<!-- {/if} -->
<!-- {#if dialogOpen} -->
<Dialog bind:open={dialogOpen} scrimClickAction=""
escapeKeyAction="" >
<Title>
    <h3 class="text-c">Auditar</h3>
</Title>
<Content>
    <div class="max-w-400px text-c mx-auto px-15">
        <p class="large">
           {mensaje}
        </p>
    </div>
</Content>
<Actions>
    <div class="box-r sx-ii px-1 py-ii">
        <NauiButton caption="Aceptar" color="accent" on:click={() => {
                dialogOpen = false;  
                if(recarga === 1 ){
                    dispatch('Eauditar')
                }
                document.body.classList.remove("mdc-dialog-scroll-lock")
            }
        }/>
    </div>
</Actions>
</Dialog>
<!-- {/if} -->
<style>
    .custom-input-file{
        display: none;
    }

    .button-style-ver{
        margin-left: 10px;
    }
</style>