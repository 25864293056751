<script lang="ts">
    import NauiCheckbox from "../atoms/NauiCheckbox.svelte";
    import NauiState from "../atoms/NauiState.svelte";
    import NauiTag from "../atoms/NauiTag.svelte";
    import NauiButton from "../atoms/NauiButton.svelte";
    import {createEventDispatcher} from "svelte";


    import {getFormatter} from "../../utils";
    import type {ITableField} from "../interfaces";

    const dispatch = createEventDispatcher();
    const formatter = getFormatter();

    export let field: ITableField
    export let data = null;
    export let selected = null;
    export let options = null;
    export let slug;

    function dispatchAction(actionSlug) {
        dispatch('action', {action: actionSlug, slug: slug});
    }

</script>
<div class="w-100 field" class:box={field.alignment==='center'} class:bold={field.bold} style="color: {field.color || 'inherit'}">
    {#if field.type === 'text'}
        {data}
    {:else if field.type === 'checkbox'}
        <NauiCheckbox label="" bind:value={selected}/>

    {:else if field.type === 'state'}
        {#if data}
            <NauiState code={data.code} label={data.label}/>
        {/if}
    {:else if field.type === 'tag'}
        <NauiTag label="{data.label}" textColor="{data.textColor}" backColor="{data.backColor}"
                 borderColor="{data.backColor}"/>

    {:else if field.type === 'date'}
        {#if data}
            <div class="naui-date text-c" style="width:100px">{data}</div>
        {/if}
    {:else if field.type === 'currency'}
        <span>{formatter(data)}</span>
    {:else if field.type === 'select'}
        <select class="naui-input w-100">
            {#each options as option}
                <option value={option.value}>{option.label}</option>
            {/each}
        </select>

    {:else if field.type === 'icon'}
        <i style="font-size: 1.2rem"
           class="{data.iconFamily ? data.iconFamily: 'fal'} fa-{data.icon} {data.color ? data.color : ''}"></i>

    {:else if field.type === 'actions'}
        <div class="box sx-1">
            {#each data || [] as action}
                {#if action.disabled}
                    <div style="opacity: .35; filter: grayscale(100%); pointer-events: none;">
                        <NauiButton href={action.route} icon="{action.icon}" fa="{action.fa}"
                                    iconStyle="{action.iconStyle ? action.iconStyle : null}"
                                    color="{action.color ? action.color : 'accent'}" disabled/>
                    </div>
                {:else if action.route}
                    <NauiButton href={action.route} icon="{action.icon}" fa="{action.fa}" target="{action.target || ''}"
                                iconStyle="{action.iconStyle ? action.iconStyle : null}"
                                color="{action.color ? action.color : 'accent'}" download="{action.download}"/>
                {:else}
                    <NauiButton icon="{action.icon}" fa="{action.fa}"
                                iconStyle="{action.iconStyle ? action.iconStyle : null}"
                                color="{action.color ? action.color : 'accent'}"
                                on:click={dispatchAction.bind(this, action.slug)}/>
                {/if}
            {/each}
        </div>

    {:else if field.type === 'list'}
        {data.map(i => i.label.trim()).join(', ')}
    {/if}
</div>
<style>
    .field.bold, .field.bold>*, .field.bold>*>*{
        font-weight: bold;
    }
</style>
